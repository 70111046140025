import { Flex, Form, Typography } from 'antd';
import clsx from 'clsx';
import styles from '../SignUpView.module.css';

interface PasswordRulesProps {
  isTwelveCharacters: boolean;
  hasNumber: boolean;
  hasUpperCase: boolean;
  hasSpecialCharacter: boolean;
}

export function PasswordRules({
  isTwelveCharacters,
  hasNumber,
  hasUpperCase,
  hasSpecialCharacter,
}: PasswordRulesProps) {
  const {status} = Form.Item.useStatus();

  const isError = status === 'error';

  return (
    <Flex justify="space-between" gap={17} className={styles.passwordRules}>
      <Typography.Text
        className={clsx(
          styles.passwordRule,
          isTwelveCharacters && styles.active,
          !isTwelveCharacters && isError && styles.invalid
        )}
      >
        12+ Characters
      </Typography.Text>
      <Typography.Text
        className={clsx(
          styles.passwordRule,
          hasNumber && styles.active,
          !hasNumber && isError && styles.invalid
        )}
      >
        Numeric
      </Typography.Text>
      <Typography.Text
        className={clsx(
          styles.passwordRule,
          hasUpperCase && styles.active,
          !hasUpperCase && isError && styles.invalid
        )}
      >
        Upper
      </Typography.Text>
      <Typography.Text
        className={clsx(
          styles.passwordRule,
          hasSpecialCharacter && styles.active,
          !hasSpecialCharacter && isError && styles.invalid
        )}
      >
        Special character
      </Typography.Text>
    </Flex>
  );
}
