export enum CounterpartyLabels {
  Entity = 'Entity',
  Individual = 'Individual',
}

export interface Counterparty {
  id: string;
  name: string;
  note: string;
  label?: CounterpartyLabels;
  turnover: number | null;
  wallets_count?: {
    eth: number;
    tron: number;
    total: number;
  };
}

export interface CounterpartyWallet {
  id: string;
  name?: string;
  turnover: number | null | '';
  counterparty_id?: Counterparty['id'];
  wallet: {
    address: string;
    network?: string;
    last_retrieved_at: string | null;
    usd_balance: string | null;
  };
}
