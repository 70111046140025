import { App, Flex, Modal, Typography } from 'antd';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { multisenderService } from 'services/multisender';
import { useSWRConfig } from 'swr';
import { Multisend } from 'types/multisender';

interface DeleteDialogProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  data: Multisend['multisend'];
}

export function DeleteDialog({
  isOpen,
  onOpenChange,
  data,
}: DeleteDialogProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { notification } = App.useApp();
  const { mutate } = useSWRConfig();

  const handleOpenChange = (isOpen: boolean) => {
    onOpenChange(isOpen);

    if (!isOpen) {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsSubmitting(true);

      await multisenderService.deleteMultisend(data?.id);

      await mutate('/multisends/templates');

      handleOpenChange(false);
      notification.success({
        message: 'Template deleted',
        description: `${data.name} deleted`,
        placement: 'bottom',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        notification.error({
          message: 'Delete failed',
          description: error.response?.data.message || error.message,
          placement: 'bottom',
        });
      } else if (error instanceof Error) {
        notification.error({
          message: 'Delete failed',
          description: error.message,
          placement: 'bottom',
        });
      }
    }
  };

  return (
    <Modal
      open={isOpen}
      afterOpenChange={handleOpenChange}
      centered
      onOk={handleDelete}
      onCancel={() => handleOpenChange(false)}
      title="Delete batch"
      okText="Delete anyway"
      okButtonProps={{
        danger: true,
        loading: isSubmitting,
      }}
    >
      <Flex vertical gap={20} style={{ margin: '24px 0' }}>
        <Typography.Text>
          Are you sure you want to delete <strong>{data.name}</strong>?
          <br />
          This action cannot be undone.
        </Typography.Text>
      </Flex>
    </Modal>
  );
}
