export interface User {
  id: number;
  email: string;

  current_plan: UserPlans;
  current_plan_valid_until?: string;
  can_add_new_wallet: boolean;

  is_password_set: boolean;

  ref_code: string;
}

export enum UserAuthResponseCodes {
  EmailVerificationRequired = 'email_verification_required',
}

export interface UserAuthResponse {
  user: User;
  token: string;

  message?: string;
  code?: UserAuthResponseCodes;
}

export type UserResponse = Omit<UserAuthResponse, 'token'>;

export enum UserPlans {
  Free = 'free',
  Pro = 'pro',
  Enterprise = 'enterprise',
}

export enum SubscriptionPeriod {
  Month = 'month',
  HalfYear = 'half_year',
  Year = 'year',
}

export interface PaymentHistoryItem {
  id: string;
  amount: string;
  gateway_id: string;
  status: string;
  created_at: string;
  updated_at: string;
}

export enum PayoutStatus {
  Paid = 'Paid',
  Processing = 'Processing',
  Pending = 'Pending',
}

export interface InvitedUser {
  email: string;
  signed_up_at: string;
  state: PayoutStatus;
  amount_paid: string;
}
