import { Currency } from 'types';
import { create } from 'zustand';

export interface CurrencyStore {
  currencies: Currency[];

  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;

  setCurrencies: (currencies: CurrencyStore['currencies']) => void;
}

export const currencyStore = create<CurrencyStore>((set) => ({
  currencies: [],
  isLoading: true,
  setIsLoading: (isLoading) => set({ isLoading }),
  setCurrencies: (currencies) => set({ currencies }),
}));

export const useCurrencyStore = currencyStore;
