import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography } from 'antd';
import { useState } from 'react';
import { MultisenderForm } from 'screens/Multisender/form';
import styles from './styles.module.css';
import { UploadCSV } from './UploadCSV';

export function RecordCreator() {
  const form = Form.useFormInstance<MultisenderForm>();

  const [amountOfRows, setAmountOfRows] = useState<string>('1');

  const handleAddRows = () => {
    const recipients = form.getFieldValue(
      'recipients'
    ) as MultisenderForm['recipients'];
    const newRecords = Array.from({ length: +amountOfRows }, (_, i) => ({
      id: crypto.randomUUID(),
      no: recipients.length + i + 1,
    }));

    form.setFieldsValue({
      recipients: [...recipients, ...newRecords],
    });

    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  const handleClearRows = () => {
    const recipients = form.getFieldValue(
      'recipients'
    ) as MultisenderForm['recipients'];

    const newRecipients = recipients.filter(
      (recipient) =>
        recipient?.counterparty ||
        recipient?.walletAddress?.[0] ||
        recipient?.amount
    );

    form.setFieldsValue({
      recipients: newRecipients,
    });
  };

  const handleAmountChange = (value: string) => {
    if (!value || /^\d+$/.test(value)) {
      setAmountOfRows(value);
    }
  };

  return (
    <div className={styles.recordCreator}>
      <Typography.Text>Add empty rows</Typography.Text>
      <Input
        placeholder="Specify qty"
        value={amountOfRows}
        onChange={(e) => handleAmountChange(e.target.value)}
        style={{ width: 108, marginLeft: 24 }}
      />
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        onClick={handleAddRows}
        style={{ marginLeft: 8 }}
      >
        Add row(s)
      </Button>
      <UploadCSV />
      <Button
        type="dashed"
        onClick={handleClearRows}
        style={{ marginLeft: 'auto' }}
      >
        Clear empty rows
      </Button>
    </div>
  );
}
