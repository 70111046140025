import { NetworkType } from 'libs/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface NetworkTypeStore {
  networkType?: NetworkType;
  setNetworkType: (networkType?: NetworkType) => void;
}

export const useNetworkType = create(
  persist<NetworkTypeStore>(
    (set) => ({
      networkType: undefined,
      setNetworkType: (networkType) => set({ networkType }),
    }),
    {
      name: 'networkType',
    }
  )
);
