import { Flex, Typography } from 'antd';
import styles from './styles.module.css';
import { WalletIcon } from 'assets/icons/Wallet';
import { useWalletsStore } from 'store/wallets';
import { NoWalletsPlaceholder } from 'components/NoWalletsPlaceholder';

export function TransactionsPlaceholder() {
  const [wallets] = useWalletsStore((s) => [s.wallets]);

  if (wallets.length <= 0)
    return (
      <Flex
        justify="center"
        align="center"
        gap={32}
        vertical
        className={styles.transactionsPlaceholder}
      >
        <NoWalletsPlaceholder />
      </Flex>
    );

  return (
    <Flex
      justify="center"
      align="center"
      gap={32}
      vertical
      className={styles.transactionsPlaceholder}
    >
      <WalletIcon />
      <Flex justify="center" align="center" vertical gap={16}>
        <Typography.Title
          level={1}
          className={styles.transactionsPlaceholderTitle}
        >
          Brand new wallet
        </Typography.Title>
        <Typography.Text className={styles.transactionsPlaceholderDescription}>
          Your wallet doesn’t have any transactions yet. Please do some crypto
          transactions for the records to appear on the screen
        </Typography.Text>
      </Flex>
    </Flex>
  );
}
