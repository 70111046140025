import axios, { AxiosInstance } from 'axios';
import { API_URL } from 'configs/api';
import { onboardingStore } from 'screens/Onboarding/store';
import { walletsStore } from 'store/wallets';
import { BreakdownStats, Stats, Wallet } from 'types/wallet';
import { getAccessToken } from 'utilities/user';
import { userService } from './userService';

export class WalletService {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
    });
  }

  /**
   * Method to get wallets
   * @returns Array of Wallet objects
   */
  async getWallets(): Promise<Wallet[]> {
    const { setWallets, setIsLoading } = walletsStore.getState();
    try {
      setIsLoading(true);
      const url = '/users/wallets';

      const { data } = await this._api.get<Wallet[]>(url, {
        headers: {
          Authorization: getAccessToken(),
        },
      });

      const wallets = data.sort((a, b) => {
        if (a.active && !b.active) return -1;
        if (!a.active && b.active) return 1;
        return 0;
      });

      setWallets(wallets);

      return wallets;
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }

    return [];
  }

  /**
   * Method to create a new wallet
   * @param name
   * @param address
   * @returns Wallet object
   * @throws **[Important]** this method may throw an error
   */
  async createWallet(name: string, address: string, network: string) {
    const { wallets, addWallet } = onboardingStore.getState();
    const { wallets: userWallets, setWallets } = walletsStore.getState();

    const isAlreadyExists = wallets.some(
      (wallet) => wallet.wallet.address === address
    );

    if (isAlreadyExists) throw new Error('Wallet already exists');

    const url = '/users/wallets';
    const { data } = await this._api.post<{ user_wallet: Wallet }>(
      url,
      {
        network,
        address,
        name: name?.length > 0 ? name : undefined,
      },
      {
        headers: {
          Authorization: getAccessToken(),
        },
      }
    );

    await userService.getCurrentUser();

    addWallet(data.user_wallet);
    setWallets([...userWallets, data.user_wallet]);

    return data.user_wallet;
  }

  /**
   * Method to remove a wallet
   * @param walletId - Wallet ID
   * @throws **[Important]** this method may throw an error
   */
  async removeWallet(walletId: string) {
    const url = `/users/wallets/${walletId}`;
    const { removeWalletById } = onboardingStore.getState();
    const { setWallets, wallets } = walletsStore.getState();

    await this._api.delete(url, {
      headers: {
        Authorization: getAccessToken(),
      },
    });

    await userService.getCurrentUser();

    removeWalletById(walletId);
    setWallets(wallets.filter((wallet) => wallet.id !== walletId));
  }

  async editWallet(walletId: string, name: string) {
    const url = `/users/wallets/${walletId}`;

    const { data } = await this._api.put<{ user_wallet: Wallet }>(
      url,
      {
        name,
      },
      {
        headers: {
          Authorization: getAccessToken(),
        },
      }
    );

    const { setWallets, wallets } = walletsStore.getState();

    setWallets(
      wallets.map((wallet) =>
        wallet.id === walletId ? { ...wallet, name } : wallet
      )
    );

    return data.user_wallet;
  }

  async getStats(
    wallets: string[],
    period: string,
    stats: Stats[] = [Stats.Income, Stats.Expenses, Stats.Pnl]
  ) {
    const url = `/user_wallets/stats`;
    const { data } = await this._api.get<{
      [key: string]: [string, number][];
    }>(url, {
      params: {
        user_wallet_ids: wallets,
        stats,
        granularity: period,
      },
      headers: {
        Authorization: getAccessToken(),
        Accept: 'application/json',
      },
    });

    return data;
  }

  async downloadStatsAsCSV(
    wallets: string[],
    period: string,
    stats: Stats[] = [Stats.Income, Stats.Expenses, Stats.Pnl]
  ) {
    const url = `/user_wallets/stats`;
    const { data } = await this._api.get<Blob>(url, {
      params: {
        user_wallet_ids: wallets,
        stats,
        granularity: period,
      },
      headers: {
        Authorization: getAccessToken(),
        Accept: 'text/csv',
      },
    });

    const urlObject = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = urlObject;
    link.setAttribute('download', `stats-${Date.now()}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  async getStatsBreakdown(
    wallets: string[],
    period: string,
    stats: Stats[] = [Stats.Income, Stats.Expenses, Stats.Pnl]
  ) {
    const url = `/user_wallets/stats/breakdown`;
    const { data } = await this._api.get<BreakdownStats>(url, {
      params: {
        user_wallet_ids: wallets,
        stats,
        granularity: period,
      },
      headers: {
        Authorization: getAccessToken(),
        Accept: 'application/json',
      },
    });

    return data;
  }

  async downloadStatsBreakdownAsCSV(
    wallets: string[],
    period: string,
    stats: Stats[] = [Stats.Income, Stats.Expenses, Stats.Pnl]
  ) {
    const url = `/user_wallets/stats/breakdown`;
    const { data } = await this._api.get<Blob>(url, {
      params: {
        user_wallet_ids: wallets,
        stats,
        granularity: period,
      },
      headers: {
        Authorization: getAccessToken(),
        Accept: 'text/csv',
      },
    });

    const urlObject = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = urlObject;
    link.setAttribute('download', `stats-breakdown-${Date.now()}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}

export const walletService = new WalletService();
