import { GoogleOAuthProvider } from '@react-oauth/google';
import { App as AntdApp, ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';
import { GuestsOnlyRoute } from 'components/GuestRoute';
import { PrivateRoute } from 'components/PrivateRoute';
import { antdConfig } from 'configs/antd';
import { Routes } from 'constants/routs';
import { AuthLayout } from 'layouts/AuthLayout';
import { MainLayout } from 'layouts/MainLayout';
import { WalletsProvider } from 'libs/providers/WalletsProvider';
import { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AddressBookView } from 'screens/AdressBook';
import { BalancesView } from 'screens/Balances';
import { EmailConfirmView } from 'screens/EmailConfirm';
import { LogInView } from 'screens/LogIn';
import { MultisenderView } from 'screens/Multisender';
import { OnboardingView } from 'screens/Onboarding';
import { ProfileView } from 'screens/Profile';
import { ProfitAndLossView } from 'screens/ProfitAndLoss';
import { SignUpView } from 'screens/SignUp';
import { TransactionsView } from 'screens/Transactions';
import { settingsService } from 'services/settings';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: Routes.ProfitAndLoss,
        element: (
          <PrivateRoute>
            <ProfitAndLossView />
          </PrivateRoute>
        ),
      },
      {
        path: Routes.Transactions,
        element: (
          <PrivateRoute>
            <TransactionsView />
          </PrivateRoute>
        ),
      },
      {
        path: Routes.Balances,
        element: (
          <PrivateRoute>
            <BalancesView />
          </PrivateRoute>
        ),
      },
      {
        path: Routes.Profile,
        element: (
          <PrivateRoute withoutPayment>
            <ProfileView />
          </PrivateRoute>
        ),
      },
      {
        path: Routes.AddressBook,
        element: (
          <PrivateRoute>
            <AddressBookView />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: Routes.Multisender,
    element: (
      <PrivateRoute>
        <MultisenderView />
      </PrivateRoute>
    ),
  },
  {
    path: '/',
    element: (
      <GuestsOnlyRoute>
        <AuthLayout />
      </GuestsOnlyRoute>
    ),
    children: [
      {
        path: Routes.SignUp,
        element: <SignUpView />,
      },
      {
        path: Routes.LogIn,
        element: <LogInView />,
      },
    ],
  },
  {
    path: Routes.Onboarding,
    element: (
      <PrivateRoute>
        <OnboardingView />
      </PrivateRoute>
    ),
  },
  {
    path: Routes.EmailConfirm,
    element: (
      <PrivateRoute withoutVerifiedEmail withoutPayment>
        <EmailConfirmView />
      </PrivateRoute>
    ),
  },
]);

function App() {
  useEffect(() => {
    settingsService.getSettings();
  }, []);

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}
    >
      <ConfigProvider locale={enUS} theme={antdConfig}>
        <AntdApp>
          <WalletsProvider>
            <RouterProvider router={router} />
          </WalletsProvider>
        </AntdApp>
      </ConfigProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
