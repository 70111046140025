import { Wallet } from 'types/wallet';
import { create } from 'zustand';

interface WalletsStore {
  wallets: Wallet[];
  setWallets: (wallets: Wallet[]) => void;

  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const walletsStore = create<WalletsStore>((set) => ({
  wallets: [],
  setWallets: (wallets) => set({ wallets }),

  isLoading: false,
  setIsLoading: (isLoading) => set({ isLoading }),
}));

export const useWalletsStore = walletsStore;
