import { SVGAttributes } from 'react';

export function FileEdit(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M7.49998 11.2222V7.4889C7.49998 6.61771 7.49998 6.18212 7.66347 5.84937C7.80728 5.55667 8.03658 5.31868 8.31882 5.16955C8.63968 5 9.06003 5 9.9001 5H13.2559C13.6228 5 13.8063 5 13.979 5.04298C14.132 5.08109 14.2783 5.14395 14.4125 5.22924C14.5639 5.32544 14.6936 5.45994 14.953 5.72898L17.2971 8.15991C17.5565 8.42894 17.6859 8.56347 17.7786 8.72045C17.8609 8.85963 17.9214 9.01134 17.9581 9.17006C17.9996 9.34909 17.9999 9.53936 17.9999 9.91983V16.5111C17.9999 17.3823 18 17.8179 17.8365 18.1506C17.6927 18.4433 17.4629 18.6813 17.1807 18.8304C16.8598 19 16.44 19 15.5999 19H12.7499M9.74995 13.5556L11.2499 15.1111M18 9.66667H15.9C15.06 9.66667 14.6396 9.66664 14.3187 9.4971C14.0365 9.34796 13.8072 9.11001 13.6634 8.81731C13.4999 8.48456 13.4999 8.04897 13.4999 7.17778V5M6 19V17.0556L11.6249 11.2222L13.4999 13.1667L7.87498 19H6Z"
        stroke="black"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
