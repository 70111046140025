import { Flex, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { transactionService } from 'services/transactionService';
import { useWalletsStore } from 'store/wallets';
import useSWR from 'swr';
import { formatTime } from 'utilities/date';
import styles from './TransactionsView.module.css';
import { FiltersBlock } from './components/FiltersBlock';
import { TransactionsPlaceholder } from './components/TransactionsPlaceholder';
import { TransactionsTable } from './components/TransactionsTable';
import { useTransactionsFiltersStore } from './store';

export function TransactionsView() {
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [updatedAt, setUpdatedAt] = useState<Date>(new Date());
  const {
    type,
    tagged,
    tagIds,
    walletIds,
    tokenIds,
    amountFrom,
    amountTo,
    dateFrom,
    dateTo,
  } = useTransactionsFiltersStore();
  const setFilters = useTransactionsFiltersStore((s) => s.setFilters);
  const [wallets] = useWalletsStore((s) => [s.wallets]);

  const [requestKey, page] = useTransactionsFiltersStore((state) => [
    state.requestKey,
    state.page,
  ]);

  const { data: transactions, isLoading } = useSWR(
    requestKey,
    async () =>
      transactionService.getTransfers(
        {
          type,
          tagged,
          tagIds,
          walletIds,
          currencyIds: tokenIds,
          amountFrom,
          amountTo,
          dateFrom,
          dateTo,
        },
        page
      ),
    {
      revalidateOnFocus: false,
      keepPreviousData: true,
      onSuccess: () => {
        setUpdatedAt(new Date());
      },
      onError: (error) => {
        setErrorCode(error.response.status);
      },
    }
  );

  useEffect(() => {
    return () => {
      setFilters({
        tagIds: undefined,
        tokenIds: undefined,
        walletIds: wallets
          .filter((el) => !el.id)
          .map((wallet) => wallet.id) as string[],
        type: undefined,
        tagged: undefined,
        amountFrom: undefined,
        amountTo: undefined,
        dateFrom: undefined,
        dateTo: undefined,
      });
    };
  }, []);

  return (
    <Flex gap={32} vertical className={styles.block}>
      <Flex align="center" justify="space-between">
        <Flex gap={16} align="center">
          <Typography.Title level={2} className={styles.blockHeading}>
            Transactions
          </Typography.Title>
        </Flex>
        <Flex vertical align="end" justify="center">
          <Typography.Text className={styles.blockHeadingDescription}>
            Last updated: {wallets.length > 0 ? formatTime(updatedAt) : '–'}
          </Typography.Text>

          {errorCode && (
            <Typography.Text
              type="danger"
              className={styles.blockHeadingDescription}
            >
              Update failed with {errorCode} error. Refresh the page.
            </Typography.Text>
          )}
        </Flex>
      </Flex>
      {(transactions &&
        transactions?.meta?.total <= 0 &&
        requestKey === '/transfers&page=1') ||
      wallets.length <= 0 ? (
        <TransactionsPlaceholder />
      ) : (
        <>
          <FiltersBlock />
          <TransactionsTable
            isLoading={isLoading}
            transactions={transactions}
          />
        </>
      )}
    </Flex>
  );
}
