import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Flex, Input, Spin, Typography } from 'antd';
import { Error } from 'assets/icons/Error';
import { Template } from 'assets/icons/Templates';
import { useState } from 'react';
import { multisenderService } from 'services/multisender';
import useSWR from 'swr';
import { BatchItem } from '../BatchItem';
import styles from './styles.module.css';

export function TemplatesView() {
  const [search, setSearch] = useState<string>('');
  const { data, isLoading, error } = useSWR('/multisends/templates', () =>
    multisenderService.getMultisendsTemplates()
  );

  const filteredData = data?.filter((el) =>
    el.name.toLowerCase().includes(search.toLowerCase())
  );

  if (isLoading) {
    return (
      <Flex
        justify="center"
        align="center"
        className={styles.containerPlaceholder}
      >
        <Spin size="large" indicator={<LoadingOutlined spin />} />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex
        justify="center"
        align="center"
        vertical
        className={styles.containerPlaceholder}
      >
        <div className={styles.placeholderContent}>
          <Error />
          <Typography.Title level={1}>Something went wrong</Typography.Title>
          <Typography.Text>
            Multisender history can’t be loaded. Refresh the page.
          </Typography.Text>
        </div>
      </Flex>
    );
  }
  if (!data || data?.length <= 0) {
    return (
      <Flex
        justify="center"
        align="center"
        vertical
        className={styles.containerPlaceholder}
      >
        <div className={styles.placeholderContent}>
          <Template />
          <Typography.Title level={1}>No templates saved yet</Typography.Title>
          <Typography.Text>
            Your account doesn’t have any Multisender templates yet. Please save
            performed
            <br /> batch as a template.
          </Typography.Text>
        </div>
      </Flex>
    );
  }

  return (
    <>
      <Input
        size="large"
        placeholder="Search by name"
        prefix={<SearchOutlined />}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className={styles.searchInput}
      />
      {filteredData?.map((el) => (
        <BatchItem key={el.id} data={el} variant="template" />
      ))}
    </>
  );
}
