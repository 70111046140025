import { SVGAttributes } from "react";

export function Arbitrum(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    >
      <path
        d="M12.7226 10.2722L14.0023 8.10232L17.4516 13.4707L17.4532 14.5009L17.442 7.41147C17.4338 7.23812 17.3417 7.07955 17.1948 6.98601L10.9847 3.41669C10.8396 3.34533 10.6552 3.34619 10.5103 3.41894C10.4907 3.4288 10.4723 3.4394 10.4547 3.45108L10.4331 3.46469L4.40517 6.95516L4.38179 6.96587C4.35166 6.97958 4.32132 6.99726 4.29269 7.01762C4.17839 7.09948 4.10248 7.22065 4.07793 7.3564C4.07428 7.37697 4.07149 7.39797 4.07031 7.41919L4.07975 13.1964L7.29265 8.22039C7.69709 7.56062 8.57846 7.34794 9.39667 7.35962L10.3568 7.3849L4.69906 16.4516L5.36598 16.8352L11.0915 7.39412L13.6223 7.3849L7.91154 17.0643L10.2913 18.4322L10.5757 18.5955C10.696 18.6443 10.8377 18.6468 10.959 18.603L17.2564 14.9563L16.0524 15.6535L12.7226 10.2722ZM13.2108 17.2991L10.8072 13.5293L12.2745 11.0412L15.4312 16.0131L13.2108 17.2991Z"
        fill="#2D374B"
      />
      <path
        d="M10.8066 13.5296L13.2103 17.2992L15.4307 16.0134L12.274 11.0415L10.8066 13.5296Z"
        fill="#28A0F0"
      />
      <path
        d="M17.4552 14.5012L17.4536 13.4709L14.0043 8.10254L12.7246 10.2724L16.0545 15.6537L17.2585 14.9565C17.3765 14.8607 17.4481 14.72 17.4556 14.5683L17.4552 14.5012Z"
        fill="#28A0F0"
      />
      <path
        d="M3 15.4728L4.70022 16.4518L10.358 7.3851L9.39782 7.35982C8.57972 7.34825 7.69836 7.56082 7.29381 8.2206L4.0809 13.1966L3 14.8561V15.4728Z"
        fill="white"
      />
      <path
        d="M13.6254 7.38477L11.0947 7.39398L5.36914 16.8351L7.37044 17.9864L7.91459 17.0642L13.6254 7.38477Z"
        fill="white"
      />
      <path
        d="M18.5208 7.37182C18.4997 6.84297 18.2131 6.35891 17.7642 6.07702L11.4728 2.46162C11.0288 2.23823 10.4741 2.23791 10.0293 2.46152C9.97671 2.48798 3.91096 6.0033 3.91096 6.0033C3.827 6.04348 3.74616 6.09137 3.67014 6.1458C3.26945 6.43283 3.0252 6.87876 3 7.36861V14.856L4.0809 13.1965L4.07147 7.41918C4.07275 7.39807 4.07544 7.37718 4.07908 7.35672C4.10353 7.22075 4.17955 7.09958 4.29385 7.01761C4.32247 6.99726 10.4917 3.42879 10.5113 3.41893C10.6564 3.34619 10.8406 3.34533 10.9858 3.41668L17.1959 6.98601C17.3427 7.07954 17.4349 7.23811 17.443 7.41147V14.568C17.4355 14.7197 17.3755 14.8604 17.2574 14.9562L16.0534 15.6534L15.4322 16.0131L13.2118 17.2989L10.96 18.6029C10.8388 18.6467 10.6969 18.6442 10.5767 18.5954L7.91258 17.0642L7.36833 17.9865L9.76259 19.3639C9.84172 19.4089 9.91227 19.4487 9.97017 19.4813C10.0598 19.5316 10.1209 19.5651 10.1425 19.5756C10.3126 19.6582 10.5574 19.7062 10.7781 19.7062C10.9803 19.7062 11.1776 19.6691 11.3643 19.5961L17.9047 15.8113C18.2801 15.5206 18.501 15.0823 18.5208 14.6075V7.37182Z"
        fill="#96BEDC"
      />
    </svg>
  );
}
