import { Flex, Table, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { userService } from 'services/userService';
import useSWR from 'swr';
import { PayoutStatus } from 'types/user';
import { formatDate } from 'utilities/date';
import { formatUSDValue } from 'utilities/number';
import { toCapital } from 'utilities/string';

const columns: ColumnsType<any> = [
  {
    title: 'User email',
    dataIndex: 'email',
    width: 329,
  },
  {
    title: 'Signed up',
    dataIndex: 'signed_up_at',
    width: 176,
    render: (value) => formatDate(value),
  },
  {
    title: 'Payout',
    dataIndex: 'state',
    width: 95,
    render: (value) => {
      value = toCapital(value);
      let color;

      switch (value) {
        case PayoutStatus.Paid:
          color = 'green';
          break;
        case PayoutStatus.Processing:
          color = 'gold';
          break;
        case PayoutStatus.Pending:
          color = 'geekblue';
          break;
      }

      return <Tag color={color}>{value}</Tag>;
    },
  },
];

export function ReferralTable() {
  const { data, isLoading } = useSWR('/user/referrals', () =>
    userService.getInvitedUsers()
  );

  const totalEarned = useMemo(() => {
    if (!data || data.length <= 0) {
      return 0;
    }

    return data.reduce(
      (acc, { amount_paid }) => acc + parseFloat(amount_paid),
      0
    );
  }, [data]);

  return (
    <Flex vertical gap={20}>
      <Flex justify="space-between" align="center">
        <Typography.Title level={4} style={{ margin: 0 }}>
          Invited
        </Typography.Title>
        <Typography.Text className="largeText">
          You earned{' '}
          <span
            dangerouslySetInnerHTML={{
              __html: formatUSDValue(totalEarned, ''),
            }}
          />{' '}
          USDT
        </Typography.Text>
      </Flex>
      {(!data || (data?.length <= 0 && !isLoading)) && (
        <Typography.Text className="largeText">
          Start referring. Successful referrals will appear here.
        </Typography.Text>
      )}
      {(isLoading || (data && data?.length > 0)) && (
        <Table
          size="small"
          rowKey="email"
          pagination={false}
          columns={columns}
          loading={isLoading}
          dataSource={data}
        />
      )}
    </Flex>
  );
}
