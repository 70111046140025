import { Line } from '@ant-design/charts';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Flex, Segmented, Select, Spin, Typography } from 'antd';
import { NoWalletsPlaceholder } from 'components/NoWalletsPlaceholder';
import { useMemo, useState } from 'react';
import { walletService } from 'services/walletService';
import { useWalletsStore } from 'store/wallets';
import useSWR from 'swr';
import { Period } from 'types';
import { Stats } from 'types/wallet';
import { formatDateByPeriod } from 'utilities/date';
import { toCapital } from 'utilities/string';
import { formatAddress } from 'utilities/wallets';
import styles from '../../BalancesView.module.css';

export function BalancesChart() {
  const [wallets, isWalletsLoading] = useWalletsStore((s) => [
    s.wallets,
    s.isLoading,
  ]);
  const [selectedWallets, setSelectedWallets] = useState<string[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<Period>(Period.Month);
  const [isCSVLoading, setIsCSVLoading] = useState(false);

  const walletsFilter = useMemo(() => {
    if (selectedWallets.length === 0) return wallets.map((el) => el.id || '');

    return selectedWallets;
  }, [wallets, selectedWallets]);

  const key = useMemo(
    () =>
      `/user_wallets/balances/stats/chart/${walletsFilter.join(
        ','
      )}/${selectedPeriod}`,
    [walletsFilter, selectedPeriod]
  );

  const { data, isLoading } = useSWR(
    wallets.length > 0 ? key : null,
    () =>
      walletService.getStats(walletsFilter, selectedPeriod, [
        Stats.Pnl,
        Stats.Balance,
      ]),
    {
      revalidateOnFocus: false,
    }
  );

  const formattedData = useMemo(() => {
    if (!data) return [];

    let formattedData = [];

    for (const category in data) {
      const elements = data[category];
      if (elements) {
        for (const element of elements) {
          formattedData.push({
            Date: formatDateByPeriod(element[0], selectedPeriod),
            value: Number(element[1]),
            category: toCapital(category),
          });
        }
      }
    }

    return formattedData;
  }, [data, selectedPeriod]);

  const handleSelectWallets = (value: string[]) => {
    if (value.includes('all')) {
      setSelectedWallets([]);
      return;
    }

    setSelectedWallets(value);
  };

  const handleDownloadCSV = async () => {
    try {
      setIsCSVLoading(true);
      await walletService.downloadStatsAsCSV(walletsFilter, selectedPeriod, [
        Stats.Pnl,
        Stats.Balance,
      ]);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsCSVLoading(false);
    }
  };

  const isDataLoading = isLoading || isWalletsLoading;
  const hasWallets = wallets.length > 0 && !isWalletsLoading;
  const hasData = formattedData.length > 0 && !!data;

  return (
    <Flex gap={32} vertical className={styles.block}>
      <Flex justify="space-between">
        <Typography.Title level={2} className={styles.blockHeading}>
          Total Profit and loss
          <Typography.Text type="secondary">USDT</Typography.Text>
        </Typography.Title>
        <Flex gap={20} align="center">
          <Flex align="center" gap={8}>
            <Typography.Text>Wallet</Typography.Text>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '200px' }}
              placeholder="All"
              value={selectedWallets}
              onChange={handleSelectWallets}
              disabled={!hasWallets}
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
              filterSort={() => 0}
              options={[
                {
                  label: 'All',
                  value: 'all',
                },
                ...wallets.map((wallet) => ({
                  label: !wallet.name
                    ? formatAddress(wallet.wallet.address)
                    : wallet.name,
                  value: wallet.id,
                })),
              ]}
            />
          </Flex>
          <Flex align="center" gap={12}>
            <Typography.Text>Period</Typography.Text>
            <Segmented<Period>
              value={selectedPeriod}
              onChange={setSelectedPeriod}
              disabled={!hasWallets}
              options={[
                {
                  value: Period.Day,
                  label: 'Day',
                },
                {
                  value: Period.Week,
                  label: 'Week',
                },
                {
                  value: Period.Month,
                  label: 'Month',
                },
              ]}
            />
          </Flex>
          <Button
            icon={<DownloadOutlined />}
            loading={isCSVLoading}
            disabled={!hasWallets}
            onClick={handleDownloadCSV}
          >
            .csv
          </Button>
        </Flex>
      </Flex>
      {isDataLoading && (
        <Flex justify="center" align="center" style={{ height: 320 }}>
          <Spin />
        </Flex>
      )}
      {!isDataLoading && (!hasWallets || !hasData) && (
        <Flex justify="center" align="center" style={{ height: 320 }}>
          <NoWalletsPlaceholder />
        </Flex>
      )}
      {!isDataLoading && hasWallets && hasData && (
        <Line
          height={314}
          key={selectedPeriod}
          data={formattedData}
          xField="Date"
          yField="value"
          colorField="category"
          interaction={{
            tooltip: {
              marker: false,
            },
          }}
          style={{
            lineWidth: 2,
          }}
          axis={{
            x: {
              labelAutoRotate: selectedPeriod !== Period.Month,
            },
          }}
          legend={{
            color: {
              position: 'bottom',
              layout: { justifyContent: 'center' },
              itemMarker: 'square',
              itemLabelFill: '#000000',
              itemLabelFillOpacity: 0.65,
            },
          }}
        />
      )}
    </Flex>
  );
}
