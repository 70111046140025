import { Flex } from 'antd';
import { Multisend } from 'types/multisender';
import { toCapital } from 'utilities/string';
import styles from './styles.module.css';

interface TokenCellProps {
  currency: Multisend['multisend']['currency'];
  chain: Multisend['multisend']['chain'];
  chainIconUrl: Multisend['multisend']['chain_icon_url'];
}

export function TokenCell({ currency, chain, chainIconUrl }: TokenCellProps) {
  return (
    <Flex align="center" gap={8}>
      <div className={styles.transactionTokenContainer}>
        <img
          src={currency?.logo_url}
          alt={currency?.name}
          className={styles.transactionTokenIcon}
        />
        <img
          src={chainIconUrl}
          className={styles.transactionNetworkIcon}
          alt=""
        />
      </div>
      <Flex gap={2} vertical style={{ marginTop: -2, marginBottom: -2 }}>
        <span className={styles.transactionTokenName}>
          {currency?.symbol?.toUpperCase()}
        </span>
        <span className={styles.transactionNetworkName}>
          {toCapital(chain)}
        </span>
      </Flex>
    </Flex>
  );
}
