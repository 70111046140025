import axios, { AxiosInstance } from 'axios';
import { API_URL } from 'configs/api';
import { ACCESS_TOKEN_STORAGE_KEY, REFERRAL_CODE_KEY } from 'constants/auth';
import { userStore } from 'store/user';
import {
  InvitedUser,
  PaymentHistoryItem,
  SubscriptionPeriod,
  UserAuthResponse,
  UserPlans,
  UserResponse,
} from 'types/user';
import Cookies from 'universal-cookie';
import { getAccessToken } from 'utilities/user';

export class UserService {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
    });
  }

  /**
   * Method to authenticate user
   * @param email
   * @param password
   * @throws **[Important]** this method may throw an error
   */
  async login(email: string, password: string) {
    const { data } = await this._api.post<UserAuthResponse>('/login', {
      email,
      password,
    });

    const { setUser } = userStore.getState();
    setUser(data.user);

    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, data.token);

    return data;
  }

  /**
   * Method to create a new user
   * @param email
   * @param password
   * @throws **[Important]** this method may throw an error
   */
  async createUser(email: string, password: string) {
    const cookies = new Cookies();
    const referralCodeCookie = cookies.get(REFERRAL_CODE_KEY);

    const { data } = await this._api.post<UserAuthResponse>('/users', {
      email,
      password,
      ref_code: referralCodeCookie,
    });

    const { setUser } = userStore.getState();
    setUser(data.user);

    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, data.token);

    return data;
  }

  /**
   * Method to authenticate user with Google
   * @param code Google OAuth code
   * @throws **[Important]** this method may throw an error
   */
  async googleOAuth(code: string) {
    const cookies = new Cookies();
    const referralCodeCookie = cookies.get(REFERRAL_CODE_KEY);
    const res = await this._api.post<UserAuthResponse>('/google_oauth', {
      code,
      ref_code: referralCodeCookie,
    });

    const { setUser } = userStore.getState();
    setUser(res.data.user);

    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, res.data.token);

    return res;
  }

  /**
   * Method to get current user
   * @returns User object or null
   * @throws **[Important]** this method may throw an error
   */
  async getCurrentUser(accessToken?: UserAuthResponse['token']) {
    const { data } = await this._api.get<UserResponse>('/users', {
      headers: {
        authorization: accessToken ? `Bearer ${accessToken}` : getAccessToken(),
      },
    });

    const { setUser } = userStore.getState();
    setUser(data.user);

    return data;
  }

  /**
   * Method to logout user
   */
  async logout() {
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  }

  /**
   * Method to delete user
   * @throws **[Important]** this method may throw an error
   */
  async deleteUser() {
    await this._api.delete('/users', {
      headers: {
        authorization: getAccessToken(),
      },
    });

    await this.logout();
  }

  /**
   * Method to verify email
   * @throws **[Important]** this method may throw an error
   */
  async verifyEmail(code: string) {
    await this._api.post(
      '/users/verify_email',
      {
        email_pin: code,
      },
      {
        headers: {
          authorization: getAccessToken(),
        },
      }
    );
  }

  /**
   * Method to resend verification email
   * @throws **[Important]** this method may throw an error
   */
  async resendVerificationEmail() {
    await this._api.post(
      '/users/resend_email_pin',
      {},
      {
        headers: {
          authorization: getAccessToken(),
        },
      }
    );
  }

  /**
   * Method to get user payments history
   * @throws **[Important]** this method may throw an error
   */
  async getPayments() {
    const url = '/user_payments';
    const { data } = await this._api.get<{
      user_payments: PaymentHistoryItem[];
    }>(url, {
      headers: {
        authorization: getAccessToken(),
      },
    });

    return data.user_payments;
  }

  /**
   * Method to create a payment link
   * @returns Payment link
   */
  async createPayLink(plan: UserPlans, period: SubscriptionPeriod) {
    const url = '/user_payments/create_paylink';
    const { data } = await this._api.post<{ paylink: string }>(
      url,
      {
        plan,
        period,
      },
      {
        headers: {
          authorization: getAccessToken(),
        },
      }
    );

    return data.paylink;
  }

  /**
   *  Method to set or update user password
   */
  async updatePassword(new_password: string, current_password?: string) {
    const url = '/users/update_password';
    await this._api.post(
      url,
      {
        new_password,
        current_password,
      },
      {
        headers: {
          authorization: getAccessToken(),
          Accept: 'application/json',
        },
      }
    );
  }

  /**
   * Method to get invited users
   */
  async getInvitedUsers() {
    const { data } = await this._api.get<InvitedUser[]>('/users/referrals', {
      headers: {
        authorization: getAccessToken(),
      },
    });

    return data;
  }
}

export const userService = new UserService();
