import { Button, Flex, Typography } from 'antd';
import styles from './styles.module.css';
import { useOnboardingStore } from 'screens/Onboarding/store';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect } from 'react';
import { walletService } from 'services/walletService';
import { useNavigate } from 'react-router-dom';
import { SpinnerIcon } from 'assets/icons/Loader';
import { SuccessIcon } from 'assets/icons/Success';
import { FailedIcon } from 'assets/icons/Failed';
import { Copy } from 'assets/icons/CopyIcon';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { CheckOutlined } from '@ant-design/icons';
import { SUPPORT_EMAIL } from 'constants/contacts';

export function Scanning() {
  const navigate = useNavigate();
  const [scanningStatus, setScanningStatus] = useOnboardingStore((state) => [
    state.scanningStatus,
    state.setScanningStatus,
  ]);

  const hasUnprocessedWallets = useCallback(async () => {
    const wallets = await walletService.getWallets();

    return wallets.some((wallet) => !wallet.wallet.last_retrieved_at);
  }, []);

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (scanningStatus === 'inProgress') {
      hasUnprocessedWallets()
        .then((hasUnprocessed) => {
          if (!hasUnprocessed) {
            setScanningStatus('success');
          }
        })
        .catch(() => {
          setScanningStatus('failed');
        });

      id = setInterval(async () => {
        try {
          const hasUnprocessed = await hasUnprocessedWallets();
          if (!hasUnprocessed) {
            setScanningStatus('success');
            clearInterval(id);
          }
        } catch (error) {
          setScanningStatus('failed');
          clearInterval(id);
        }
      }, 10000);
    }

    return () => {
      clearInterval(id);
    };
  }, [scanningStatus, hasUnprocessedWallets]);

  useEffect(() => {
    let id: NodeJS.Timeout;

    if (scanningStatus === 'success') {
      id = setTimeout(() => {
        navigate('/');
        setScanningStatus(null);
      }, 5000);
    }

    return () => {
      clearInterval(id);
    };
  }, [scanningStatus]);

  const handleTryAgain = () => {
    setScanningStatus('inProgress');
  };

  const [copied, copy] = useCopyToClipboard(3000);

  const handleCopyEmail = () => {
    copy(SUPPORT_EMAIL);
  };

  return (
    <Flex
      justify="center"
      align="center"
      className={styles.container}
      style={{
        backgroundColor: '#F6F7F9',
      }}
      vertical
    >
      <AnimatePresence mode="wait">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key={scanningStatus}
        >
          <Flex
            justify="center"
            align="center"
            vertical
            className={styles.content}
          >
            {scanningStatus === 'inProgress' && (
              <SpinnerIcon className="animation-spinner" />
            )}
            {scanningStatus === 'success' && <SuccessIcon />}
            {scanningStatus === 'failed' && <FailedIcon />}
            <Typography.Title level={1} className={styles.scanningTitle}>
              {scanningStatus === 'inProgress' && 'Scanning wallets'}
              {scanningStatus === 'success' && 'Scanning succeed'}
              {scanningStatus === 'failed' && 'Scanning failed'}
            </Typography.Title>
            {scanningStatus === 'inProgress' && (
              <Typography.Paragraph className={styles.scanningDescription}>
                Process may take up to 10 minutes depending on total
                transactions amount. <br />
                You will be redirected to FinFlo dashboard automatically.
              </Typography.Paragraph>
            )}
            {scanningStatus === 'success' && (
              <Typography.Paragraph className={styles.scanningDescription}>
                You will be redirected to FinFlo dashboard automatically.
              </Typography.Paragraph>
            )}
            {scanningStatus === 'failed' && (
              <Flex gap={20} vertical>
                <Flex gap={8} vertical className={styles.scanningActions}>
                  <Button
                    onClick={handleTryAgain}
                    type="primary"
                    size="large"
                    block
                  >
                    Try again
                  </Button>
                  <Button
                    href={`mailto:${SUPPORT_EMAIL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="large"
                    block
                  >
                    Email to support
                  </Button>
                </Flex>
                <Flex justify="center" align="center">
                  <Typography.Text style={{ color: '#475467' }}>
                    {SUPPORT_EMAIL}
                  </Typography.Text>
                  <Button
                    type="link"
                    color="secondary"
                    icon={
                      copied === SUPPORT_EMAIL ? <CheckOutlined /> : <Copy />
                    }
                    onClick={handleCopyEmail}
                  />
                </Flex>
              </Flex>
            )}
          </Flex>
        </motion.div>
      </AnimatePresence>
    </Flex>
  );
}
