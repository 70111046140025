import { AxiosError } from 'axios';
import { ACCESS_TOKEN_STORAGE_KEY } from 'constants/auth';
import { Routes } from 'constants/routs';
import { useReferralCodeInterceptor } from 'hooks/useReferralCodeInterceptor';
import { ReactNode, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { userService } from 'services/userService';
import { UserAuthResponseCodes } from 'types/user';

interface PrivateRouteProps {
  children?: ReactNode;
  withoutVerifiedEmail?: boolean;
  withoutPayment?: boolean;
}

export function PrivateRoute({
  children,
  withoutVerifiedEmail,
  withoutPayment,
}: PrivateRouteProps) {
  const navigate = useNavigate();
  const isAuthorized = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) !== null;

  useReferralCodeInterceptor();

  useEffect(() => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    if (!accessToken) return;

    userService.getCurrentUser(accessToken).catch((e) => {
      if (e instanceof AxiosError) {
        if (
          !withoutVerifiedEmail &&
          e.response?.data?.code ===
            UserAuthResponseCodes.EmailVerificationRequired
        ) {
          navigate(Routes.EmailConfirm);
        } else if (
          withoutVerifiedEmail &&
          e.response?.data?.code !==
            UserAuthResponseCodes.EmailVerificationRequired
        ) {
          navigate('/');
        } else if (
          withoutVerifiedEmail &&
          e.response?.data?.code ===
            UserAuthResponseCodes.EmailVerificationRequired
        ) {
          return;
        } else {
          localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
          navigate(Routes.LogIn);
        }
      }
    });
  }, [navigate, withoutVerifiedEmail, withoutPayment]);

  if (!isAuthorized) {
    return <Navigate to={Routes.LogIn} />;
  }

  return <>{children}</>;
}
