import { SVGAttributes } from "react";

export function FailedIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_2_7360)">
        <path
          d="M47.6441 60.2143C47.6441 60.8394 47.8924 61.439 48.3345 61.881C48.7765 62.3231 49.3761 62.5714 50.0012 62.5714C50.6264 62.5714 51.2259 62.3231 51.668 61.881C52.11 61.439 52.3584 60.8394 52.3584 60.2143C52.3584 59.5891 52.11 58.9896 51.668 58.5475C51.2259 58.1055 50.6264 57.8571 50.0012 57.8571C49.3761 57.8571 48.7765 58.1055 48.3345 58.5475C47.8924 58.9896 47.6441 59.5891 47.6441 60.2143V60.2143ZM48.4298 45.2857V54.3214C48.4298 54.5375 48.6066 54.7143 48.8227 54.7143H51.1798C51.3959 54.7143 51.5727 54.5375 51.5727 54.3214V45.2857C51.5727 45.0696 51.3959 44.8929 51.1798 44.8929H48.8227C48.6066 44.8929 48.4298 45.0696 48.4298 45.2857ZM71.7901 66.8929L51.3615 31.5357C51.057 31.0103 50.5316 30.75 50.0012 30.75C49.4709 30.75 48.9405 31.0103 48.641 31.5357L28.2124 66.8929C27.6084 67.9438 28.3646 69.25 29.5727 69.25H70.4298C71.6379 69.25 72.3941 67.9438 71.7901 66.8929ZM33.3146 65.5228L50.0012 36.6379L66.6879 65.5228H33.3146V65.5228Z"
          fill="#F5222D"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_7360">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
