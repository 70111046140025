import { create } from 'zustand';

export interface TagsStore {
  tags: {
    id: string | null;
    name: string;
  }[];

  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;

  setTags: (tags: TagsStore['tags']) => void;
  addTag: (tag: TagsStore['tags'][0]) => void;
}

export const tagsStore = create<TagsStore>((set) => ({
  tags: [],
  isLoading: true,
  setIsLoading: (isLoading) => set({ isLoading }),
  setTags: (tags) => set({ tags }),
  addTag: (tag) => set((state) => ({ tags: [...state.tags, tag] })),
}));

export const useTagsStore = tagsStore;
