import { useGoogleLogin } from '@react-oauth/google';
import { Alert, Button, Checkbox, Flex, Form, Input, Typography } from 'antd';
import { GoogleLogo } from 'assets/icons/GoogleLogo';
import { AxiosError } from 'axios';
import { Routes } from 'constants/routs';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { userService } from 'services/userService';
import { useSettingsStore } from 'store/settings';
import { UserAuthResponseCodes } from 'types/user';
import { toCapital } from 'utilities/string';
import styles from './LogInView.module.css';

interface LogInForm {
  email: string;
  password: string;
  remember: boolean;
}

export function LogInView() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGoogleSubmitting, setIsGoogleSubmitting] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm<LogInForm>();
  const [error, setError] = useState<string | null>(null);
  const [setShouldOpenWelcomeDialog] = useSettingsStore((s) => [
    s.setShouldOpenWelcomeDialog,
  ]);

  const handleSubmit = async (formData: LogInForm) => {
    setIsSubmitting(true);
    setError(null);
    try {
      const data = await userService.login(formData.email, formData.password);

      if (data.code === UserAuthResponseCodes.EmailVerificationRequired) {
        navigate(Routes.EmailConfirm);
      } else {
        navigate('/');
      }

      setShouldOpenWelcomeDialog(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        const parsedErrors = Object.entries(
          (error.response?.data.errors as
            | Record<string, string[]>
            | undefined) ?? {}
        );
        if (parsedErrors.length > 0) {
          setError(toCapital(parsedErrors[0][0]) + ' ' + parsedErrors[0][1][0]);
        } else {
          setError(error.message);
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (tokenResponse) => {
      try {
        const res = await userService.googleOAuth(tokenResponse.code);

        if (res.data.code === UserAuthResponseCodes.EmailVerificationRequired) {
          navigate(Routes.EmailConfirm);
          return;
        }

        if (res.status === 201) {
          navigate(Routes.Onboarding);
        } else {
          navigate(Routes.ProfitAndLoss);
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          setError(error.response?.data.message || error.message);
        }
      } finally {
        setIsGoogleSubmitting(false);
      }
    },
    onError: (error) => {
      setError(error.error_description ?? null);
      setIsGoogleSubmitting(false);
    },
    onNonOAuthError: () => {
      setIsGoogleSubmitting(false);
    },
  });

  return (
    <Flex vertical>
      <Typography.Paragraph className={styles.formDescription}>
        Welcome back! Please enter your details.
      </Typography.Paragraph>
      <Form<LogInForm>
        form={form}
        validateTrigger={['onSubmit', 'onChange']}
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <Flex gap={24} vertical>
          <Flex gap={20} vertical>
            <Form.Item<LogInForm>
              style={{ marginBottom: 0 }}
              labelCol={{ span: 24 }}
              label="Email"
              name="email"
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: 'This field is required.',
                  validateTrigger: 'onSubmit',
                },
                {
                  type: 'email',
                  message: 'This address is not valid.',
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter your email"
                autoComplete="email"
              />
            </Form.Item>
            <Form.Item<LogInForm>
              style={{ marginBottom: 0 }}
              labelCol={{ span: 24 }}
              label="Password"
              name="password"
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: 'This field is required.',
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Input.Password
                size="large"
                placeholder="Enter password"
                autoComplete="new-password"
              />
            </Form.Item>
          </Flex>
          <Form.Item<LogInForm>
            name="remember"
            valuePropName="checked"
            wrapperCol={{ span: 24 }}
            style={{ marginBottom: 0 }}
            className={styles.formItemCheckbox}
          >
            <Checkbox>Remember for 30 days</Checkbox>
          </Form.Item>

          <Flex vertical gap={32}>
            <Flex vertical gap={12}>
              <Button
                disabled={isGoogleSubmitting}
                loading={isSubmitting}
                size="large"
                type="primary"
                htmlType="submit"
                block
              >
                Log in
              </Button>
              <Button
                disabled={isSubmitting}
                loading={isGoogleSubmitting}
                size="large"
                block
                icon={<GoogleLogo />}
                onClick={() => {
                  googleLogin();
                  setIsGoogleSubmitting(true);
                }}
                className="google-button"
              >
                Sign in with Google
              </Button>
            </Flex>
            <Flex justify="center">
              <Typography.Paragraph className={styles.formAlternative}>
                Don’t have an account? <Link to="/sign-up">Sign up</Link>
              </Typography.Paragraph>
            </Flex>
            {error && (
              <Alert
                message={error}
                type="error"
                showIcon
                closable
                style={{ marginTop: '4px' }}
              />
            )}
          </Flex>
        </Flex>
      </Form>
    </Flex>
  );
}
