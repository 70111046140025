import { App, Flex, Form, Input, Modal, Select, Typography } from 'antd';
import { AxiosError } from 'axios';
import WAValidator from 'multicoin-address-validator';
import { useState } from 'react';
import { useTransactionsFiltersStore } from 'screens/Transactions/store';
import { walletService } from 'services/walletService';
import { useSettingsStore } from 'store/settings';
import { useSWRConfig } from 'swr';
import { Wallet } from 'types/wallet';
import { getNetworkType } from 'utilities/wallets';
import styles from './styles.module.css';

interface AddWalletDialogProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  wallet?: Wallet;
}

interface AddWalletFormType {
  name: string;
  address: string;
  network: string;
}

export function AddWalletDialog({
  isOpen,
  onOpenChange,
  wallet,
}: AddWalletDialogProps) {
  const settings = useSettingsStore((s) => s.settings);
  const { notification } = App.useApp();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const network = Form.useWatch(['network'], form);
  const { mutate } = useSWRConfig();
  const transactionRequestKey = useTransactionsFiltersStore(
    (s) => s.requestKey
  );

  const isEdit = !!wallet;

  const handleSubmit = async (values: AddWalletFormType) => {
    try {
      setIsSubmitting(true);

      if (isEdit && wallet?.id) {
        await walletService.editWallet(wallet.id, values.name ?? '');
      } else {
        await walletService.createWallet(
          values.name ?? '',
          values.address,
          values.network
        );
      }

      await mutate(transactionRequestKey, undefined, {
        revalidate: false,
      });

      handleOpenChange(false);
      notification.success({
        message: isEdit ? 'Edit wallet' : 'Add wallet',
        description: isEdit
          ? 'Changes applied successfully!'
          : 'Wallet added successfully!',
        placement: 'bottom',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        notification.error({
          message: isEdit ? 'Edit wallet' : 'Add wallet',
          description: error.response?.data.message || error.message,
          placement: 'bottom',
        });
      } else if (error instanceof Error) {
        notification.error({
          message: isEdit ? 'Edit wallet' : 'Add wallet',
          description: error.message,
          placement: 'bottom',
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenChange = (isOpen: boolean) => {
    onOpenChange(isOpen);

    if (isOpen) return;

    form.resetFields();
  };

  return (
    <Modal
      open={isOpen}
      afterOpenChange={handleOpenChange}
      centered
      onOk={() => form.submit()}
      onCancel={() => handleOpenChange(false)}
      title={isEdit ? 'Edit wallet' : 'Add wallet'}
      okText={isEdit ? 'Save' : 'Add wallet'}
      okButtonProps={{
        loading: isSubmitting,
      }}
      style={{
        maxWidth: 408,
      }}
    >
      <Form<AddWalletFormType>
        form={form}
        onFinish={handleSubmit}
        validateTrigger={['onSubmit', 'onChange']}
        requiredMark={false}
      >
        <Flex vertical gap={20}>
          <Form.Item<AddWalletFormType>
            style={{ marginBottom: 0 }}
            labelCol={{ span: 24 }}
            label="Name"
            name="name"
            className={styles.addWalletFormItem}
            initialValue={wallet?.name ?? ''}
          >
            <Input size="large" placeholder="eg Salary (optional)" />
          </Form.Item>
          <Flex vertical gap={12}>
            <Form.Item<AddWalletFormType>
              style={{ marginBottom: 0 }}
              labelCol={{ span: 24 }}
              name="network"
              initialValue={wallet?.wallet?.network}
              hasFeedback={{
                icons: () => ({
                  success: ' ',
                }),
              }}
              label={
                <>
                  Wallet address type (network){' '}
                  {!isEdit && (
                    <span className={styles.addWalletFormLabelRequired}>*</span>
                  )}
                </>
              }
              className={styles.addWalletFormItem}
              rules={[
                {
                  required: !isEdit,
                  message: 'This field is required.',
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select wallet address type"
                optionFilterProp="label"
                disabled={isEdit}
                options={settings.networks.map((network, i) => ({
                  label: getNetworkType(network.code),
                  value: network.code,
                  key: i,
                }))}
              />
            </Form.Item>
            {network === 'tron' && (
              <Typography.Text className="text-description">
                Use address of this type to operate with tokens on Tron network.
              </Typography.Text>
            )}
            {network === 'eth' && (
              <Typography.Text className="text-description">
                Use address of this type to operate with tokens on Ethereum,
                Base, Polygon, Avalanche, Arbitrum, and other compatible
                networks.
              </Typography.Text>
            )}
          </Flex>

          <Form.Item<AddWalletFormType>
            style={{ marginBottom: 0 }}
            labelCol={{ span: 24 }}
            name="address"
            initialValue={wallet?.wallet?.address ?? ''}
            hasFeedback={{
              icons: () => ({
                success: ' ',
              }),
            }}
            label={
              <>
                Wallet address{' '}
                {!isEdit && (
                  <span className={styles.addWalletFormLabelRequired}>*</span>
                )}
              </>
            }
            className={styles.addWalletFormItem}
            rules={[
              {
                required: !isEdit,
                message: 'This field is required.',
                validateTrigger: 'onSubmit',
              },
              {
                validateTrigger: 'onSubmit',
                validator: async (_, value) => {
                  const res = WAValidator.validate(value, network);
                  if (value?.length <= 0 || !value) return Promise.resolve();
                  if (!res) {
                    return Promise.reject('This address is not valid.');
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter your wallet address"
              disabled={isEdit}
            />
          </Form.Item>
        </Flex>
      </Form>
    </Modal>
  );
}
