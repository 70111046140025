import { InfoCircleFilled } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Flex,
  Form,
  InputNumber,
  Radio,
  Row,
  Select,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useId, useMemo, useState } from 'react';
import { useTransactionsFiltersStore } from 'screens/Transactions/store';
import { addressBookService } from 'services/addressBook';
import { transactionService } from 'services/transactionService';
import { useTagsStore } from 'store/tags';
import { useWalletsStore } from 'store/wallets';
import useSWR from 'swr';
import { formatAddress } from 'utilities/wallets';
import styles from './styles.module.css';
import { TransactionTagged } from 'types';

interface FiltersFormSchema {
  type: string;
  tagged: string;
  tags: string[];
  wallets: string[];
  tokens: number[];
  amountFrom: number;
  amountTo: number;
  dateRange: [DatePickerProps['value'], DatePickerProps['value']];
}

export function FiltersBlock() {
  const [form] = Form.useForm<FiltersFormSchema>();
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const [wallets] = useWalletsStore((s) => [s.wallets]);
  const [tags] = useTagsStore((s) => [s.tags]);
  const [filters, setFilters] = useTransactionsFiltersStore((s) => [
    s,
    s.setFilters,
  ]);
  const { data: counterparties } = useSWR(
    '/counterparties',
    () => addressBookService.getCounterparties(),
    { revalidateOnFocus: false }
  );
  const { data: counterpartyWallets } = useSWR(
    '/counterparties_wallets',
    () => addressBookService.getWallets(),
    { revalidateOnFocus: false }
  );
  const allTagsOption = useId();
  const selectedTags = Form.useWatch('tags', form);

  useEffect(() => {
    form.setFieldsValue({
      type: filters.type,
      tagged: filters.tagged,
      tags: filters.tagIds,
      wallets: filters.walletIds.length > 0 ? filters.walletIds : undefined,
      tokens: filters.tokenIds,
      amountFrom: filters.amountFrom,
      amountTo: filters.amountTo,
      dateRange: [
        filters.dateFrom ? dayjs(filters.dateFrom) : undefined,
        filters.dateTo ? dayjs(filters.dateTo) : undefined,
      ],
    });
  }, [
    form,
    filters.type,
    filters.tagged,
    filters.tagIds,
    filters.walletIds,
    filters.tokenIds,
    filters.amountFrom,
    filters.amountTo,
    filters.dateFrom,
    filters.dateTo,
  ]);

  const handleSubmit = (values: FiltersFormSchema) => {
    let walletsFilter =
      values?.wallets?.length > 0
        ? values?.wallets
        : (wallets
            .filter((el) => !el.id)
            .map((wallet) => wallet.id) as string[]);
    let selectedTags = values?.tags?.filter(
      (tag) => tag !== allTagsOption
    ) as string[];

    let tagsFilter = selectedTags?.length > 0 ? selectedTags : undefined;
    let tokensFilter = values?.tokens?.length > 0 ? values?.tokens : undefined;

    setFilters({
      tagIds: tagsFilter,
      tokenIds: tokensFilter,
      walletIds: walletsFilter,
      type: values.type === 'all' ? undefined : values.type,
      tagged: values.tags?.includes(TransactionTagged.NotMarked)
        ? TransactionTagged.NotMarked
        : undefined,
      amountFrom: values.amountFrom,
      amountTo: values.amountTo,
      dateFrom: values.dateRange?.[0]?.format('YYYY-MM-DD'),
      dateTo: values.dateRange?.[1]?.format('YYYY-MM-DD'),
    });
  };

  const handleClear = () => {
    form.resetFields();
    setFilters({
      tagIds: undefined,
      tokenIds: undefined,
      walletIds: wallets
        .filter((el) => !el.id)
        .map((wallet) => wallet.id) as string[],
      type: undefined,
      tagged: undefined,
      amountFrom: undefined,
      amountTo: undefined,
      dateFrom: undefined,
      dateTo: undefined,
    });
  };

  const handleDownloadCSV = async () => {
    try {
      setIsCSVLoading(true);
      await transactionService.getTransfersAsCSV(filters, filters.page);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsCSVLoading(false);
    }
  };

  const walletsOptions = useMemo(() => {
    const sourceWallets = wallets
      .map((wallet) => ({
        label: !wallet.name
          ? formatAddress(wallet.wallet.address, 8, 8)
          : wallet.name,
        value: wallet.wallet.address,
      }))
      .sort((a, b) => a.label?.localeCompare(b.label));

    const counterpartyOptions =
      counterpartyWallets
        ?.map((wallet) => {
          const counterparty = counterparties?.find(
            (el) => el.id === wallet.counterparty_id
          );

          const walletName =
            wallet.name ?? formatAddress(wallet.wallet.address, 8, 8);

          return {
            label: `${counterparty?.name} - ${walletName}`,
            value: wallet.wallet.address,
          };
        })
        .sort((a, b) => a.label?.localeCompare(b.label)) ?? [];

    return [...sourceWallets, ...counterpartyOptions];
  }, [wallets, counterpartyWallets, counterparties]);

  return (
    <Form<FiltersFormSchema>
      form={form}
      validateTrigger={['onSubmit', 'onChange']}
      requiredMark={false}
      labelCol={{ span: 24 }}
      className={styles.filtersContent}
      onFinish={handleSubmit}
    >
      <Row gutter={16}>
        <Col span={4}>
          <Form.Item<FiltersFormSchema>
            name="type"
            label="Type"
            className={styles.filtersContentItem}
          >
            <Radio.Group>
              <Radio.Button value={undefined}>All</Radio.Button>
              <Radio.Button value="income">In</Radio.Button>
              <Radio.Button value="expense">Out</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item<FiltersFormSchema>
            name="wallets"
            label="Wallet"
            className={styles.filtersContentItem}
            tooltip={{
              title:
                'Filtering available by Source, Target wallets or wallet address',
              placement: 'top',
              icon: (
                <span role="img" aria-label="question-circle">
                  <InfoCircleFilled
                    style={{
                      width: 10.5,
                      height: 10.5,
                      color: '#1677FF',
                    }}
                  />
                </span>
              ),
              overlayInnerStyle: { textAlign: 'center' },
            }}
          >
            <Select
              mode="tags"
              allowClear
              placeholder="All"
              listHeight={320}
              maxCount={1}
              onChange={(value) => {
                if (value.includes('all')) {
                  form.setFieldsValue({ wallets: [] });
                  return;
                }
              }}
              filterOption={(input, option) =>
                (option?.label?.toString().toLowerCase() ?? '').includes(
                  input.toLowerCase()
                )
              }
              filterSort={() => 0}
              options={[
                {
                  label: 'All',
                  value: 'all',
                },
                ...walletsOptions,
              ]}
              notFoundContent={false}
              tagRender={({ label, value }: any) => (
                <Typography.Text
                  ellipsis
                  style={{
                    display: 'block',
                    maxWidth: '300px',
                    height: '20px',
                    marginTop: '0px',
                    marginLeft: '9px',
                  }}
                >
                  {label ?? value}
                </Typography.Text>
              )}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item<FiltersFormSchema>
            name="tags"
            label="Tags"
            className={styles.filtersContentItem}
            shouldUpdate
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="All"
              maxCount={
                selectedTags?.includes(TransactionTagged.NotMarked)
                  ? 1
                  : undefined
              }
              filterOption={(input, option) =>
                (option?.children?.toString().toLowerCase() ?? '').includes(
                  input.toLowerCase()
                )
              }
              filterSort={() => 0}
              onChange={(value) => {
                if (value.includes(allTagsOption)) {
                  form.setFieldsValue({ tags: [] });
                  return;
                }
              }}
            >
              <Select.Option value={allTagsOption}>All</Select.Option>
              <Select.Option value={TransactionTagged.NotMarked}>
                Untagged
              </Select.Option>
              {tags.map((tag) => (
                <Select.Option key={tag.id} value={tag.id}>
                  {tag.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Amount range" className={styles.filtersContentItem}>
            <Flex align="center" gap={16}>
              <Form.Item<FiltersFormSchema>
                name="amountFrom"
                className={styles.filtersContentItem}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="From"
                  min={0}
                />
              </Form.Item>
              <Form.Item<FiltersFormSchema>
                name="amountTo"
                className={styles.filtersContentItem}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value <= form.getFieldValue('amountFrom')) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                    validateTrigger: 'onSubmit',
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Up to"
                  min={0}
                />
              </Form.Item>
            </Flex>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item<FiltersFormSchema>
            label="Date range"
            className={styles.filtersContentItem}
            name="dateRange"
          >
            <DatePicker.RangePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Flex
            gap={16}
            align="end"
            style={{
              height: '100%',
            }}
            className={styles.filtersContentItem}
          >
            <Button block loading={isCSVLoading} onClick={handleDownloadCSV}>
              .csv
            </Button>
            <Button block onClick={handleClear}>
              Clear
            </Button>
            <Button htmlType="submit" block type="primary">
              Apply
            </Button>
          </Flex>
        </Col>
      </Row>
    </Form>
  );
}
