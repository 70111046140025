export const toCapital = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatEmail = (email: string) => {
  let [username, domain] = email.split('@');

  let shortUsername = username;

  if (username.length > 3) {
    let ending =
      username.length > 6 ? username.slice(Math.max(-(username.length - 6), -3)) : '';

    shortUsername = username.substring(0, 3) + '***' + ending;
  }

  return `${shortUsername}@${domain}`;
};
