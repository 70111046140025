import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Input,
  InputRef,
  Select,
  SelectProps,
  Space,
} from 'antd';
import { ChangeEvent, MouseEvent, useRef, useState } from 'react';
import { useTagsStore } from 'store/tags';

export function TagSelect(props: SelectProps) {
  const [tags, addTag] = useTagsStore((s) => [s.tags, s.addTag]);
  const [tag, setTag] = useState('');
  const inputRef = useRef<InputRef>(null);

  const onTagChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTag(event.target.value);
  };

  const addItem = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!tag || tags.some((el) => el.name === tag)) {
      return;
    }

    addTag({ id: null, name: tag });
    setTag('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Select
      placeholder="Apply tag"
      showSearch
      options={tags.map((item, i) => ({ key: item.id ?? i, value: item.name }))}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: '8px 0',
            }}
          />
          <Space
            style={{
              padding: '0 8px 4px',
            }}
          >
            <Input
              placeholder="Please enter item"
              ref={inputRef}
              value={tag}
              onChange={onTagChange}
              onKeyDown={(e) => e.stopPropagation()}
              name="tagName"
            />
            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
              Add item
            </Button>
          </Space>
        </>
      )}
      {...props}
    />
  );
}
