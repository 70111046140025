import { Button, Card, Flex, Form, Input, Tag, Typography } from 'antd';
import WAValidator from 'multicoin-address-validator';
import { useCallback } from 'react';
import { addressBookService } from 'services/addressBook';
import { useSettingsStore } from 'store/settings';
import { CounterpartyWallet } from 'types/counterparty';
import { getNetworkType } from 'utilities/wallets';
import { CounterpartyDialogForm } from '.';
import styles from './styles.module.css';
import { useWalletsStore } from 'store/wallets';
import { useSWRConfig } from 'swr';

interface WalletsListProps {
  value?: CounterpartyWallet[];
  onChange?: () => void;
  counterpartyWallets: CounterpartyWallet[] | undefined;
}

export function WalletsList({
  value,
  onChange,
  counterpartyWallets,
}: WalletsListProps) {
  const settings = useSettingsStore((s) => s.settings);
  const form = Form.useFormInstance<CounterpartyDialogForm>();
  const wallets = useWalletsStore((s) => s.wallets);
  const { mutate } = useSWRConfig();

  const handleRemove = async (id: string) => {
    try {
      const wallets = value?.filter(
        (wallet: CounterpartyWallet) => wallet.id !== id
      );
      form.setFieldValue('wallets', wallets);

      await addressBookService.deleteWalletFromCounterparty(id);
      await mutate('/counterparties?with_turnover');
      await mutate('/counterparties_wallets?with_turnover');
    } catch (error) {}
  };

  const getNetworkLabel = useCallback(
    (networkCode: string) => {
      const network = settings.networks.find(
        (network) => network.code === networkCode
      );

      if (!network) return null;

      return getNetworkType(network.code);
    },
    [settings.networks]
  );

  if (value && value.length > 0) {
    return (
      <div className={styles.walletsList}>
        {value.map((wallet, i) => (
          <Card key={wallet.id} classNames={{ body: styles.walletsListItem }}>
            <Flex gap={8} vertical>
              <Flex style={{ minHeight: 22 }}>
                {wallet.wallet.network && (
                  <Tag color="geekblue">
                    {getNetworkLabel(wallet.wallet.network)}
                  </Tag>
                )}
                <Button
                  type="link"
                  danger
                  className={styles.removeButton}
                  onClick={() => handleRemove(wallet.id)}
                >
                  Remove from counterparty
                </Button>
              </Flex>
              <Form.Item
                label="Wallet address"
                name={['wallets', i, 'wallet', 'address']}
                className={styles.label}
                labelCol={{ span: 24 }}
                style={{ margin: 0 }}
                hasFeedback={{
                  icons: () => ({
                    success: ' ',
                  }),
                }}
                rules={[
                  { required: true, message: '' },
                  {
                    validateTrigger: 'onSubmit',
                    validator(rule, address) {
                      let network = wallet?.wallet.network;
                      if (!network) {
                        for (const tempNetwork of settings.networks) {
                          const res = WAValidator.validate(
                            address,
                            tempNetwork.code
                          );
                          if (res) {
                            network = tempNetwork.code;
                            let newWallets = value;
                            newWallets[i].wallet.network = tempNetwork.code;
                            form.setFieldValue('wallets', newWallets);
                            break;
                          }
                        }
                      }

                      const res = WAValidator.validate(address, network);
                      if (address.length === 0) return Promise.resolve();
                      if (!res) {
                        return Promise.reject('');
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                  {
                    validateTrigger: 'onSubmit',
                    validator(rule, val) {
                      const isDuplicate = counterpartyWallets?.some(
                        (w) => w.wallet.address === val && w.id !== wallet.id
                      );

                      if (isDuplicate) {
                        return Promise.reject('This address already exists.');
                      }

                      if (wallets.some((w) => w.wallet.address === val)) {
                        return Promise.reject('This address already exists.');
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Add wallet address" />
              </Form.Item>
              <Form.Item
                label="Wallet name"
                name={['wallets', i, 'name']}
                labelCol={{ span: 24 }}
                className={styles.label}
                style={{ margin: 0 }}
                hasFeedback={{
                  icons: () => ({
                    success: ' ',
                  }),
                }}
              >
                <Input placeholder="Add wallet name" />
              </Form.Item>
            </Flex>
          </Card>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.walletsList}>
      <div className={styles.walletsListPlaceholder}>
        <Typography.Text>No wallets added yet</Typography.Text>
      </div>
    </div>
  );
}
