import { SVGAttributes } from 'react';

export function GasOutlined(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M1.33398 14.6666V2.81473C1.33398 2.40733 1.49223 2.05856 1.80873 1.76844C2.12523 1.47831 2.5057 1.33325 2.95015 1.33325H7.79863C8.24308 1.33325 8.62355 1.47831 8.94005 1.76844C9.25654 2.05856 9.41479 2.40733 9.41479 2.81473V7.99992H10.2229C10.6673 7.99992 11.0478 8.14498 11.3643 8.4351C11.6808 8.72523 11.839 9.07399 11.839 9.4814V12.8147C11.839 13.0246 11.9165 13.2005 12.0714 13.3425C12.2262 13.4845 12.4182 13.5555 12.6471 13.5555C12.8761 13.5555 13.068 13.4845 13.2229 13.3425C13.3778 13.2005 13.4552 13.0246 13.4552 12.8147V7.4814C13.334 7.54313 13.206 7.58325 13.0714 7.60177C12.9367 7.62029 12.7953 7.62955 12.6471 7.62955C12.0815 7.62955 11.6033 7.45054 11.2128 7.09251C10.8222 6.73449 10.6269 6.29622 10.6269 5.7777C10.6269 5.38263 10.7448 5.0277 10.9804 4.71288C11.2161 4.39807 11.5293 4.17276 11.9198 4.03696L10.2229 2.4814L11.0714 1.70362L14.0613 4.37029C14.2633 4.55547 14.4148 4.77152 14.5158 5.01844C14.6168 5.26535 14.6673 5.51844 14.6673 5.7777V12.8147C14.6673 13.3333 14.472 13.7715 14.0815 14.1295C13.6909 14.4876 13.2128 14.6666 12.6471 14.6666C12.0815 14.6666 11.6033 14.4876 11.2128 14.1295C10.8222 13.7715 10.6269 13.3333 10.6269 12.8147V9.11103H9.41479V14.6666H1.33398ZM2.95015 6.51844H7.79863V2.81473H2.95015V6.51844ZM12.6471 6.51844C12.8761 6.51844 13.068 6.44745 13.2229 6.30547C13.3778 6.1635 13.4552 5.98757 13.4552 5.7777C13.4552 5.56782 13.3778 5.39189 13.2229 5.24992C13.068 5.10794 12.8761 5.03696 12.6471 5.03696C12.4182 5.03696 12.2262 5.10794 12.0714 5.24992C11.9165 5.39189 11.839 5.56782 11.839 5.7777C11.839 5.98757 11.9165 6.1635 12.0714 6.30547C12.2262 6.44745 12.4182 6.51844 12.6471 6.51844ZM2.95015 13.1851H7.79863V7.99992H2.95015V13.1851Z"
        fill="currentColor"
      />
    </svg>
  );
}
