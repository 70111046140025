import { SVGAttributes } from "react";

export function WalletConnect(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="116"
      height="20"
      viewBox="0 0 116 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.90533 6.31921C7.01585 3.22758 12.0576 3.22758 15.1681 6.31921L15.5419 6.6923C15.6965 6.84721 15.6965 7.09812 15.5419 7.25303L14.2616 8.52503C14.1842 8.60139 14.0575 8.60139 13.9801 8.52503L13.4646 8.0123C11.295 5.85667 7.7763 5.85667 5.60667 8.0123L5.05459 8.55994C4.97726 8.6363 4.85052 8.6363 4.77318 8.55994L3.49289 7.28794C3.33822 7.13303 3.33822 6.88212 3.49289 6.72721L3.90319 6.31921H3.90533ZM17.8167 8.95048L18.9553 10.0828C19.1099 10.2378 19.1099 10.4887 18.9553 10.6436L13.8169 15.7512C13.6622 15.9061 13.4087 15.9061 13.2541 15.7512L9.60652 12.1272C9.56785 12.0879 9.50556 12.0879 9.46474 12.1272L5.81718 15.7512C5.66252 15.9061 5.40904 15.9061 5.25437 15.7512L0.116 10.6436C-0.0386667 10.4887 -0.0386667 10.2378 0.116 10.0828L1.25452 8.95048C1.40919 8.79557 1.66267 8.79557 1.81733 8.95048L5.46489 12.5745C5.50356 12.6138 5.56585 12.6138 5.60667 12.5745L9.25422 8.95048C9.40889 8.79557 9.66237 8.79557 9.81704 8.95048L13.4646 12.5767C13.5033 12.6159 13.5656 12.6159 13.6064 12.5767L17.2539 8.95266C17.4086 8.79775 17.6621 8.79775 17.8167 8.95266V8.95048Z"
        fill="#5570FF"
      />
      <path
        d="M26.7653 14.9133L28.4129 8.2173C28.5118 7.84858 28.5955 7.45149 28.7072 6.82749C28.791 7.45149 28.8898 7.84858 28.9586 8.2173L30.3828 14.9133H33.3301L35.8155 4.98167H33.5535L32.1851 11.082C32.0455 11.6777 31.9617 12.1468 31.8629 12.8406C31.7512 12.1729 31.6395 11.6777 31.5127 11.0951L30.1852 4.97949H27.2229L25.7836 11.0951C25.6569 11.6777 25.5602 12.1446 25.4485 12.8406C25.3368 12.1446 25.238 11.6777 25.1134 11.0951L23.7729 4.97949H21.4121L23.8846 14.9111H26.7632L26.7653 14.9133Z"
        fill="#5570FF"
      />
      <path
        d="M37.7788 15.1685C38.8958 15.1685 39.609 14.6994 39.972 14.0186C39.9291 14.2325 39.9162 14.4441 39.9162 14.6579V14.9132H41.7872V10.8135C41.7872 8.85645 40.9065 7.71973 38.7562 7.71973C36.898 7.71973 35.6822 8.76918 35.5705 10.2157H37.6241C37.695 9.57645 38.1418 9.19464 38.8249 9.19464C39.4672 9.19464 39.8174 9.56336 39.8174 9.98882C39.8174 10.3008 39.6348 10.4863 39.0913 10.5561L38.1139 10.6695C36.6617 10.855 35.418 11.3939 35.418 12.9539C35.418 14.3721 36.578 15.1663 37.7788 15.1663V15.1685ZM38.4082 13.7066C37.8626 13.7066 37.4587 13.3946 37.4587 12.8557C37.4587 12.3168 37.9206 12.0746 38.6187 11.9481L39.0935 11.863C39.4995 11.7779 39.7229 11.7059 39.8625 11.5794V12.2317C39.8625 13.111 39.2331 13.7066 38.4103 13.7066H38.4082Z"
        fill="#5570FF"
      />
      <path
        d="M42.8535 4.98193V14.9136H44.8792V4.98193H42.8535Z"
        fill="#5570FF"
      />
      <path
        d="M46.1426 4.98193V14.9136H48.1683V4.98193H46.1426Z"
        fill="#5570FF"
      />
      <path
        d="M52.6808 15.1685C54.6077 15.1685 55.8514 14.0623 55.9911 12.6157H53.9525C53.8537 13.255 53.3231 13.6085 52.6808 13.6085C51.8151 13.6085 51.1857 12.8994 51.1728 11.9197H56.034V11.5226C56.034 9.22518 54.7903 7.71973 52.64 7.71973C50.4897 7.71973 49.1191 9.19464 49.1191 11.4223C49.1191 13.8332 50.6143 15.1685 52.6808 15.1685ZM51.1577 10.5714C51.2415 9.77718 51.8151 9.19464 52.6249 9.19464C53.4348 9.19464 53.9525 9.74882 53.9654 10.5714H51.1577Z"
        fill="#5570FF"
      />
      <path
        d="M60.4116 15.0551C60.8584 15.0551 61.2923 14.9831 61.4878 14.9264V13.3664C61.3331 13.3947 61.1527 13.41 60.998 13.41C60.2569 13.41 59.9927 12.9845 59.9927 12.2885V9.56345H61.6403V7.95982H59.9927V5.49219H57.982V7.95982H56.502V9.56345H57.982V12.5722C57.982 14.2173 58.7919 15.0551 60.4116 15.0551Z"
        fill="#5570FF"
      />
      <path
        d="M67.248 15.1687C70.0148 15.1687 71.6904 13.5237 71.8987 11.1542H69.7055C69.5379 12.4458 68.6443 13.3251 67.3318 13.3251C65.7808 13.3251 64.6917 12.0487 64.6917 9.89093C64.6917 7.73311 65.8238 6.5702 67.3747 6.5702C68.7023 6.5702 69.4563 7.39275 69.6088 8.59929H71.8579C71.6345 6.13165 69.8472 4.72656 67.3876 4.72656C64.58 4.72656 62.4297 6.65529 62.4297 9.89093C62.4297 13.1266 64.3158 15.1687 67.248 15.1687Z"
        fill="#5570FF"
      />
      <path
        d="M76.1989 15.1685C78.3084 15.1685 79.8035 13.8201 79.8035 11.4659C79.8035 9.11173 78.3084 7.71973 76.1989 7.71973C74.0894 7.71973 72.6094 9.15318 72.6094 11.4659C72.6094 13.7786 74.0894 15.1685 76.1989 15.1685ZM76.1989 13.5801C75.2623 13.5801 74.663 12.799 74.663 11.4659C74.663 10.1328 75.2924 9.33864 76.1989 9.33864C77.1054 9.33864 77.7499 10.1045 77.7499 11.4659C77.7499 12.8274 77.1355 13.5801 76.1989 13.5801Z"
        fill="#5570FF"
      />
      <path
        d="M82.6273 14.9132V10.8419C82.6273 9.97573 83.1579 9.33864 83.9119 9.33864C84.625 9.33864 85.0439 9.89282 85.0439 10.8135V14.9132H87.0696V10.5146C87.0696 8.84118 86.2039 7.71973 84.653 7.71973C83.5768 7.71973 82.9216 8.24554 82.5714 8.86954C82.6122 8.61427 82.6273 8.41573 82.6273 8.23027V7.95973H80.6016V14.911H82.6273V14.9132Z"
        fill="#5570FF"
      />
      <path
        d="M90.2132 14.9132V10.8419C90.2132 9.97573 90.7438 9.33864 91.4978 9.33864C92.211 9.33864 92.6299 9.89282 92.6299 10.8135V14.9132H94.6556V10.5146C94.6556 8.84118 93.7899 7.71973 92.2389 7.71973C91.1627 7.71973 90.5075 8.24554 90.1574 8.86954C90.1982 8.61427 90.2132 8.41573 90.2132 8.23027V7.95973H88.1875V14.911H90.2132V14.9132Z"
        fill="#5570FF"
      />
      <path
        d="M99.0148 15.1685C100.942 15.1685 102.185 14.0623 102.325 12.6157H100.286C100.188 13.255 99.657 13.6085 99.0148 13.6085C98.149 13.6085 97.5196 12.8994 97.5068 11.9197H102.368V11.5226C102.368 9.22518 101.124 7.71973 98.9739 7.71973C96.8236 7.71973 95.4531 9.19464 95.4531 11.4223C95.4531 13.8332 96.9482 15.1685 99.0148 15.1685ZM97.4917 10.5714C97.5755 9.77718 98.1469 9.19464 98.9589 9.19464C99.7709 9.19464 100.286 9.74882 100.299 10.5714H97.4917Z"
        fill="#5570FF"
      />
      <path
        d="M106.716 15.1685C108.838 15.1685 109.998 13.9903 110.221 12.1597H108.196C108.127 12.9255 107.693 13.5779 106.784 13.5779C105.876 13.5779 105.233 12.7117 105.233 11.4354C105.233 10.0455 105.959 9.33645 106.825 9.33645C107.691 9.33645 108.097 9.96045 108.153 10.6848H110.178C110.026 8.99609 108.894 7.71973 106.812 7.71973C104.731 7.71973 103.18 9.08118 103.18 11.4375C103.18 13.7939 104.535 15.1685 106.713 15.1685H106.716Z"
        fill="#5570FF"
      />
      <path
        d="M114.603 15.0551C115.05 15.0551 115.484 14.9831 115.679 14.9264V13.3664C115.525 13.3947 115.344 13.41 115.189 13.41C114.448 13.41 114.184 12.9845 114.184 12.2885V9.56345H115.832V7.95982H114.184V5.49219H112.173V7.95982H110.693V9.56345H112.173V12.5722C112.173 14.2173 112.983 15.0551 114.603 15.0551Z"
        fill="#5570FF"
      />
    </svg>
  );
}
