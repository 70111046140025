import axios, { AxiosInstance } from 'axios';
import { API_URL } from 'configs/api';
import {
  CreateMultisend,
  GasEstimations,
  Multisend,
  MultisenderState,
} from 'types/multisender';
import { getAccessToken } from 'utilities/user';

export class MultisenderService {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
    });
  }

  async getMultisendsHistory(): Promise<Multisend['multisend'][]> {
    const url = '/multisends';
    const { data } = await this._api.get<Multisend['multisend'][]>(url, {
      headers: {
        Authorization: getAccessToken(true),
        Accept: 'application/json',
      },
    });

    return data.filter(
      (el) =>
        el.state === MultisenderState.Confirmed ||
        el.state === MultisenderState.Failed ||
        el.state === MultisenderState.Processing ||
        el.state === MultisenderState.InProgress
    );
  }

  async getMultisendsTemplates(): Promise<Multisend['multisend'][]> {
    const url = '/multisends';
    const { data } = await this._api.get<Multisend['multisend'][]>(url, {
      headers: {
        Authorization: getAccessToken(true),
      },
    });

    return data.filter((el) => el.state === MultisenderState.Template);
  }

  async downloadMultisendAsCSV(id: string, name?: string) {
    const url = `/multisends/${id}`;
    const { data } = await this._api.get<Blob>(url, {
      headers: {
        Authorization: getAccessToken(),
        Accept: 'text/csv',
      },
    });

    const urlObject = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = urlObject;
    link.setAttribute('download', `${name ?? Date.now()}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  async createMultisend({
    name,
    description,
    currency_id,
    chain,
    state,
    source,
    targets,
    tx_id,
  }: CreateMultisend) {
    try {
      const url = '/multisends';
      const { data } = await this._api.post<Multisend>(
        url,
        {
          name,
          description,
          currency_id,
          chain,
          state,
          source,
          targets,
          tx_id,
        },
        {
          headers: {
            Authorization: getAccessToken(true),
          },
        }
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async editMultisend(
    {
      name,
      description,
      currency_id,
      chain,
      state,
      source,
      targets,
      tx_id,
    }: CreateMultisend,
    id: Multisend['multisend']['id']
  ) {
    try {
      const url = `/multisends/${id}`;
      const { data } = await this._api.put(
        url,
        {
          name,
          description,
          currency_id,
          chain,
          state,
          source,
          targets,
          tx_id,
        },
        {
          headers: {
            Authorization: getAccessToken(true),
          },
        }
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async confirmMultisend(multisend: Multisend['multisend'], tx_id: string) {
    try {
      await this.editMultisend(
        {
          name: multisend.name,
          description: multisend.description ?? '',
          currency_id: multisend.currency.id,
          chain: multisend.chain,
          source: multisend.source.address,
          targets: multisend.targets.map((target) => ({
            address: target.address,
            amount: +target.amount,
          })),
          state: MultisenderState.Confirmed,
          tx_id: tx_id,
        },
        multisend.id
      );
    } catch (error) {
      console.error(error);
    }
  }

  async deleteMultisend(id: string) {
    const url = `/multisends/${id}`;
    await this._api.delete(url, {
      headers: {
        Authorization: getAccessToken(true),
      },
    });
  }

  async getGasEstimations() {
    const url = `/multisends/gas_estimations`;
    const { data } = await this._api.get<GasEstimations>(url, {
      headers: {
        Authorization: getAccessToken(true),
      },
    });

    return data;
  }
}

export const multisenderService = new MultisenderService();
