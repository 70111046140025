import { SVGAttributes } from "react";

export function Error(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      {...props}
    >
      <rect x="26" y="26" width="100" height="100" rx="14" fill="#EDEFF3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76 51.5C62.469 51.5 51.5 62.469 51.5 76C51.5 89.531 62.469 100.5 76 100.5C89.531 100.5 100.5 89.531 100.5 76C100.5 62.469 89.531 51.5 76 51.5ZM46.5 76C46.5 59.7076 59.7076 46.5 76 46.5C92.2924 46.5 105.5 59.7076 105.5 76C105.5 92.2924 92.2924 105.5 76 105.5C59.7076 105.5 46.5 92.2924 46.5 76ZM65.2322 65.2322C66.2085 64.2559 67.7915 64.2559 68.7678 65.2322L76 72.4645L83.2318 65.2326C84.2082 64.2563 85.7911 64.2563 86.7674 65.2326C87.7437 66.2089 87.7437 67.7918 86.7674 68.7681L79.5355 76L86.7674 83.2318C87.7437 84.2082 87.7437 85.7911 86.7674 86.7674C85.7911 87.7437 84.2082 87.7437 83.2318 86.7674L76 79.5355L68.7678 86.7677C67.7915 87.7441 66.2085 87.7441 65.2322 86.7677C64.2559 85.7914 64.2559 84.2085 65.2322 83.2322L72.4645 76L65.2322 68.7678C64.2559 67.7915 64.2559 66.2085 65.2322 65.2322Z"
        fill="#667085"
      />
    </svg>
  );
}
