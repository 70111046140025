import {
  CrownTwoTone,
  SettingOutlined,
  StarTwoTone,
  ThunderboltTwoTone,
} from '@ant-design/icons';
import { Button, Card, Flex, Tag, Typography } from 'antd';
import { SubscriptionDialog } from 'components/SubscriptionDialog';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useUserStore } from 'store/user';
import { useWalletsStore } from 'store/wallets';
import { UserPlans } from 'types/user';
import styles from './styles.module.css';

export function CurrentPlan() {
  const user = useUserStore((s) => s.user);
  const amountOfWallets = useWalletsStore((s) => s.wallets?.length ?? 0);
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] =
    useState(false);

  const isPlanValid = useMemo(() => {
    const today = dayjs();
    const validUntil = dayjs(user?.current_plan_valid_until);

    return validUntil.isAfter(today);
  }, [user?.current_plan_valid_until]);

  const planValidFor = useMemo(() => {
    if (!user?.current_plan_valid_until) return null;

    const validUntil = dayjs(user.current_plan_valid_until);
    const today = dayjs();

    if (validUntil.isBefore(today)) return 0;

    return Math.ceil(validUntil.diff(today, 'days', true));
  }, [user?.current_plan_valid_until]);

  const billingDate = useMemo(() => {
    if (!user?.current_plan_valid_until) return null;

    const validUntil = dayjs(user.current_plan_valid_until);
    const today = dayjs();

    if (validUntil.isBefore(today)) return null;

    return dayjs(user.current_plan_valid_until).format('DD MMMM YYYY');
  }, [user?.current_plan_valid_until]);

  const handleSubscribe = async () => {
    setIsSubscriptionDialogOpen(true);
  };

  return (
    <Flex vertical gap={20}>
      <Typography.Title level={4} style={{ margin: 0 }}>
        Current plan
      </Typography.Title>
      <Card classNames={{ body: styles.planCardBody }}>
        <Flex vertical gap={20}>
          <Flex vertical gap={6}>
            <Flex align="center" gap={20}>
              <Typography.Title level={3} className={styles.planName}>
                {user?.current_plan === UserPlans.Free && (
                  <>
                    <StarTwoTone />
                    Basic
                  </>
                )}
                {user?.current_plan === UserPlans.Pro && (
                  <>
                    <CrownTwoTone />
                    Pro
                  </>
                )}
                {user?.current_plan === UserPlans.Enterprise && (
                  <>
                    <ThunderboltTwoTone />
                    Enterprise
                  </>
                )}
              </Typography.Title>
              {user?.current_plan === UserPlans.Free && (
                <div className={styles.planTag}>
                  {amountOfWallets} wallet{amountOfWallets > 1 && 's'}
                </div>
              )}
              {user?.current_plan === UserPlans.Pro && (
                <div className={styles.planTag}>
                  {amountOfWallets}/5 wallets used
                </div>
              )}
              {isPlanValid && user?.current_plan === UserPlans.Pro && (
                <Tag color={(planValidFor ?? 0) <= 5 ? 'magenta' : undefined}>
                  Left {planValidFor} day{(planValidFor ?? 0) > 1 && 's'}
                </Tag>
              )}
              {isPlanValid && user?.current_plan === UserPlans.Enterprise && (
                <Tag color={(planValidFor ?? 0) <= 5 ? 'magenta' : undefined}>
                  Left {planValidFor} day{(planValidFor ?? 0) > 1 && 's'}
                </Tag>
              )}
              {!isPlanValid && <Tag color="magenta">Finished</Tag>}
              <Button
                size="small"
                icon={<SettingOutlined />}
                style={{ marginLeft: 'auto' }}
                onClick={() => setIsSubscriptionDialogOpen(true)}
              >
                Manage
              </Button>
            </Flex>
          </Flex>
          <Flex vertical gap={12}>
            {!isPlanValid &&
              (user?.current_plan === UserPlans.Pro ||
                user?.current_plan === UserPlans.Enterprise) && (
                <Typography.Text style={{ color: '#D92D20' }}>
                  Services will be locked for all wallets except your first
                  registered wallet until subscription payment is complete.
                </Typography.Text>
              )}
            {(user?.current_plan === UserPlans.Pro ||
              user?.current_plan === UserPlans.Enterprise) && (
              <Flex vertical gap={8} align="flex-start">
                <Typography.Text>
                  Next billing date:{' '}
                  <strong>
                    in {planValidFor} days, {billingDate}
                  </strong>
                </Typography.Text>
              </Flex>
            )}
            <SubscriptionDialog
              isOpen={isSubscriptionDialogOpen}
              onOpenChange={setIsSubscriptionDialogOpen}
            />
          </Flex>
          <Flex align="center" gap={8}>
            {(user?.current_plan === UserPlans.Free ||
              (isPlanValid &&
                user?.current_plan === UserPlans.Pro &&
                amountOfWallets >= 5 &&
                (planValidFor ?? 0) > 5)) && (
              <>
                <Button type="primary" size="large" onClick={handleSubscribe}>
                  Upgrade Subscription
                </Button>
                <Typography.Text>to add more wallets</Typography.Text>
              </>
            )}
            {isPlanValid &&
              (user?.current_plan === UserPlans.Pro ||
                user?.current_plan === UserPlans.Enterprise) &&
              (planValidFor ?? 0) <= 5 && (
                <Button type="primary" size="large" onClick={handleSubscribe}>
                  Continue subscription
                </Button>
              )}
            {!isPlanValid &&
              (user?.current_plan === UserPlans.Pro ||
                user?.current_plan === UserPlans.Enterprise) && (
                <Button type="primary" size="large" onClick={handleSubscribe}>
                  Renew subscription
                </Button>
              )}
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
}
