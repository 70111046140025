import { SVGAttributes } from 'react';

export function LetterIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      {...props}
    >
      <rect x="26" y="26" width="100" height="100" rx="8" fill="#EDEFF3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.8737 56.083C61.9103 56.083 61.947 56.083 61.9838 56.083L90.1274 56.083C91.3864 56.0829 92.5063 56.0829 93.4356 56.1588C94.4271 56.2399 95.4623 56.4221 96.4725 56.9368C97.9452 57.6872 99.1451 58.885 99.8967 60.3601C100.412 61.3708 100.594 62.4066 100.674 63.398C100.75 64.3257 100.75 65.4437 100.75 66.7V85.299C100.75 86.5554 100.75 87.6735 100.674 88.6013C100.594 89.5927 100.412 90.6287 99.8967 91.6394C99.1449 93.1149 97.9449 94.3123 96.4725 95.0625C95.4623 95.5772 94.4271 95.7594 93.4356 95.8405C92.5062 95.9165 91.3859 95.9164 90.1265 95.9163H61.8746C60.615 95.9164 59.4944 95.9165 58.5645 95.8405C57.573 95.7595 56.537 95.5774 55.5265 95.0625C54.0522 94.3113 52.8546 93.1129 52.1038 91.6394C51.589 90.629 51.4068 89.5933 51.3258 88.6018C51.2499 87.6723 51.2499 86.5522 51.25 85.2931L51.25 66.8162C51.25 66.7794 51.25 66.7426 51.25 66.706C51.2499 65.447 51.2499 64.3269 51.3258 63.3975C51.4068 62.406 51.589 61.3705 52.1038 60.3601C52.8544 58.887 54.0518 57.6882 55.5265 56.9368C56.5371 56.4219 57.5731 56.2398 58.5646 56.1588C59.4942 56.0829 60.6145 56.0829 61.8737 56.083ZM62.5586 62.083L76.5009 72.2493L89.6686 62.083H62.5586ZM94.7483 65.7413L78.3857 78.3743C77.3299 79.1895 75.8627 79.2096 74.7849 78.4237L57.2522 65.6395C57.2502 65.9856 57.25 66.3746 57.25 66.8162V85.1829C57.25 86.5859 57.2523 87.4577 57.3059 88.1133C57.3565 88.7322 57.4374 88.8911 57.4497 88.9153C57.6257 89.2607 57.906 89.5409 58.2505 89.7164C58.2745 89.7287 58.4337 89.8098 59.0531 89.8604C59.7088 89.914 60.5809 89.9163 61.9838 89.9163H90.0171C91.4201 89.9163 92.2916 89.914 92.9468 89.8604C93.5652 89.8099 93.724 89.729 93.7483 89.7165C94.0949 89.54 94.3757 89.2589 94.5507 88.9154C94.5629 88.8915 94.6439 88.7326 94.6943 88.1137C94.7478 87.458 94.75 86.5862 94.75 85.1829V66.8162C94.75 66.4174 94.7498 66.0615 94.7483 65.7413Z"
        fill="#667085"
      />
    </svg>
  );
}
