import { Flex } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { useEffect, useRef, useState } from 'react';

interface CodeblockProps {
  children: React.ReactNode;
  readonly?: boolean;
}

export function Codeblock({ children, readonly }: CodeblockProps) {
  const textareaRef = useRef<TextAreaRef>(null);
  const [lines, setLines] = useState(1);

  const calcLines = () => {
    const height =
      textareaRef.current?.resizableTextArea?.textArea.offsetHeight;

    if (!height) return 1;

    const lines = Math.ceil((height - 5 * 2) / (14 * 1.5714285714285714));
    return lines + 1;
  };

  const handleChange = () => {
    setLines(calcLines());
  };

  useEffect(() => {
    setTimeout(() => {
      setLines(calcLines());
    }, 0);
  }, []);

  return (
    <Flex gap={10}>
      <TextArea
        cols={1}
        style={{
          flexGrow: 0,
          minWidth: 34,
          width: 'auto',
          resize: 'none',
          overflow: 'hidden',
          textAlign: 'right',
        }}
        readOnly
        disabled
        value={Array(lines)
          .fill(0)
          .map((_, i) => i + 1)
          .join('\n')}
      />
      <TextArea
        style={{ flexGrow: 1 }}
        autoSize={{ minRows: 3 }}
        ref={textareaRef}
        defaultValue={children?.toString()}
        onChange={handleChange}
        readOnly={readonly}
      />
    </Flex>
  );
}
