import { SVGAttributes } from "react";

export function ExternalIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3333 2H21C21.5523 2 22 2.44772 22 3V9.66667H20V5.41421L13.0667 12.3475L11.6525 10.9333L18.5858 4H14.3333V2ZM7.58743 3.13333L10.6669 3.13333V5.13333H7.62689C6.97567 5.13333 6.54726 5.13411 6.21932 5.1609C5.90299 5.18673 5.76927 5.2317 5.69136 5.27139C5.45328 5.3927 5.25963 5.58637 5.13806 5.82497C5.09833 5.90295 5.05339 6.03658 5.02756 6.35268C5.00078 6.68046 5 7.10872 5 7.75994V16.3733C5 17.0245 5.00078 17.4528 5.02756 17.7806C5.05339 18.0968 5.09834 18.2305 5.13806 18.3084C5.25958 18.5469 5.45317 18.7405 5.69136 18.8619C5.76928 18.9016 5.90302 18.9466 6.21934 18.9724C6.54727 18.9992 6.97569 19 7.62689 19H16.2402C16.8914 19 17.3196 18.9992 17.6473 18.9724C17.9632 18.9466 18.0968 18.9017 18.1748 18.8619C18.4137 18.7402 18.6077 18.5463 18.7289 18.3084C18.7685 18.2306 18.8134 18.097 18.8392 17.7808C18.866 17.4529 18.8667 17.0247 18.8667 16.3733V13.3333H20.8667V16.4158C20.8667 17.0155 20.8667 17.5251 20.8326 17.9434C20.7967 18.3831 20.7183 18.8094 20.5109 19.2164C20.1974 19.8316 19.697 20.331 19.0828 20.6439C18.676 20.8512 18.25 20.9298 17.8102 20.9658C17.3912 21 16.8805 21 16.2793 21H7.58786C6.98656 21 6.47569 21 6.05648 20.9658C5.61662 20.9298 5.19032 20.8513 4.78338 20.6439C4.16857 20.3307 3.66916 19.8309 3.35605 19.2164C3.14871 18.8095 3.07014 18.3833 3.0342 17.9435C2.99997 17.5245 2.99998 17.0138 3 16.4128V7.72046C2.99998 7.11939 2.99997 6.60878 3.0342 6.18981C3.07014 5.75 3.14873 5.32388 3.35605 4.91699C3.6691 4.30259 4.16846 3.8027 4.78338 3.48938C5.19034 3.28202 5.61665 3.20346 6.0565 3.16753C6.4756 3.1333 6.98633 3.13332 7.58743 3.13333Z"
        fill="currentColor"
      />
    </svg>
  );
}
