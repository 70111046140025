import { Col, Flex, Row, Tabs, Typography } from 'antd';
import { useState } from 'react';
import { BillingHistory } from './components/BillingHistory';
import { CurrentPlan } from './components/CurrentPlan';
import { DeleteAccount } from './components/DeleteAccount';
import { EmailAndPasswordInfo } from './components/EmailAndPasswordInfo';
import { ReferralInfo } from './components/ReferralInfo';
import { WalletsList } from './components/WalletsList';
import styles from './styles.module.css';

enum ProfileTabs {
  General = 'General',
  Billing = 'Billing',
  Referrals = 'Referrals',
}

export function ProfileView() {
  const [tab, setTab] = useState<ProfileTabs>(ProfileTabs.General);

  return (
    <Row style={{ paddingBottom: 132 }}>
      <Col span={4}>
        <Flex justify="flex-end">
          <Tabs
            tabPosition="left"
            onChange={(tab) => setTab(tab as ProfileTabs)}
            items={Object.values(ProfileTabs).map((item) => ({
              label: item,
              key: item,
              className: styles.tabContent,
            }))}
            className={styles.tabs}
          />
        </Flex>
      </Col>
      <Col span={2} />
      <Col span={12}>
        {tab === ProfileTabs.General && (
          <Flex vertical gap={32}>
            <Typography.Title level={2} style={{ margin: 0 }}>
              General
            </Typography.Title>
            <EmailAndPasswordInfo />
            <WalletsList />
            <DeleteAccount />
          </Flex>
        )}
        {tab === ProfileTabs.Billing && (
          <Flex vertical gap={32}>
            <Typography.Title level={2} style={{ margin: 0 }}>
              Billing
            </Typography.Title>
            <CurrentPlan />
            <BillingHistory />
          </Flex>
        )}
        {tab === ProfileTabs.Referrals && (
          <Flex vertical gap={32}>
            <Typography.Title level={2} style={{ margin: 0 }}>
              Referrals
            </Typography.Title>
            <ReferralInfo />
          </Flex>
        )}
      </Col>
    </Row>
  );
}
