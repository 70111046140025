import { Button, Flex, Form } from 'antd';
import { AxiosError } from 'axios';
import { useOnboardingStore } from 'screens/Onboarding/store';
import { walletService } from 'services/walletService';
import { getNetworkCodeByAddress } from 'utilities/wallets';
import { AddWalletFormType } from '../AddWalletForm';
import { WalletListItem } from './WalletListItem';
import styles from './styles.module.css';
import { useUserStore } from 'store/user';

export function WalletsList() {
  const form = Form.useFormInstance<AddWalletFormType>();
  const address = Form.useWatch('address', form);
  const [wallets, setError, setScanningStatus] = useOnboardingStore((state) => [
    state.wallets,
    state.setError,
    state.setScanningStatus,
  ]);
  const user = useUserStore((s) => s.user);

  const handleProceed = async () => {
    if (wallets.length <= 0) {
      const values = form.getFieldsValue();

      if (values.name !== undefined || values.address !== undefined) {
        try {
          await form.validateFields();
          const network = getNetworkCodeByAddress(values.address);

          if (!network) return;

          await walletService.createWallet(
            values.name,
            values.address,
            network
          );
          form.resetFields();
          setScanningStatus('inProgress');
        } catch (error) {
          if (error instanceof AxiosError) {
            form.setFields([
              {
                name: 'address',
                errors: [
                  error.response?.data.message || 'Something went wrong',
                ],
              },
            ]);
          }
        }
      } else {
        setError('Add at least one wallet address to proceed');
      }

      return;
    }

    setScanningStatus('inProgress');
  };

  return (
    <Flex vertical className={styles.container}>
      <div className={styles.walletListContainer}>
        {wallets.length > 0 && (
          <Flex vertical gap={8} className={styles.walletList}>
            {wallets.map((wallet, i) => (
              <WalletListItem wallet={wallet} key={i} />
            ))}
          </Flex>
        )}
        <Button
          type="primary"
          htmlType="button"
          size="large"
          className={styles.walletListSubmit}
          onClick={handleProceed}
          block
        >
          {!user?.can_add_new_wallet && wallets.length === 1 && !!address
            ? 'Proceed with One wallet'
            : 'Proceed & Start using FinFlo'}
        </Button>
      </div>
    </Flex>
  );
}
