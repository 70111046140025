import { Flex, Tag } from 'antd';
import { ReactNode, useEffect, useRef, useState } from 'react';

interface TagsGroupEllipsisProps {
  tags: ReactNode[];
}

export function TagsGroupEllipsis({ tags }: TagsGroupEllipsisProps) {
  const targetRef = useRef<HTMLDivElement>(null);
  const [shouldTruncate, setShouldTruncate] = useState(false);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (!targetRef.current) {
        return;
      }

      const { offsetWidth, scrollWidth } = targetRef.current;
      setShouldTruncate(offsetWidth < scrollWidth);
    });

    observer.observe(targetRef.current as Element);

    return () => {
      observer.disconnect();
    };
  }, [tags]);

  return (
    <>
      <Flex
        style={{ overflow: 'hidden', height: 0, visibility: 'hidden' }}
        align="center"
        ref={targetRef}
      >
        {tags}
      </Flex>
      <Flex style={{ overflow: 'hidden' }} align="center">
        {shouldTruncate ? tags?.[0] : tags}
        {shouldTruncate && tags.length - 1 > 0 && <Tag>+{tags.length - 1}</Tag>}
      </Flex>
    </>
  );
}
