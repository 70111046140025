import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { Period } from 'types';

dayjs.extend(utc);
dayjs.extend(weekOfYear);

export const WEEKS_IN_YEAR = 52;

export const formatDateByPeriod = (date: string, period: Period) => {
  const tzOffset = new Date().getTimezoneOffset();

  const dateObj = dayjs(date).utcOffset(tzOffset, true);
  const year = dateObj.year();
  const month = dateObj.format('MMM');

  switch (period) {
    case Period.Day:
      return `${dateObj.date()} ${month} ${year}`;
    case Period.Week:
      return `Week ${dateObj.week()} (${year})`;
    case Period.Month:
      return `${month} ${year.toString().slice(2, 4)}`;
    case Period.Quarter:
      return `Q${Math.floor(dateObj.month() / 3) + 1} (${year})`;
    default:
      return date;
  }
};

export const formatTime = (date: Date) => {
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });
};

export const formatDate = (date: string) => {
  const tzOffset = new Date().getTimezoneOffset();

  const dateObj = dayjs(date).utcOffset(tzOffset, true);

  return dateObj.format('DD MMM YYYY');
};

export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
