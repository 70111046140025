import { Button, Flex, Form, Input, Modal, Select, Typography } from 'antd';
import { AxiosError } from 'axios';
import WAValidator from 'multicoin-address-validator';
import { useState } from 'react';
import { addressBookService } from 'services/addressBook';
import useSWR, { useSWRConfig } from 'swr';
import {
  Counterparty,
  CounterpartyLabels,
  CounterpartyWallet,
} from 'types/counterparty';
import { WalletsList } from './WalletsList';
import styles from './styles.module.css';

interface CounterpartyDialogProps {
  counterparty: Counterparty;
}

export interface CounterpartyDialogForm {
  name: Counterparty['name'];
  label: CounterpartyLabels;
  note: Counterparty['note'];
  wallets: CounterpartyWallet[];
}

export function CounterpartyDialog({ counterparty }: CounterpartyDialogProps) {
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { data: wallets } = useSWR(
    '/counterparties_wallets',
    () => addressBookService.getWallets(),
    { revalidateOnFocus: false }
  );
  const [form] = Form.useForm<CounterpartyDialogForm>();
  const list = Form.useWatch('wallets', { form });
  const { mutate } = useSWRConfig();

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);

    if (!open) {
      form.resetFields();
    }
  };

  const handleAddNew = () => {
    form.setFieldValue('wallets', [
      ...form.getFieldValue('wallets'),
      {
        id: crypto.randomUUID(),
        name: '',
        wallet: {
          address: '',
          network: '',
          last_retrieved_at: null,
          usd_balance: null,
        },
      },
    ]);
  };

  const handleSubmit = async (values: CounterpartyDialogForm) => {
    setIsSubmitting(true);
    try {
      await addressBookService.updateCounterparty(
        counterparty.id,
        values.name,
        values.note,
        values.label
      );

      for (let i = 0; i < list.length; i++) {
        const wallet = list[i];

        if (wallets?.find((w) => w.id === wallet.id) === undefined) {
          await addressBookService.createWallet(
            wallet.name,
            wallet.wallet.network,
            wallet.wallet.address,
            counterparty.id
          );
        } else {
          await addressBookService.updateWallet(
            wallet.id,
            wallet.name,
            counterparty.id,
            wallet.wallet.address,
            wallet.wallet.network
          );
        }
      }

      await mutate('/counterparties?with_turnover');
      await mutate('/counterparties_wallets?with_turnover');
      handleOpenChange(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        setError(error?.response?.data.message || error.message);
      } else if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleValidation = () => {
    const wallets: CounterpartyDialogForm['wallets'] =
      form.getFieldValue('wallets');

    if (wallets.some((wallet) => !wallet.wallet.address)) {
      setError('Check and fix wallet address(es) to proceed.');
      return;
    }

    for (let i = 0; i < wallets.length; i++) {
      const wallet = wallets[i];
      const network = wallet?.wallet.network;

      const res = WAValidator.validate(wallet.wallet.address, network);
      if (wallet.wallet.address.length === 0) return setError(null);
      if (!res) {
        setError('Check and fix wallet address(es) to proceed.');
        return;
      } else {
        return setError(null);
      }
    }

    return setError(null);
  };

  return (
    <>
      <Button
        type="link"
        key="editable"
        onClick={() => {
          handleOpenChange(true);
        }}
        style={{ padding: 0, height: 'auto' }}
      >
        Edit
      </Button>
      <Modal
        open={isOpen}
        afterOpenChange={handleOpenChange}
        centered
        onOk={() => form.submit()}
        onCancel={() => handleOpenChange(false)}
        title="Counterparty card"
        okText="Save"
        okButtonProps={{
          loading: isSubmitting,
        }}
        style={{
          maxWidth: 608,
        }}
        classNames={{
          footer: styles.footer,
        }}
        footer={[
          error && (
            <Typography.Text
              type="danger"
              key="error"
              style={{ marginRight: 'auto' }}
            >
              {error}
            </Typography.Text>
          ),
          <Button
            key="cancel"
            onClick={() => setIsOpen(false)}
            style={{
              marginLeft: 'auto',
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              handleValidation();
              form.submit();
            }}
            loading={isSubmitting}
          >
            Save
          </Button>,
        ]}
      >
        <Form
          form={form}
          initialValues={{
            name: counterparty.name,
            label: counterparty.label,
            note: counterparty.note,
            wallets: wallets?.filter(
              (wallet) => wallet.counterparty_id === counterparty.id
            ),
          }}
          validateTrigger="onSubmit"
          requiredMark={false}
          onFinish={handleSubmit}
          labelCol={{ span: 24, className: styles.label }}
        >
          <Flex vertical gap={32}>
            <Flex gap={32}>
              <Flex gap={8} vertical>
                <Form.Item
                  label="Name"
                  name="name"
                  style={{
                    margin: 0,
                  }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Label"
                  name="label"
                  style={{
                    margin: 0,
                  }}
                >
                  <Select
                    showSearch
                    options={[
                      {
                        label: CounterpartyLabels.Entity,
                        value: CounterpartyLabels.Entity,
                      },
                      {
                        label: CounterpartyLabels.Individual,
                        value: CounterpartyLabels.Individual,
                      },
                    ]}
                  />
                </Form.Item>
              </Flex>
              <Form.Item
                label="Notes"
                name="note"
                style={{
                  margin: 0,
                }}
              >
                <Input.TextArea placeholder="What is important?" rows={4} />
              </Form.Item>
            </Flex>
            <Flex gap={12} vertical>
              <Flex justify="space-between">
                <Typography.Text>Wallets</Typography.Text>
                <Button
                  type="link"
                  style={{ height: 'auto', padding: 0 }}
                  onClick={handleAddNew}
                >
                  + Add new wallet
                </Button>
              </Flex>
              <Form.Item<CounterpartyDialogForm> name="wallets">
                <WalletsList counterpartyWallets={wallets} />
              </Form.Item>
            </Flex>
          </Flex>
        </Form>
      </Modal>
    </>
  );
}
