import { DownloadOutlined } from '@ant-design/icons';
import { Button, Flex, Segmented, Select, Spin, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { NoWalletsPlaceholder } from 'components/NoWalletsPlaceholder';
import { useMemo, useState } from 'react';
import { walletService } from 'services/walletService';
import { useWalletsStore } from 'store/wallets';
import useSWR from 'swr';
import { Period } from 'types';
import { RecordItem } from 'types/wallet';
import { formatDateByPeriod } from 'utilities/date';
import { formatUSDValue } from 'utilities/number';
import { toCapital } from 'utilities/string';
import { formatAddress } from 'utilities/wallets';
import styles from '../../ProfitAndLoss.module.css';

interface Item {
  key: string;
  category: string;
  [key: string]: unknown;
}

export function BreakdownTable() {
  const [wallets, isWalletsLoading] = useWalletsStore((s) => [
    s.wallets,
    s.isLoading,
  ]);
  const [selectedWallets, setSelectedWallets] = useState<string[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<Period>(Period.Month);
  const [isCSVLoading, setIsCSVLoading] = useState(false);

  const walletsFilter = useMemo(() => {
    if (selectedWallets.length === 0) return wallets.map((el) => el.id || '');

    return selectedWallets;
  }, [wallets, selectedWallets]);

  const { data, isLoading } = useSWR(
    wallets.length > 0
      ? `/user_wallets/stats/table/${walletsFilter.join(',')}/${selectedPeriod}`
      : null,
    () => walletService.getStatsBreakdown(walletsFilter, selectedPeriod),
    {
      revalidateOnFocus: false,
    }
  );

  const columns = useMemo<ColumnsType<Item>>(() => {
    if (!data)
      return [
        {
          title: 'Profit and loss item',
          width: 232,
          fixed: 'left',
          dataIndex: 'category',
          key: 'category',
          sorter: (a, b, order) => {
            if (a.category === undefined || a.category === null) {
              if (order === 'descend') return -1;
              return 1;
            }
            if (b.category === undefined || b.category === null) {
              if (order === 'descend') return 1;
              return -1;
            }
            return a.category.localeCompare(b.category);
          },
          render: (value: string | null) => toCapital(value ?? '...rest'),
        },
      ];

    return [
      {
        title: 'Profit and loss item',
        width: 332,
        fixed: 'left',
        dataIndex: 'category',
        key: 'category',
        sorter: (a, b, order) => {
          if (a.category === undefined || a.category === null) {
            if (order === 'descend') return -1;
            return 1;
          }
          if (b.category === undefined || b.category === null) {
            if (order === 'descend') return 1;
            return -1;
          }
          return a.category.localeCompare(b.category);
        },
        render: (value: string | null) => toCapital(value ?? '...rest'),
      },
      ...Object.keys(data).map((date) => ({
        title: (
          <span className={styles.breakdownValue}>
            {formatDateByPeriod(date, selectedPeriod)}
          </span>
        ),
        key: date,
        dataIndex: date,

        render: (record: number) => (
          <span
            className={styles.breakdownValue}
            dangerouslySetInnerHTML={{
              __html: formatUSDValue(record ?? 0),
            }}
          />
        ),
      })),
    ];
  }, [data, selectedPeriod]);

  const formattedData = useMemo(() => {
    if (!data) return [];

    let dates = Object.keys(data);
    let categories: string[] = Object.keys(data[dates[0]]);

    let items: Item[] = [];
    let formattedData: Record<string, Record<string, RecordItem[]>> = {};

    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];

      let tempData: Record<string, number> = {};

      for (let j = 0; j < dates.length; j++) {
        const date = dates[j];

        formattedData[category] = {
          ...formattedData[category],
          [date]: data[date][category],
        };

        let sum =
          data[date][category]?.reduce(
            (acc, item) => acc + Number(item.total_amount),
            0
          ) ?? 0;

        tempData[date] = sum;
      }

      const temp = Object.entries<RecordItem[]>(formattedData[category]);
      let children: any[] = [];

      for (let j = 0; j < temp.length; j++) {
        const [date, value] = temp[j];

        for (let k = 0; k < value.length; k++) {
          const tag = value[k];

          const index = children.findIndex(
            (el) => el.key === `${i}-${tag.tag_id}`
          );

          if (index === -1) {
            children.push({
              key: `${i}-${tag.tag_id}`,
              category: tag.tag_name,
              [date]: tag.total_amount,
            });
          } else {
            children[index] = {
              ...children[index],
              [date]: tag.total_amount,
            };
          }
        }
      }

      items.push({
        key: `${i}`,
        category,
        ...tempData,
        children: children.sort((a, b) => {
          if (b.category === null || b.category === undefined) return -1;
          return 0;
        }),
      });
    }

    return items;
  }, [data]);

  const handleSelectWallets = (value: string[]) => {
    if (value.includes('all')) {
      setSelectedWallets([]);
      return;
    }

    setSelectedWallets(value);
  };

  const handleDownloadCSV = async () => {
    try {
      setIsCSVLoading(true);
      await walletService.downloadStatsBreakdownAsCSV(
        walletsFilter,
        selectedPeriod
      );
    } catch (error) {
      console.warn(error);
    } finally {
      setIsCSVLoading(false);
    }
  };

  const isDataLoading = isLoading || isWalletsLoading;
  const hasWallets = wallets.length > 0 && !isWalletsLoading;
  const hasData = formattedData.length > 0 && !!data;

  return (
    <>
      <Flex
        gap={32}
        style={{ marginBottom: 135 }}
        vertical
        className={styles.block}
      >
        <Flex justify="space-between">
          <Typography.Title level={2} className={styles.blockHeading}>
            Breakdown
          </Typography.Title>
          <Flex gap={20} align="center">
            <Flex align="center" gap={8}>
              <Typography.Text>Wallet</Typography.Text>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '200px' }}
                placeholder="All"
                disabled={wallets.length <= 0}
                value={selectedWallets}
                onChange={handleSelectWallets}
                filterOption={(input, option) =>
                  (option?.label ?? '').includes(input)
                }
                filterSort={() => 0}
                options={[
                  {
                    label: 'All',
                    value: 'all',
                  },
                  ...wallets.map((wallet) => ({
                    label: !wallet.name
                      ? formatAddress(wallet.wallet.address)
                      : wallet.name,
                    value: wallet.id,
                  })),
                ]}
              />
            </Flex>
            <Flex align="center" gap={12}>
              <Typography.Text>Period</Typography.Text>
              <Segmented<Period>
                value={selectedPeriod}
                onChange={setSelectedPeriod}
                disabled={wallets.length <= 0}
                options={[
                  {
                    value: Period.Day,
                    label: 'Day',
                  },
                  {
                    value: Period.Week,
                    label: 'Week',
                  },
                  {
                    value: Period.Month,
                    label: 'Month',
                  },
                ]}
              />
            </Flex>
            <Button
              icon={<DownloadOutlined />}
              loading={isCSVLoading}
              onClick={handleDownloadCSV}
              disabled={wallets.length <= 0}
            >
              .csv
            </Button>
          </Flex>
        </Flex>
        {isDataLoading && (
          <Flex justify="center" align="center" style={{ height: 206 }}>
            <Spin />
          </Flex>
        )}
        {!isDataLoading && (!hasWallets || !hasData) && (
          <Flex justify="center" align="center" style={{ height: 206 }}>
            <NoWalletsPlaceholder />
          </Flex>
        )}
        {!isDataLoading && hasWallets && hasData && (
          <Table
            size="small"
            columns={columns}
            dataSource={formattedData}
            scroll={{ x: 232 + columns.length * 162 }}
            pagination={false}
            loading={isLoading}
            rowClassName={styles.breakdownRow}
          />
        )}
      </Flex>
    </>
  );
}
