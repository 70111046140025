import { LinkOutlined } from '@ant-design/icons';
import { Card, Flex, Tag, Typography } from 'antd';
import { ExternalIcon } from 'assets/icons/External';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useMemo } from 'react';
import { Wallet } from 'types/wallet';
import { formatUSDValue } from 'utilities/number';
import { toCapital } from 'utilities/string';
import { formatAddress } from 'utilities/wallets';
import styles from './styles.module.css';

interface WalletCardProps {
  wallet: Wallet;
}

export function WalletCard({ wallet }: WalletCardProps) {
  const [copiedText, copyToClipboard] = useCopyToClipboard(2000);

  const explorerLink = useMemo(() => {
    switch (wallet.wallet.network) {
      case 'tron':
        return 'https://tronscan.org/#/address/';

      case 'eth':
        return 'https://etherscan.io/address/';

      default:
        return '';
    }
  }, [wallet.wallet.network]);

  const handleCopy = () => {
    if (copiedText === wallet.wallet.address) return;

    copyToClipboard(wallet.wallet.address);
  };

  return (
    <Card
      className={styles.walletCard}
      classNames={{ body: styles.walletCardBody }}
    >
      <Flex gap={7} vertical className={styles.walletCardHeader}>
        <Flex align="center" justify="space-between">
          <Flex>
            <Tag color="geekblue">{toCapital(wallet.wallet.network)}</Tag>
            {!!wallet.name && <Tag>{wallet.name}</Tag>}
          </Flex>
          <Flex gap={16}>
            <a
              href={`${explorerLink}${wallet.wallet.address}`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.walletCardAction}
              aria-label="Open in explorer"
            >
              <ExternalIcon width={24} height={24} />
            </a>
            <button
              aria-label="Copy wallet address"
              className={styles.walletCardAction}
              onClick={handleCopy}
            >
              <LinkOutlined width={24} height={24} />
            </button>
          </Flex>
        </Flex>
        <Typography.Text>
          {formatAddress(wallet.wallet.address, 10, 10)}
        </Typography.Text>
      </Flex>
      <Flex gap={4} vertical>
        <span
          className={styles.walletCardBalance}
          dangerouslySetInnerHTML={{
            __html: formatUSDValue(wallet.wallet.usd_balance ?? 0, ''),
          }}
        />
        <span className={styles.walletCardDescription}>USD</span>
      </Flex>
    </Card>
  );
}
