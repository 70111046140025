import {
  ReloadOutlined,
  ThunderboltOutlined,
  WalletOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { Button, Flex, Form, Tooltip, Typography } from 'antd';
import { GasOutlined } from 'assets/icons/GasOtlined';
import { MINUTE } from 'constants/time';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useEVMMultisend } from 'libs/evm/hooks/useMultisend';
import { useAccount } from 'libs/hooks/useAccount';
import { useBalance } from 'libs/hooks/useBalance';
import { useTRONMultisend } from 'libs/tron/hooks/useMultisend';
import { NetworkType } from 'libs/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MultisenderForm } from 'screens/Multisender/form';
import { BuilderViewVariant } from 'screens/Multisender/store';
import { multisenderService } from 'services/multisender';
import { useCurrencyStore } from 'store/currencies';
import useSWR from 'swr';
import { GasEstimationsItem } from 'types/multisender';
import { useDebounceValue } from 'usehooks-ts';
import { formatUSDValue, toFixed } from 'utilities/number';
import { formatUnits } from 'viem';
import styles from '../../MultisenderView.module.css';

interface FooterSummaryProps {
  variant: BuilderViewVariant;
}

export function FooterSummary({ variant }: FooterSummaryProps) {
  const form = Form.useFormInstance<MultisenderForm>();
  const token = Form.useWatch<MultisenderForm['token']>('token', form);
  const recipients = Form.useWatch('recipients', form);
  const currencies = useCurrencyStore((s) => s.currencies);

  const { address, isConnected, networkType } = useAccount();

  const { data: gasEstimation } = useSWR(
    '/multisend/gas',
    () => multisenderService.getGasEstimations(),
    {
      revalidateOnFocus: false,
      refreshInterval: MINUTE,
    }
  );

  const copy = useCopyToClipboard()[1];

  const [selectedToken, tokenPlatform] = useMemo(() => {
    const selectedToken = currencies.find((c) => c.id === token);

    let chain: string;

    switch (networkType) {
      case NetworkType.EVM:
        chain = 'arbitrum';
        break;
      case NetworkType.Tron:
        chain = 'tron';
        break;
    }

    const tokenPlatform = selectedToken?.platforms?.find(
      (p) => p.chain === chain
    );

    return [selectedToken, tokenPlatform];
  }, [currencies, token, networkType]);

  const [totalGas, setTotalGas] = useState<number>(0);

  const totalSum = useMemo(() => {
    return (recipients ?? []).reduce(
      (acc, recipient) => acc + +(recipient?.amount ?? 0),
      0
    );
  }, [recipients]);

  const { estimateGasForApprove: estimateEVMGasForApprove } = useEVMMultisend();
  const { estimateGasForApprove: estimateTRONGasForApprove } =
    useTRONMultisend();

  const [debounceRecipients] = useDebounceValue(recipients, 500);

  const amountOfRecipients = useMemo(
    () =>
      recipients?.filter(
        (recipient) =>
          recipient?.counterparty ||
          recipient?.walletAddress?.[0] ||
          recipient?.amount
      )?.length ?? 0,
    [recipients]
  );

  const approveFee = useMemo(async () => {
    try {
      if (!tokenPlatform?.contract_address || !tokenPlatform.decimals) return;
      let approveFee: number | undefined;

      switch (networkType) {
        case NetworkType.Tron: {
          approveFee = await estimateTRONGasForApprove({
            token: tokenPlatform?.contract_address,
            decimals: tokenPlatform?.decimals,
            recipients: debounceRecipients,
          });
          break;
        }
        case NetworkType.EVM: {
          approveFee = await estimateEVMGasForApprove({
            token: tokenPlatform?.contract_address,
            decimals: tokenPlatform?.decimals,
            recipients: debounceRecipients,
          });
          break;
        }
        default: {
          return 0;
        }
      }

      return approveFee;
    } catch (error) {
      return undefined;
    }
  }, [
    estimateEVMGasForApprove,
    estimateTRONGasForApprove,
    networkType,
    tokenPlatform?.contract_address,
    tokenPlatform?.decimals,
    debounceRecipients,
  ]);

  const estimateGas = useCallback(async () => {
    if (
      !tokenPlatform?.contract_address ||
      !tokenPlatform.decimals ||
      !gasEstimation
    )
      return;

    let gas: GasEstimationsItem | undefined;
    switch (networkType) {
      case NetworkType.Tron: {
        gas = gasEstimation['tron'];
        break;
      }
      case NetworkType.EVM: {
        gas = gasEstimation['arbitrum'];
        break;
      }
      default: {
        return 0;
      }
    }

    if (amountOfRecipients <= 0) {
      return 0;
    }

    const approve = (await approveFee) ?? gas.approve_fee;

    const contractInteractionFee = gas.contract_interaction_fee;

    return +formatUnits(
      BigInt(
        approve +
          contractInteractionFee +
          gas.transfer_fee * (amountOfRecipients ?? 0)
      ),
      gas.currency_decimal
    );
  }, [
    networkType,
    tokenPlatform,
    gasEstimation,
    amountOfRecipients,
    approveFee,
  ]);

  useEffect(() => {
    if (!isConnected) return;

    estimateGas()
      .then((gas) => {
        setTotalGas(gas || 0);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [estimateGas, isConnected]);

  const {
    isLoading: isTokenBalanceLoading,
    balance: tokenBalance,
    symbol: tokenSymbol,
    refetch: refetchTokenBalance,
  } = useBalance({
    enable: !!tokenPlatform && variant === BuilderViewVariant.Default,
    address,
    networkType,
    token: tokenPlatform?.contract_address,
  });

  const {
    balance,
    symbol,
    isLoading: isBalanceLoading,
    refetch: refetchBalance,
  } = useBalance({
    enable: variant === BuilderViewVariant.Default,
    address,
  });

  return (
    <div className={styles.footerSummary}>
      <Flex gap={20}>
        <Flex vertical gap={8} className={styles.footerSummaryCol}>
          {variant === BuilderViewVariant.Default && (
            <Typography.Text className={styles.footerSummaryLabel}>
              <WalletOutlined />
              Token balance
            </Typography.Text>
          )}

          <Typography.Text className={styles.footerSummaryLabel}>
            <ThunderboltOutlined />
            Multisend total
          </Typography.Text>
        </Flex>
        <Flex
          align="flex-end"
          vertical
          gap={8}
          className={styles.footerSummaryCol}
        >
          {variant === BuilderViewVariant.Default && (
            <Flex gap={8}>
              {isTokenBalanceLoading && !!selectedToken && isConnected && (
                <Typography.Text>Loading...</Typography.Text>
              )}
              {isConnected && !isTokenBalanceLoading && !!selectedToken && (
                <Tooltip
                  overlayInnerStyle={{ textAlign: 'center' }}
                  title={
                    totalSum > tokenBalance && (
                      <>
                        Source wallet insufficient
                        <br /> funds. Top up {tokenSymbol}:
                        <br />
                        <strong>{totalSum - tokenBalance}</strong>
                        <br />
                        <Button
                          type="link"
                          size="small"
                          onClick={() =>
                            copy((totalSum - tokenBalance).toString())
                          }
                        >
                          Copy to clipboard
                        </Button>
                      </>
                    )
                  }
                >
                  <Flex gap={8}>
                    <Typography.Text
                      type={totalSum <= tokenBalance ? 'success' : 'danger'}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formatUSDValue(tokenBalance ?? 0, ''),
                        }}
                      />{' '}
                      {tokenSymbol}
                    </Typography.Text>
                    {totalSum > tokenBalance && (
                      <Typography.Text type="danger">
                        <WarningFilled />
                      </Typography.Text>
                    )}
                  </Flex>
                </Tooltip>
              )}
              {!isConnected && !isTokenBalanceLoading && (
                <Typography.Text>–</Typography.Text>
              )}
              {totalSum <= tokenBalance && (
                <div style={{ width: 16, height: 16 }} />
              )}
            </Flex>
          )}

          <Flex gap={8}>
            <Typography.Text>
              <span
                dangerouslySetInnerHTML={{
                  __html: formatUSDValue(totalSum ?? 0, ''),
                }}
              />{' '}
              {selectedToken?.symbol.toUpperCase()}
            </Typography.Text>
            <div style={{ width: 16, height: 16 }} />
          </Flex>
        </Flex>
      </Flex>
      <Flex gap={20}>
        <Flex vertical gap={8} className={styles.footerSummaryCol}>
          {variant === BuilderViewVariant.Default && (
            <Typography.Text className={styles.footerSummaryLabel}>
              <WalletOutlined />
              Gas balance
            </Typography.Text>
          )}

          <Typography.Text className={styles.footerSummaryLabel}>
            <GasOutlined />
            {variant === BuilderViewVariant.Default && 'Multisend gas'}
            {variant === BuilderViewVariant.Template &&
              'Approx. multisend fee:'}
          </Typography.Text>
        </Flex>
        <Flex
          align="flex-end"
          vertical
          gap={8}
          className={styles.footerSummaryCol}
        >
          {variant === BuilderViewVariant.Default && (
            <Flex gap={8}>
              {isBalanceLoading && isConnected && (
                <Typography.Text>Loading...</Typography.Text>
              )}
              {isConnected && !isBalanceLoading && (
                <Tooltip
                  overlayInnerStyle={{ textAlign: 'center' }}
                  title={
                    totalGas > balance && (
                      <>
                        Source wallet insufficient
                        <br /> funds. Top up {symbol}:
                        <br />
                        <strong>
                          {toFixed(totalGas - balance, tokenPlatform?.decimals)}
                        </strong>
                        <br />
                        <Button
                          type="link"
                          size="small"
                          onClick={() =>
                            copy(
                              toFixed(
                                totalGas - balance,
                                tokenPlatform?.decimals
                              ).toFixed(tokenPlatform?.decimals)
                            )
                          }
                        >
                          Copy to clipboard
                        </Button>
                      </>
                    )
                  }
                >
                  <Typography.Text
                    type={totalGas <= balance ? 'success' : 'danger'}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: formatUSDValue(
                          balance ?? 0,
                          '',
                          tokenPlatform?.decimals
                        ),
                      }}
                    />{' '}
                    {symbol}
                  </Typography.Text>
                  {totalGas > balance && (
                    <Typography.Text type="danger">
                      <WarningFilled style={{ marginLeft: 8 }} />
                    </Typography.Text>
                  )}
                </Tooltip>
              )}
              {!isConnected && !isBalanceLoading && (
                <Typography.Text>–</Typography.Text>
              )}
              {(totalGas <= balance || !isConnected) && (
                <div style={{ width: 16, height: 16 }} />
              )}
            </Flex>
          )}

          <Flex gap={8}>
            {!isConnected && <Typography.Text>–</Typography.Text>}
            {isConnected && (
              <Typography.Text>
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatUSDValue(
                      totalGas || 0,
                      '',
                      totalGas <= 0 ? 2 : tokenPlatform?.decimals
                    ),
                  }}
                />{' '}
                {symbol}
              </Typography.Text>
            )}
            <div style={{ width: 16, height: 16 }} />
          </Flex>
        </Flex>
      </Flex>
      {variant === BuilderViewVariant.Default && (
        <Button
          type="link"
          icon={<ReloadOutlined />}
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            refetchTokenBalance();
            refetchBalance();
          }}
          disabled={!isConnected}
        >
          Update balance
        </Button>
      )}
    </div>
  );
}
