import {
  AuditOutlined,
  LineChartOutlined,
  SwapOutlined,
  ThunderboltOutlined,
  UserOutlined,
  WalletOutlined
} from '@ant-design/icons';
import { Button, Menu, MenuProps } from 'antd';
import { LogoFull } from 'assets/icons/LogoFull';
import {
  SubscriptionDialog,
  SubscriptionDialogVariant,
} from 'components/SubscriptionDialog';
import { TrialBanner } from 'components/TrialBanner';
import { Routes } from 'constants/routs';
import { useEffect, useMemo } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { transactionService } from 'services/transactionService';
import { walletService } from 'services/walletService';
import { useSettingsStore } from 'store/settings';
import styles from './MainLayout.module.css';

export function MainLayout() {
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] =
    useSettingsStore((s) => [
      s.shouldOpenWelcomeDialog,
      s.setShouldOpenWelcomeDialog,
    ]);
  const { pathname } = useLocation();

  useEffect(() => {
    walletService.getWallets();
    transactionService.getTags();
    transactionService.getCurrencies();
  }, []);

  const tabItems: MenuProps['items'] = useMemo(
    () => [
      {
        label: <Link to={Routes.ProfitAndLoss}>Profit and loss</Link>,
        key: '/',
        icon: <LineChartOutlined />,
      },
      {
        label: <Link to={Routes.Transactions}>Transactions</Link>,
        key: '/transactions',
        icon: <SwapOutlined />,
      },
      {
        label: <Link to={Routes.Balances}>Balances</Link>,
        key: '/balances',
        icon: <WalletOutlined />,
      },
      {
        label: <Link to={Routes.AddressBook}>Address Book</Link>,
        key: '/address-book',
        icon:<AuditOutlined />,
      },
      {
        label: <Link to={Routes.Profile}>Profile</Link>,
        key: '/profile',
        icon: <UserOutlined />,
      },
    ],
    []
  );

  const selectedTab = useMemo(() => {
    if (!tabItems || tabItems.length <= 0) return;

    const tab = tabItems.find((tab) => pathname === (tab?.key as string));
    return tab?.key;
  }, [pathname, tabItems]);

  return (
    <>
      <TrialBanner />

      <div className={styles.container}>
        <header className={styles.header}>
          <Link to="/">
            <LogoFull width={128} height={24} />
          </Link>
          <Menu
            selectedKeys={[selectedTab as string]}
            className={styles.headerMenu}
            mode="horizontal"
            items={tabItems}
          />
          <Link to={Routes.Multisender}>
            <Button type="primary" icon={<ThunderboltOutlined />}>
              Multisender
            </Button>
          </Link>
        </header>
        <Outlet />

        <SubscriptionDialog
          isOpen={isSubscriptionDialogOpen}
          onOpenChange={setIsSubscriptionDialogOpen}
          variant={SubscriptionDialogVariant.Welcome}
        />
      </div>
    </>
  );
}
