import { Button, Flex, Typography } from 'antd';
import { DeleteAccountDialog } from './DeleteAccountDialog';
import { useState } from 'react';

export function DeleteAccount() {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  return (
    <Flex vertical gap={20}>
      <Flex vertical gap={4}>
        <Typography.Title level={4} style={{ margin: 0 }}>
          Delete account
        </Typography.Title>
        <Typography.Text>
          Once you delete your account, there is no way to restore your data.
          Please be certain.
        </Typography.Text>
      </Flex>
      <div>
        <Button onClick={() => setIsDeleteDialogOpen(true)}>
          Delete account
        </Button>
        <DeleteAccountDialog
          isOpen={isDeleteDialogOpen}
          onOpenChange={setIsDeleteDialogOpen}
        />
      </div>
    </Flex>
  );
}
