import { useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import {
  TronLinkAdapterName,
  WalletConnectWalletName,
} from '@tronweb3/tronwallet-adapters';
import { useNetworkType } from 'libs/hooks/useNetworkType';
import { NetworkType } from 'libs/types';
import { useState } from 'react';
import { TronAdapter } from '../types/connectors';

export const useConnect = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { select, connecting, connected, wallet, wallets } = useWallet();
  const [setNetworkType] = useNetworkType((s) => [s.setNetworkType]);

  const handleTronConnect = async (walletType: TronAdapter, maxRepeats = 1) => {
    setIsLoading(true);
    if (maxRepeats < 0) {
      setIsLoading(false);
      return;
    }
    maxRepeats--;
    try {
      if (walletType === TronAdapter.TronLink) {
        select(TronLinkAdapterName);
        if (!connected) {
          await wallets
            .find((w) => w.adapter.name === TronLinkAdapterName)
            ?.adapter.connect();
        }
        setNetworkType(NetworkType.Tron);
        return;
      }

      if (walletType === TronAdapter.WalletConnect) {
        await wallets
          .find((w) => w.adapter.name === WalletConnectWalletName)
          ?.adapter.connect();
        select(WalletConnectWalletName);
        setNetworkType(NetworkType.Tron);
        return;
      }
    } catch (error) {
      if (
        error instanceof Error &&
        error.message === 'No wallet is selected. Please select a wallet.'
      ) {
        await new Promise((res) => setTimeout(res, 10000));
        console.log(wallet);
        console.log(error.message);
        await handleTronConnect(walletType, maxRepeats);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isConnecting: connecting || isLoading,
    connect: handleTronConnect,
  }
};
