export interface Wallet {
  id?: string;
  name: string | null;
  active: boolean;
  wallet: {
    address: string;
    network: string;
    usd_balance: number | null;
    last_retrieved_at: string | null;
  };
}

export enum Stats {
  Income = 'income',
  Expenses = 'expenses',
  Pnl = 'pnl',
  Balance = 'balance',
}

export interface RecordItem {
  tag_id: string | null;
  tag_name: string | null;
  total_amount: number;
}

export interface BreakdownStats {
  [date: string]: {
    [category: string]: RecordItem[];
  };
}
