export const formatUSDValue = (value: number, prefix: string = '$&thinsp;', decimals: number = 2) => {
  const sign = value < 0 ? '-' : '';

  return `${sign}${prefix}${(Math.abs(value) ?? 0)
    .toFixed(decimals)
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '&thinsp;')}`;
};

export const toFixed = (value: number, precision: number = 2) => {
  return Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
};
