import { App, Flex, Form, Input, Modal } from 'antd';
import { AxiosError } from 'axios';
import { useMemo, useState } from 'react';
import { PasswordRules } from 'screens/SignUp/components/PasswordRules';
import { userService } from 'services/userService';
import styles from './styles.module.css';

interface PasswordChangeDialogProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

interface PasswordChangeFormType {
  newPassword: string;
}

export function PasswordSetDialog({
  isOpen,
  onOpenChange,
}: PasswordChangeDialogProps) {
  const { notification } = App.useApp();
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const password = Form.useWatch('newPassword', form);

  const [isTwelveCharacters, hasNumber, hasUpperCase, hasSpecialCharacter] =
    useMemo(() => {
      return [
        password?.length >= 12,
        /\d/.test(password),
        /[A-Z]/.test(password),
        /[~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/]/.test(password),
      ];
    }, [password]);

  const handleSubmit = async (values: PasswordChangeFormType) => {
    try {
      setIsSubmitting(true);
      await userService.updatePassword(values.newPassword);

      onOpenChange(false);
      notification.success({
        message: 'Set password',
        description: 'Password set successfully!',
        placement: 'bottom',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        notification.error({
          message: 'Set password',
          description: error.response?.data.message || error.message,
          placement: 'bottom',
        });
      } else if (error instanceof Error) {
        notification.error({
          message: 'Set password',
          description: error.message,
          placement: 'bottom',
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenChange = (isOpen: boolean) => {
    onOpenChange(isOpen);

    if (!isOpen) {
      form.resetFields();
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      afterOpenChange={handleOpenChange}
      centered
      onOk={() => form.submit()}
      onCancel={() => handleOpenChange(false)}
      title="Set password"
      okText="Save"
      okButtonProps={{
        loading: isSubmitting,
      }}
      style={{
        maxWidth: 408,
      }}
    >
      <Form<PasswordChangeFormType>
        form={form}
        onFinish={handleSubmit}
        validateTrigger={['onSubmit', 'onChange']}
        requiredMark={false}
        labelCol={{ span: 24 }}
        style={{ padding: '24px 0' }}
      >
        <Flex vertical gap={20}>
          <Form.Item
            name="newPassword"
            label="New password"
            className={styles.formItem}
            rules={[
              {
                required: true,
                message: 'This field is required.',
              },
              {
                validator: async (_, value) => {
                  if (!value) return;

                  if (
                    !isTwelveCharacters ||
                    !hasNumber ||
                    !hasUpperCase ||
                    !hasSpecialCharacter
                  ) {
                    return Promise.reject();
                  }
                },
                validateTrigger: ['onSubmit'],
              },
            ]}
          >
            <Flex vertical>
              <Input.Password
                size="large"
                placeholder="Enter new password"
                autoComplete="new-password"
              />
              <PasswordRules
                isTwelveCharacters={isTwelveCharacters}
                hasNumber={hasNumber}
                hasUpperCase={hasUpperCase}
                hasSpecialCharacter={hasSpecialCharacter}
              />
            </Flex>
          </Form.Item>
        </Flex>
      </Form>
    </Modal>
  );
}
