import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Flex, Typography } from 'antd';
import { Arbitrum } from 'assets/icons/chains/Arbitrum';
import { Tron } from 'assets/icons/chains/Tron';
import { WalletConnect } from 'assets/icons/WalletConnect';
import RainbowLogo from 'assets/images/RainbowLogo.png';
import TronLinkLogo from 'assets/images/TronLinkLogo.png';
import { useNetworkType } from 'libs/hooks/useNetworkType';
import { useConnect as useTronConnect } from 'libs/tron/hooks/useConnect';
import { TronAdapter } from 'libs/tron/types/connectors';
import { NetworkType } from 'libs/types';
import { useAccountEffect } from 'wagmi';
import styles from './styles.module.css';

interface ConnectWalletPopoverProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export function ConnectWalletPopover({
  isOpen,
  onOpenChange,
}: ConnectWalletPopoverProps) {
  const [setNetworkType] = useNetworkType((s) => [s.setNetworkType]);
  const { connect } = useTronConnect();

  useAccountEffect({
    onConnect(data) {
      if (isOpen && data.address) {
        setNetworkType(NetworkType.EVM);
      }
    },
  });

  return (
    <Flex vertical gap={12}>
      <Flex vertical gap={8}>
        <Typography.Text className={styles.walletOptionTitle}>
          <Tron />
          Tron
        </Typography.Text>
        <Flex vertical gap={4}>
          <button
            className={styles.walletOptionsItem}
            aria-label="Connect TronLink"
            onClick={() => {
              onOpenChange(false);
              connect(TronAdapter.TronLink);
            }}
          >
            <img src={TronLinkLogo} alt="TronLink logo" draggable={false} />
          </button>
          <button
            className={styles.walletOptionsItem}
            aria-label="Connect WalletConnect"
            onClick={() => {
              onOpenChange(false);
              connect(TronAdapter.WalletConnect);
            }}
          >
            <WalletConnect />
          </button>
        </Flex>
      </Flex>
      <Flex vertical gap={8}>
        <Typography.Text className={styles.walletOptionTitle}>
          <Arbitrum />
          Arbitrum
        </Typography.Text>
        <Flex vertical gap={4}>
          <ConnectButton.Custom>
            {({ openConnectModal, account }) => (
              <button
                className={styles.walletOptionsItem}
                aria-label="Connect Rainbow"
                onClick={async () => {
                  try {
                    onOpenChange(false);

                    if (account) {
                      setNetworkType(NetworkType.EVM);
                    } else {
                      openConnectModal();
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <img
                  src={RainbowLogo}
                  alt="RainbowWallet logo"
                  draggable={false}
                />
              </button>
            )}
          </ConnectButton.Custom>
        </Flex>
      </Flex>
    </Flex>
  );
}
