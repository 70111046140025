import { Col, Flex, Row, Segmented } from 'antd';
import { LogoFull } from 'assets/icons/LogoFull';
import waveImage from 'assets/images/wave.png';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from './AuthLayout.module.css';

type Tabs = 'LogIn' | 'SignUp' | '';

export function AuthLayout() {
  const [activeTab, setActiveTab] = useState<Tabs>('');
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/sign-up') {
      setActiveTab('SignUp');
    } else {
      setActiveTab('LogIn');
    }
  }, [pathname]);

  const handleTabChange = (tab: Tabs) => {
    if (tab === 'LogIn') {
      navigate('/log-in');
    } else {
      navigate('/sign-up');
    }
  };

  return (
    <Row className={styles.container}>
      <Col
        style={{
          backgroundColor: '#F6F7F9',
        }}
        span={12}
      >
        <Flex
          justify="center"
          align="center"
          className={styles.waveImagesContainer}
          vertical
          gap={40}
        >
          <img
            src={waveImage}
            className={styles.waveImage}
            draggable={false}
            alt=""
          />
          <img
            src={waveImage}
            className={styles.waveImage}
            draggable={false}
            alt=""
          />
          <img
            src={waveImage}
            className={styles.waveImage}
            draggable={false}
            alt=""
          />
        </Flex>
      </Col>
      <Col span={12}>
        <div className={styles.formContainer}>
          <Flex className={styles.form} gap={24} vertical>
            <Flex vertical gap={80}>
              <LogoFull width={107} height={21} />
              <Segmented<Tabs>
                options={[
                  {
                    label: 'Log in',
                    value: 'LogIn',
                  },
                  {
                    label: 'Sign up',
                    value: 'SignUp',
                  },
                ]}
                size="large"
                block
                value={activeTab}
                onChange={handleTabChange}
              />
            </Flex>
            <Outlet />
          </Flex>
        </div>
      </Col>
    </Row>
  );
}
