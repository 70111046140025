import { WarningFilled } from '@ant-design/icons';
import { Dropdown, Flex, Menu, Table, Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { ArrowUpRight } from 'assets/icons/ArrowUpRight';
import { Copy } from 'assets/icons/CopyIcon';
import { FileEdit } from 'assets/icons/FileEdit';
import { TagById } from 'components/Tag';
import { TagsGroupEllipsis } from 'components/TagsGroupEllipsis';
import { AMOUNT_OF_TRANSFERS_PER_PAGE } from 'constants/transfers';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useTransactionsFiltersStore } from 'screens/Transactions/store';
import { settingsStore } from 'store/settings';
import { Transaction, TransactionsPaginated } from 'types';
import { formatDate } from 'utilities/date';
import { formatUSDValue } from 'utilities/number';
import { TransactionDialog } from '../TransactionDialog';
import { ExpandedRow } from './ExpandedRow';
import { WalletCell } from './WalletCell';
import styles from './styles.module.css';
import { QuickTagPopover } from '../QuickTagPopover';

export const columns: ColumnProps<Transaction>[] = [
  {
    title: 'Tags',
    dataIndex: 'payments',
    key: 'payments',
    width: 218,
    sorter: (a, b) => a.payments.length - b.payments.length,
    fixed: 'left',
    render: (payments: Transaction['payments'], record, i) => {
      if (!payments || payments.length <= 0) {
        return (
          <>
            <Flex align="center" gap={17}>
              <QuickTagPopover transaction={record} />
              <WarningFilled style={{ color: '#FAAD14' }} />
            </Flex>
          </>
        );
      }

      return (
        <TagsGroupEllipsis
          tags={payments.map((tag, i) => (
            <TagById key={i} id={tag.tag_id} className={styles.truncateTag} />
          ))}
        />
      );
    },
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    sorter: (a, b) => {
      return a.source.address.localeCompare(b.source.address);
    },
    width: 221,
    render: (source: Transaction['source']) => {
      return <WalletCell value={source} withCopy />;
    },
  },
  {
    title: 'Target',
    dataIndex: 'target',
    key: 'target',
    sorter: (a, b) => {
      return a.target.address.localeCompare(b.target.address);
    },
    width: 221,
    render: (target: Transaction['target']) => {
      return <WalletCell value={target} withCopy />;
    },
  },
  {
    title: 'Token',
    dataIndex: 'currency',
    key: 'currency',
    sorter: (a, b) => a.currency.symbol.localeCompare(b.currency.symbol),
    width: 93,
    render: (token: Transaction['currency'], record) => {
      const chain = settingsStore
        .getState()
        .settings.chains.find((el) =>
          record.explorer_url.startsWith(el.explorer_url)
        );
      return (
        <Flex align="center" gap={8}>
          <div className={styles.transactionTokenContainer}>
            <img
              src={token?.logo_url}
              alt={token?.name}
              className={styles.transactionTokenIcon}
            />
            <img
              src={record.chain_icon_url}
              className={styles.transactionNetworkIcon}
              alt=""
            />
          </div>
          <Flex gap={2} vertical style={{ marginTop: -2, marginBottom: -2 }}>
            <span className={styles.transactionTokenName}>
              {token?.symbol?.toUpperCase()}
            </span>
            <span className={styles.transactionNetworkName}>{chain?.name}</span>
          </Flex>
        </Flex>
      );
    },
  },
  {
    title: 'Amount',
    align: 'right',
    dataIndex: 'amount',
    key: 'amount',
    sorter: (a, b) => a.amount - b.amount,
    width: 150,
    render: (amount: number, record) => {
      if (record.type === 'income') {
        return (
          <Typography.Text type="success">
            +
            <span
              dangerouslySetInnerHTML={{
                __html: formatUSDValue(amount, ''),
              }}
            />
          </Typography.Text>
        );
      }

      if (!amount) {
        return <Typography.Text>{amount}</Typography.Text>;
      }

      return (
        <Typography.Text type="danger">
          -
          <span
            dangerouslySetInnerHTML={{
              __html: formatUSDValue(amount, ''),
            }}
          />
        </Typography.Text>
      );
    },
  },
  {
    title: 'Date',
    dataIndex: 'date',
    align: 'right',
    key: 'date',
    sorter: (a, b) => a.date.localeCompare(b.date),
    defaultSortOrder: 'descend',
    width: 130,
    render: (date: string) => formatDate(date),
  },
];

interface TransactionsTableProps {
  transactions?: TransactionsPaginated;
  isLoading?: boolean;
}

export function TransactionsTable({
  transactions,
  isLoading,
}: TransactionsTableProps) {
  const [page, setPage] = useTransactionsFiltersStore((s) => [
    s.page,
    s.setPage,
  ]);

  const copy = useCopyToClipboard()[1];

  return (
    <Table
      loading={isLoading}
      columns={[
        ...columns,
        {
          title: 'Actions',
          key: 'actions',
          width: 67,
          align: 'right',
          render: (_, record) => (
            <Dropdown
              trigger={['click']}
              placement="bottomRight"
              dropdownRender={() => (
                <Menu>
                  <Menu.Item key="blockExplorer" className={styles.menuItem}>
                    <a
                      href={record?.explorer_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ArrowUpRight />
                      Block explorer
                    </a>
                  </Menu.Item>
                  <TransactionDialog transaction={record}>
                    <Menu.Item key="edit" className={styles.menuItem}>
                      <FileEdit />
                      Edit transaction
                    </Menu.Item>
                  </TransactionDialog>
                  <Menu.Item
                    key="copySourceAddress"
                    className={styles.menuItem}
                    onClick={() => {
                      return copy(record.source.address);
                    }}
                  >
                    <Copy /> Copy Source wallet address
                  </Menu.Item>
                  <Menu.Item
                    key="copyTargetAddress"
                    className={styles.menuItem}
                    onClick={() => {
                      return copy(record.target.address);
                    }}
                  >
                    <Copy /> Copy Target wallet address
                  </Menu.Item>
                </Menu>
              )}
            >
              <Typography.Link>More</Typography.Link>
            </Dropdown>
          ),
        },
      ]}
      pagination={{
        position: ['none', 'bottomCenter'],
        pageSize: AMOUNT_OF_TRANSFERS_PER_PAGE,
        showSizeChanger: false,
        current: page,
        total: transactions?.meta?.total ?? 0,
        onChange: (page) => {
          setPage(page);
        },
      }}
      size="small"
      expandable={{
        rowExpandable(record) {
          return record.payments && record.payments.length > 0;
        },
        expandedRowClassName: () => styles.expandedRow,
        expandedRowRender: (record) => <ExpandedRow transaction={record} />,
      }}
      rowSelection={{
        type: 'checkbox',
        onChange: () => {},
      }}
      dataSource={
        transactions?.transfers?.map((transaction, i) => ({
          ...transaction,
          key: i,
        })) ?? []
      }
      scroll={{ x: 1200 }}
    />
  );
}
