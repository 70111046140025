import { WalletOutlined } from '@ant-design/icons';
import { Button, Empty } from 'antd';
import { Routes } from 'constants/routs';
import { Link } from 'react-router-dom';

export function NoWalletsPlaceholder() {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="No wallets to track. Add wallet at Profile section."
    >
      <Link to={Routes.Profile}>
        <Button
          type="primary"
          icon={<WalletOutlined />}
          style={{ marginTop: 8 }}
        >
          Add wallet
        </Button>
      </Link>
    </Empty>
  );
}
