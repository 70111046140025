import { Flex, Table, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { userService } from 'services/userService';
import useSWR from 'swr';
import { PaymentHistoryItem } from 'types/user';
import { formatDate } from 'utilities/date';
import { formatUSDValue } from 'utilities/number';

interface BillingHistoryItem extends PaymentHistoryItem {
  invoice: string;
}

const columns: ColumnsType<BillingHistoryItem> = [
  { title: 'Invoice', dataIndex: 'invoice', key: 'invoice' },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount) => (
      <>
        <span
          dangerouslySetInnerHTML={{
            __html: formatUSDValue(amount ?? 0, ''),
          }}
        />{' '}
        USDT
      </>
    ),
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (date) => formatDate(date),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 91,
    render: (status) => {
      switch (status) {
        case 'Succeeded':
          return <Tag color="green">{status}</Tag>;

        default:
          return <Tag>{status}</Tag>;
      }
    },
  },
];

export function BillingHistory() {
  const { data, isLoading } = useSWR('/api/user_payments', () =>
    userService.getPayments()
  );

  return (
    <Flex vertical gap={20}>
      <Typography.Title level={4} style={{ margin: 0 }}>
        Billing History
      </Typography.Title>
      <Table<BillingHistoryItem>
        size="small"
        loading={isLoading}
        pagination={false}
        columns={columns}
        dataSource={
          data
            ?.sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )
            ?.map((record) => ({
              invoice: 'Pro subscription',
              ...record,
              key: record.id,
            })) ?? []
        }
      />
    </Flex>
  );
}
