import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Typography } from 'antd';
import { Copy } from 'assets/icons/CopyIcon';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { ReferralTable } from '../ReferralTable';
import styles from './styles.module.css';
import { useUserStore } from 'store/user';

export function ReferralInfo() {
  const user = useUserStore(s => s.user);
  const [copiedText, copyToClipboard] = useCopyToClipboard(3000);

  const handleCopyReferralLink = () => {
    if (!user?.ref_code) return;

    copyToClipboard(`https://app.finflo.io/sign-up?ref=${user?.ref_code}`);
  };

  return (
    <>
      <Flex vertical gap={16}>
        <Typography.Text className="largeText">
          Invite new users to FinFlo and earn <strong>50$</strong> for
          every user with paid subscription. Start referring and earning today!
        </Typography.Text>
        <Card classNames={{ body: styles.cardBody }}>
          <Flex vertical gap={4}>
            <Typography.Text className={styles.cardLabel}>
              Your personal referral link
            </Typography.Text>
            <Flex justify="space-between" align="center">
              <Typography.Text className="largeText">
                https://app.finflo.io/sign-up?ref={user?.ref_code}
              </Typography.Text>
              <Button
                icon={
                  copiedText ===
                  `https://app.finflo.io/sign-up?ref=${user?.ref_code}` ? (
                    <CheckOutlined width={16} height={16} />
                  ) : (
                    <Copy width={20} height={20} />
                  )
                }
                disabled={!user?.ref_code}
                className={styles.cardButton}
                onClick={handleCopyReferralLink}
              >
                Copy
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Flex>
      <ReferralTable />
    </>
  );
}
