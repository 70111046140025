import { useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { useNetworkType } from 'libs/hooks/useNetworkType';
import { NetworkType } from 'libs/types';
import { useAccountEffect } from 'wagmi';

export function EVMSwitcher({ children }: { children: React.ReactNode }) {
  const [networkType, setNetworkType] = useNetworkType((s) => [
    s.networkType,
    s.setNetworkType,
  ]);
  const { connected: isTronConnected } = useWallet();

  useAccountEffect({
    onConnect: () => {
      if (!networkType) setNetworkType(NetworkType.EVM);
    },
    onDisconnect: () => {
      if (isTronConnected) {
        setNetworkType(NetworkType.Tron);
      } else {
        setNetworkType(undefined);
      }
    },
  });

  return children;
}
