import { SVGAttributes } from 'react';

export function Profile(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      {...props}
    >
      <rect x="26" y="26" width="100" height="100" rx="14" fill="#EDEFF3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76 51.5C62.469 51.5 51.5 62.469 51.5 76C51.5 83.4776 54.85 90.1729 60.131 94.6668C64.3101 90.8393 69.8833 88.5 76 88.5C82.1168 88.5 87.6898 90.8394 91.8688 94.667C97.1499 90.1731 100.5 83.4778 100.5 76C100.5 62.469 89.531 51.5 76 51.5ZM87.5984 97.5861C84.4244 95.0283 80.3918 93.5 76 93.5C71.6083 93.5 67.5754 95.0282 64.4014 97.586C67.854 99.4451 71.8038 100.5 76 100.5C80.1961 100.5 84.1458 99.4451 87.5984 97.5861ZM46.5 76C46.5 59.7076 59.7076 46.5 76 46.5C92.2924 46.5 105.5 59.7076 105.5 76C105.5 92.2924 92.2924 105.5 76 105.5C59.7076 105.5 46.5 92.2924 46.5 76ZM76 66.5C72.4101 66.5 69.5 69.4101 69.5 73C69.5 76.5899 72.4101 79.5 76 79.5C79.5899 79.5 82.5 76.5899 82.5 73C82.5 69.4101 79.5899 66.5 76 66.5ZM64.5 73C64.5 66.6487 69.6487 61.5 76 61.5C82.3513 61.5 87.5 66.6487 87.5 73C87.5 79.3513 82.3513 84.5 76 84.5C69.6487 84.5 64.5 79.3513 64.5 73Z"
        fill="#667085"
      />
    </svg>
  );
}
