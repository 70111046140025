import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { wagmiConfig } from 'configs/wagmi';
import { WagmiProvider } from 'wagmi';
import { TronProvider } from './WalletsSwitcher/TronProvider';

const queryClient = new QueryClient();

export function WalletsProvider({ children }: { children: React.ReactNode }) {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider locale="en">
          <TronProvider>{children}</TronProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
