import { Tag as AntTag, TagProps as AntTagProps } from 'antd';
import { useTagsStore } from 'store/tags';
import { Tag as TagType } from 'types';

interface TagByIdProps extends AntTagProps {
  id: TagType['id'];
}

export function TagById({ id, ...props }: TagByIdProps) {
  const tags = useTagsStore((state) => state.tags);

  return (
    <AntTag {...props}>
      <span>{tags.find((el) => el.id === id)?.name}</span>
    </AntTag>
  );
}
