import { Flex, Spin, Typography } from 'antd';
import { NoWalletsPlaceholder } from 'components/NoWalletsPlaceholder';
import { useWalletsStore } from 'store/wallets';
import viewStyles from '../../BalancesView.module.css';
import { WalletCard } from './WalletCard';
import styles from './styles.module.css';

export function WalletsGrid() {
  const [wallets, isLoading] = useWalletsStore((state) => [
    state.wallets,
    state.isLoading,
  ]);

  return (
    <Flex gap={32} vertical className={viewStyles.block}>
      <Flex justify="space-between">
        <Typography.Title level={2} className={viewStyles.blockHeading}>
          Wallets
        </Typography.Title>
      </Flex>
      {isLoading && (
        <Flex justify="center" align="center" style={{ height: 320 }}>
          <Spin />
        </Flex>
      )}
      {!isLoading && wallets.length <= 0 && (
        <Flex justify="center" align="center" style={{ height: 320 }}>
          <NoWalletsPlaceholder />
        </Flex>
      )}
      {!isLoading && wallets.length > 0 && (
        <div className={styles.walletsGrid}>
          {wallets.map((wallet) => (
            <WalletCard key={wallet.id} wallet={wallet} />
          ))}
        </div>
      )}
    </Flex>
  );
}
