import axios, { AxiosInstance } from 'axios';
import { API_URL } from 'configs/api';
import { Counterparty, CounterpartyWallet } from 'types/counterparty';
import { getAccessToken } from 'utilities/user';

export class AddressBookService {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
    });
  }

  async getCounterparties(withTurnover = false) {
    const url = '/counterparties';
    const { data } = await this._api.get<Counterparty[]>(url, {
      params: {
        with_turnover: withTurnover,
      },
      headers: {
        Authorization: getAccessToken(true),
      },
    });

    return data;
  }

  async getCounterparty(id: Counterparty['id']) {
    try {
      const url = `/counterparties/${id}`;
      const { data } = await this._api.get<{ counterparty: Counterparty }>(
        url,
        {
          headers: {
            Authorization: getAccessToken(true),
          },
        }
      );

      return data.counterparty;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async createCounterparty(
    name: Counterparty['name'],
    note: Counterparty['note'],
    label: Counterparty['label']
  ) {
    const url = '/counterparties';

    const { data } = await this._api.post<Counterparty>(
      url,
      {
        name,
        note,
        label,
      },
      {
        headers: {
          Authorization: getAccessToken(true),
        },
      }
    );

    return data;
  }

  async updateCounterparty(
    id: Counterparty['id'],
    name: Counterparty['name'],
    note: Counterparty['note'],
    label: Counterparty['label']
  ) {
    const url = `/counterparties/${id}`;

    const { data } = await this._api.put<Counterparty>(
      url,
      {
        name,
        note,
        label,
      },
      {
        headers: {
          Accept: 'application/json',
          Authorization: getAccessToken(true),
        },
      }
    );

    return data;
  }

  async deleteCounterparty(id: Counterparty['id']) {
    const url = `/counterparties/${id}`;

    await this._api.delete(url, {
      headers: {
        Authorization: getAccessToken(true),
      },
    });
  }

  async getWallets(withTurnover = false) {
    const url = `/counterparty_wallets`;

    const { data } = await this._api.get<CounterpartyWallet[]>(url, {
      params: {
        with_turnover: withTurnover,
      },
      headers: {
        Authorization: getAccessToken(true),
      },
    });

    return data;
  }

  async getWallet(id: string) {
    try {
      const url = `/counterparty_wallets/${id}`;

      const { data } = await this._api.get<{
        counterparty_wallet: CounterpartyWallet;
      }>(url, {
        headers: {
          Authorization: getAccessToken(true),
        },
      });

      return data.counterparty_wallet;
    } catch (error) {}
    return null;
  }

  async createWallet(
    name: CounterpartyWallet['name'],
    network: CounterpartyWallet['wallet']['network'],
    address: CounterpartyWallet['wallet']['address'],
    counterparty_id: CounterpartyWallet['counterparty_id']
  ) {
    const url = `/counterparty_wallets`;

    const { data } = await this._api.post<{
      counterparty_wallet: CounterpartyWallet;
    }>(
      url,
      {
        name,
        network,
        address,
        counterparty_id,
      },
      {
        headers: {
          Accept: 'application/json',
          Authorization: getAccessToken(true),
        },
      }
    );

    return data.counterparty_wallet;
  }

  async updateWallet(
    id: CounterpartyWallet['id'],
    name: CounterpartyWallet['name'],
    counterparty_id: CounterpartyWallet['counterparty_id'],
    address: CounterpartyWallet['wallet']['address'],
    network: CounterpartyWallet['wallet']['network']
  ) {
    const url = `/counterparty_wallets/${id}`;

    const { data } = await this._api.put<{
      counterparty_wallet: CounterpartyWallet;
    }>(
      url,
      {
        name,
        counterparty_id,
        address,
        network,
      },
      {
        headers: {
          Accept: 'application/json',
          Authorization: getAccessToken(true),
        },
      }
    );

    return data.counterparty_wallet;
  }

  async deleteWallet(id: CounterpartyWallet['id']) {
    const url = `/counterparty_wallets/${id}`;

    await this._api.delete(url, {
      headers: {
        Authorization: getAccessToken(true),
      },
    });
  }

  async deleteWalletFromCounterparty(id: CounterpartyWallet['id']) {
    const url = `/counterparty_wallets/${id}/remove_counterparty`;

    await this._api.post(
      url,
      {},
      {
        headers: {
          Authorization: getAccessToken(true),
        },
      }
    );
  }
}

export const addressBookService = new AddressBookService();
