import { EditableProTable, ProColumns } from '@ant-design/pro-components';
import { Button, Empty, Form, Typography } from 'antd';
import { useAccount } from 'libs/hooks/useAccount';
import WAValidator from 'multicoin-address-validator';
import { useMemo } from 'react';
import { MultisenderForm } from 'screens/Multisender/form';
import {
  BuilderViewVariant,
  useMultisenderStore,
} from 'screens/Multisender/store';
import { addressBookService } from 'services/addressBook';
import useSWR from 'swr';
import { formatAddress } from 'utilities/wallets';
import { RecordCreator } from './RecordCreator';
import styles from './styles.module.css';

export function RecipientsTable() {
  const form = Form.useFormInstance<MultisenderForm>();
  const { networkType } = useAccount();
  const [variant, initialBuilderValues] = useMultisenderStore((s) => [
    s.builderViewVariant,
    s.initialBuilderValues,
  ]);

  const networkCode = useMemo(() => {
    switch (variant) {
      case BuilderViewVariant.Default:
        return networkType;
      case BuilderViewVariant.Template:
        return initialBuilderValues?.network;
    }
  }, [networkType, variant, initialBuilderValues?.network]);

  const recipients = Form.useWatch('recipients', form);

  const { data: counterparties, isLoading: isCounterpartiesLoading } = useSWR(
    '/counterparties',
    () => addressBookService.getCounterparties()
  );

  const { data: wallets, isLoading: isWalletsLoading } = useSWR(
    '/counterparties_wallets',
    () => addressBookService.getWallets()
  );

  const columns: ProColumns<MultisenderForm['recipients'][0]>[] = [
    {
      title: '#',
      dataIndex: 'no',
      width: 50,
      editable: false,
      renderText(_, __, index) {
        return index + 1;
      },
    },
    {
      title: 'Counterparty',
      dataIndex: 'counterparty',
      width: 288,
      valueType: 'select',
      editable: () => {
        return true;
      },
      fieldProps: (form, { rowIndex }) => ({
        size: 'small',
        placeholder: 'Select counterparty',
        showSearch: true,
        options: counterparties
          ?.filter(
            (c) =>
              networkCode &&
              (c.wallets_count?.[networkCode as keyof typeof c.wallets_count] ??
                0) > 0
          )
          ?.map((c) => ({
            label: c.name,
            key: c?.id,
            value: c?.id,
          })),
        notFoundContent: (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
        ),
        onClear: () => {
          form.setFieldValue(
            ['recipients', rowIndex, 'walletAddress'],
            undefined
          );
        },
      }),
      formItemProps: (_, { entity, rowIndex }) => {
        return {
          dependencies: ['walletAddress', 'amount'],
          rules: [
            {
              validator: (_, value) => {
                if (!value && !entity?.walletAddress?.[0] && !entity?.amount) {
                  return Promise.resolve();
                }

                if (!value && !entity?.walletAddress?.[0]) {
                  return Promise.reject('This field is required');
                }

                return Promise.resolve();
              },
            },
          ],
        };
      },
    },
    {
      title: 'Wallet name or address',
      dataIndex: 'walletAddress',
      width: 470,
      valueType: 'select',

      editable: () => {
        return true;
      },
      render: (text, record) => {
        const wallet = wallets?.find(
          (w) => w.wallet.address === record.walletAddress?.[0]
        );
        return wallet?.name ?? formatAddress(wallet?.wallet.address ?? '');
      },
      fieldProps: (form, cell) => ({
        size: 'small',
        placeholder: 'Select wallet',
        showSearch: true,
        mode: 'tags',
        maxCount: 1,
        className: 'walletAddressSelect',

        tagRender: ({ label, value }: any) => (
          <Typography.Text
            ellipsis
            style={{
              display: 'block',
              maxWidth: '232px',
              height: '20px',
              marginTop: '0px',
            }}
          >
            {label ?? value}
          </Typography.Text>
        ),

        options: wallets
          ?.filter((wallet) => {
            return (
              wallet.wallet.network === networkCode &&
              wallet.counterparty_id === cell.entity.counterparty
            );
          })
          ?.map((wallet) => ({
            label: !!wallet?.name
              ? wallet.name
              : formatAddress(wallet.wallet.address),
            key: wallet.wallet.address,
            value: wallet.wallet.address,
          })),
        notFoundContent: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              !form?.getFieldValue(cell?.entity?.id)?.counterparty
                ? 'Select counterparty or enter wallet address manually'
                : 'No data for selected counterparty and network'
            }
          />
        ),
      }),
      formItemProps: (_, { entity, rowIndex }) => {
        return {
          dependencies: ['counterparty', 'amount'],
          rules: [
            {
              validateTrigger: 'onSubmit',
              validator(_, values) {
                const value = values?.[0];

                if (!entity?.counterparty && !value && !entity?.amount) {
                  return Promise.resolve();
                }

                if (!value) {
                  return Promise.reject('This field is required');
                }

                const res = WAValidator.validate(value, networkCode);
                if (value?.length === 0) return Promise.resolve();
                if (!res) {
                  return Promise.reject('This address is not valid.');
                } else {
                  return Promise.resolve();
                }
              },
            },
          ],
        };
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: 227,
      valueType: 'digit',
      editable: () => {
        return true;
      },
      fieldProps: (form, cell) => ({
        type: 'number',
        size: 'small',
        placeholder: 'Enter amount',
        style: {
          width: '100%',
        },
        changeOnWheel: false,
        onPressEnter: async (e) => {
          e.preventDefault();

          if (recipients.length - 1 === cell.rowIndex) {
            const targetFieldId = `recipients_${
              cell.rowIndex + 1
            }_counterparty`;

            form.setFieldsValue({
              recipients: [
                ...recipients,
                {
                  id: crypto.randomUUID(),
                  no: recipients.length + 1,
                },
              ],
            });

            setTimeout(() => {
              document.getElementById(targetFieldId)?.focus();
              document.getElementById(targetFieldId)?.scrollIntoView({
                behavior: 'smooth',
              });
            }, 1);
          }
        },
      }),
      formItemProps: (_, { rowIndex, entity }) => {
        return {
          dependencies: ['counterparty', 'walletAddress'],
          rules: [
            {
              validateTrigger: 'onSubmit',
              validator(_, value) {
                if (
                  !entity.counterparty &&
                  !entity.walletAddress?.[0] &&
                  !value
                ) {
                  return Promise.resolve();
                }

                if (!value) {
                  return Promise.reject('This field is required');
                }

                return Promise.resolve();
              },
            },
          ],
        };
      },
    },
    {
      title: 'Actions',
      dataIndex: 'amount',
      valueType: 'option',
      width: 165,
      render: () => {
        return null;
      },
    },
  ];

  const handleDuplicate = async (record: MultisenderForm['recipients'][0]) => {
    const recipients = form.getFieldsValue().recipients ?? [];
    recipients.push({
      ...record,
      id: crypto.randomUUID(),
    });

    form.setFieldValue('recipients', recipients);
  };

  const handleDelete = async (record: MultisenderForm['recipients'][0]) => {
    const recipients = form.getFieldsValue().recipients ?? [];
    const newRecipients = recipients.filter((el) => el?.id !== record?.id);

    form.setFieldValue('recipients', newRecipients);
  };

  return (
    <div>
      <EditableProTable<MultisenderForm['recipients'][0]>
        rowKey="id"
        size="small"
        name={'recipients'}
        sortDirections={[null]}
        showSorterTooltip={false}
        loading={isCounterpartiesLoading || isWalletsLoading}
        cardProps={{
          bodyStyle: { padding: 0 },
        }}
        columns={columns}
        editable={{
          type: 'multiple',
          editableKeys: recipients?.map((el) => el?.id),
          onlyAddOneLineAlertMessage: 'Only one line can be added at a time',
          onlyOneLineEditorAlertMessage:
            'Only one line can be edited at a time',
          deletePopconfirmMessage: 'Are you sure?',
          actionRender: (record) => [
            <Button
              type="link"
              key="duplicate"
              onClick={async () => {
                await handleDuplicate(record);
              }}
              style={{ padding: 0, height: 'auto' }}
            >
              Duplicate
            </Button>,
            <Button
              type="link"
              key="delete"
              onClick={async () => {
                await handleDelete(record);
              }}
              style={{ padding: 0, height: 'auto' }}
            >
              Delete
            </Button>,
          ],
          form,
        }}
        recordCreatorProps={false}
        className="withoutOffset"
        tableClassName={styles.tableWidth}
      />
      <RecordCreator />
    </div>
  );
}
