import {
  App,
  AutoComplete,
  Button,
  Flex,
  Form,
  Popover,
  Typography,
} from 'antd';
import { AxiosError } from 'axios';
import { transactionService } from 'services/transactionService';
import { useTagsStore } from 'store/tags';
import { PaymentCreateRequest, Transaction } from 'types';
import { TransactionDialog } from '../TransactionDialog';
import styles from './styles.module.css';
import { useState } from 'react';
import { useTransactionsFiltersStore } from 'screens/Transactions/store';
import { useSWRConfig } from 'swr';

interface QuickTagPopoverProps {
  transaction: Transaction;
}

interface QuickTagForm {
  tag: string;
}

export function QuickTagPopover({ transaction }: QuickTagPopoverProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm<QuickTagForm>();
  const tags = useTagsStore((s) => s.tags);
  const { notification } = App.useApp();
  const requestKey = useTransactionsFiltersStore((s) => s.requestKey);
  const { mutate } = useSWRConfig();

  const handleSubmit = async (values: QuickTagForm) => {
    try {
      setIsSubmitting(true);
      const tag = tags.find((el) => el.name === values.tag);

      const payments = [
        {
          usd_amount: +transaction.amount,
          [!tag || !tag.id ? 'tag_name' : 'tag_id']:
            !tag || !tag.id ? values.tag : tag?.id,
        },
      ] as PaymentCreateRequest[];

      await transactionService.createPayment(
        transaction,
        transaction.comment ?? '',
        payments
      );

      await mutate(requestKey);

      setIsOpen(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        notification.error({
          message: 'Error',
          description: error.response?.data.message || error.message,
          placement: 'bottom',
        });
      } else if (error instanceof Error) {
        notification.error({
          message: 'Error',
          description: error.message,
          placement: 'bottom',
        });
      }
      console.warn(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Popover
      title="Quick tag add"
      trigger="click"
      placement="right"
      open={isOpen}
      onOpenChange={setIsOpen}
      content={
        <Flex vertical gap={20}>
          <Form
            requiredMark={false}
            form={form}
            disabled={isSubmitting}
            onFinish={handleSubmit}
          >
            <Flex vertical gap={8}>
              <Typography.Text className={styles.description}>
                Create new tag filling the field or choose from existing.
              </Typography.Text>
              <Form.Item<QuickTagForm>
                name="tag"
                rules={[{ required: true, message: '' }]}
                style={{ margin: 0 }}
              >
                <AutoComplete
                  placeholder="Choose tags"
                  options={tags.map((el) => ({
                    label: el.name,
                    value: el.name,
                  }))}
                  filterOption={(inputValue, option) =>
                    option!.label
                      ?.toUpperCase()
                      ?.includes(inputValue.toUpperCase())
                  }
                  showSearch
                  allowClear
                  listHeight={160}
                />
              </Form.Item>
            </Flex>
          </Form>
          <Flex justify="space-between">
            <TransactionDialog transaction={transaction}>
              <Button disabled={isSubmitting} onClick={() => setIsOpen(false)}>
                Advanced mode
              </Button>
            </TransactionDialog>
            <Button
              type="primary"
              loading={isSubmitting}
              onClick={() => form.submit()}
            >
              Apply
            </Button>
          </Flex>
        </Flex>
      }
    >
      <Typography.Link>Add tags</Typography.Link>
    </Popover>
  );
}
