import { Form } from 'antd';
import { LogoFull } from 'assets/icons/LogoFull';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { walletService } from 'services/walletService';
import { getNetworkCodeByAddress } from 'utilities/wallets';
import styles from './OnboardingView.module.css';
import { AddWalletForm, AddWalletFormType } from './components/AddWalletForm';
import { Scanning } from './components/Scanning';
import { WalletsList } from './components/WalletsList';
import { useOnboardingStore } from './store';

export function OnboardingView() {
  const [form] = Form.useForm<AddWalletFormType>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isScanning, setWallets] = useOnboardingStore((state) => [
    !!state.scanningStatus,
    state.setWallets,
  ]);

  useEffect(() => {
    walletService.getWallets().then((wallets) => {
      setWallets(wallets);
    });
  }, []);

  if (isScanning) {
    return <Scanning />;
  }

  const handleSubmit = async (values: AddWalletFormType) => {
    try {
      setIsSubmitting(true);
      const network = getNetworkCodeByAddress(values.address);

      if (!network) return;

      await walletService.createWallet(values.name, values.address, network);
      form.resetFields();
    } catch (error) {
      if (error instanceof AxiosError) {
        form.setFields([
          {
            name: 'address',
            errors: [error.response?.data.message || 'Something went wrong'],
          },
        ]);
      } else if (error instanceof Error) {
        form.setFields([
          {
            name: 'address',
            errors: [error.message || 'Something went wrong'],
          },
        ]);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form<AddWalletFormType>
      form={form}
      validateTrigger={['onSubmit', 'onChange']}
      onFinish={handleSubmit}
      requiredMark={false}
    >
      <div className={styles.onboardingView}>
        <div className={styles.onboardingViewLogo}>
          <LogoFull height={21} />
        </div>
        <div className={styles.onboardingViewContent}>
          <AddWalletForm isSubmitting={isSubmitting} />
          <WalletsList />
        </div>
      </div>
    </Form>
  );
}
