import { App, Tag } from 'antd';
import { Copy } from 'assets/icons/CopyIcon';
import clsx from 'clsx';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { Transaction } from 'types';
import { formatAddress } from 'utilities/wallets';
import styles from './styles.module.css';

interface WalletCellProps {
  value: Transaction['source'];
  withCopy?: boolean;
}

export function WalletCell({ value, withCopy }: WalletCellProps) {
  const copy = useCopyToClipboard()[1];
  const { notification } = App.useApp();

  const handleCopy = () => {
    copy(value.address);
    notification.success({
      placement: 'bottom',
      message: 'Copied!',
      description: 'Wallet address copied to clipboard',
    });
  };

  if (value.counterparty_name && value.counterparty_wallet_name) {
    return (
      <div className={clsx(withCopy && styles.cellWithCopyButton)}>
        {withCopy && (
          <button className={styles.copyButton} onClick={handleCopy}>
            <Copy width={20} height={20} />
          </button>
        )}
        <Tag className={styles.tagContainer} title={value.counterparty_name}>
          <span className={styles.tagText}>{value.counterparty_name}</span>
          <Tag
            className={styles.tagItem}
            color="volcano"
            title={value.counterparty_wallet_name}
          >
            <span className={styles.tagText}>
              {value.counterparty_wallet_name}
            </span>
          </Tag>
        </Tag>
      </div>
    );
  }

  if (!!value?.counterparty_wallet_name && !!value?.user_wallet_name) {
    return (
      <div className={clsx(withCopy && styles.cellWithCopyButton)}>
        {withCopy && (
          <button className={styles.copyButton} onClick={handleCopy}>
            <Copy width={20} height={20} />
          </button>
        )}
        <Tag color="volcano" className={styles.tag}>
          <span className={styles.tagText}>
            {value?.counterparty_wallet_name} Lorem ipsum dolor sit amet
            consectetur, adipisicing elit. Facilis odit ducimus tempora ea
            consequuntur eos est nisi, natus eaque porro hic sequi, iusto sint
            quidem ut. Voluptate totam saepe sunt.
          </span>
        </Tag>
        <Tag color="volcano" className={styles.tag}>
          <span className={styles.tagText}>
            {value?.user_wallet_name} Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Officia quod repellat hic laboriosam magni
            pariatur neque iure magnam iusto nam vitae harum consequatur
            repellendus provident quis, expedita soluta reprehenderit beatae.
          </span>
        </Tag>
      </div>
    );
  }

  if (!!value?.user_wallet_name) {
    return (
      <div className={clsx(withCopy && styles.cellWithCopyButton)}>
        {withCopy && (
          <button className={styles.copyButton} onClick={handleCopy}>
            <Copy width={20} height={20} />
          </button>
        )}
        <Tag
          color="volcano"
          className={styles.tag}
          title={value.user_wallet_name}
        >
          <span className={styles.tagText}>{value?.user_wallet_name}</span>
        </Tag>
      </div>
    );
  }

  if (!!value?.counterparty_wallet_name) {
    return (
      <div className={clsx(withCopy && styles.cellWithCopyButton)}>
        {withCopy && (
          <button className={styles.copyButton} onClick={handleCopy}>
            <Copy width={20} height={20} />
          </button>
        )}
        <Tag
          color="volcano"
          className={styles.tag}
          title={value.counterparty_wallet_name}
        >
          <span className={styles.tagText}>
            {value?.counterparty_wallet_name}
          </span>
        </Tag>
      </div>
    );
  }

  if (!!value?.counterparty_name) {
    return (
      <div className={clsx(withCopy && styles.cellWithCopyButton)}>
        {withCopy && (
          <button className={styles.copyButton} onClick={handleCopy}>
            <Copy width={20} height={20} />
          </button>
        )}
        <Tag className={styles.tagContainer} title={value.counterparty_name}>
          <span className={styles.tagText}>{value.counterparty_name}</span>
          <Tag className={styles.tagItem} color="volcano">
            <span className={styles.tagText}>-</span>
          </Tag>
        </Tag>
      </div>
    );
  }

  return (
    <div className={clsx(withCopy && styles.cellWithCopyButton)}>
      {withCopy && (
        <button className={styles.copyButton} onClick={handleCopy}>
          <Copy width={20} height={20} />
        </button>
      )}
      {formatAddress(value.address ?? '', 8, 8)}
    </div>
  );
}
