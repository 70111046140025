import WAValidator from 'multicoin-address-validator';
import { settingsStore } from 'store/settings';

export const formatAddress = (
  address: string,
  firstChars: number = 9,
  lastChars: number = 9
): string => {
  return `${address.slice(0, firstChars)}...${address.slice(-lastChars)}`;
};

export const getNetworkType = (network: string): string => {
  switch (network) {
    case 'tron':
      return 'Tron';

    case 'eth':
      return 'EVM';

    default:
      return '';
  }
};

/**
 * Validate address for all networks from settings store
 * @param address
 */
export const isAddressValid = (address: string): boolean => {
  if (address?.length === 0) return false;
  let res = false;

  const { settings } = settingsStore.getState();

  for (const network of settings.networks) {
    const isValid = WAValidator.validate(address, network.code);
    if (isValid) {
      res = true;
      break;
    }
  }

  return res;
};

/**
 * Get network type by address
 * @param address
 */
export const getNetworkCodeByAddress = (address: string) => {
  if (address?.length === 0) {
    throw new Error('Address is required');
  }
  let res;

  const { settings } = settingsStore.getState();

  for (const network of settings.networks) {
    const isValid = WAValidator.validate(address, network.code);
    if (isValid) {
      res = network.code;
      break;
    }
  }

  return res;
};
