import { STAGE } from 'configs';
import { Stages } from 'types';

interface ApiConfig {
  baseUrl: string;
}

export const apiConfig: Record<Stages, ApiConfig> = {
  development: {
    baseUrl: 'https://app.finflo.io/api',
  },
  production: {
    baseUrl: 'https://app.finflo.io/api',
  },
};

export const API_URL = apiConfig[STAGE].baseUrl;
