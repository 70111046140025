import { SVGAttributes } from "react";

export function SuccessIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      {...props}
    >
      <path
        d="M50 30.75C39.3695 30.75 30.75 39.3695 30.75 50C30.75 60.6305 39.3695 69.25 50 69.25C60.6305 69.25 69.25 60.6305 69.25 50C69.25 39.3695 60.6305 30.75 50 30.75ZM50 65.9844C41.1742 65.9844 34.0156 58.8258 34.0156 50C34.0156 41.1742 41.1742 34.0156 50 34.0156C58.8258 34.0156 65.9844 41.1742 65.9844 50C65.9844 58.8258 58.8258 65.9844 50 65.9844Z"
        fill="#1677FF"
      />
      <path
        d="M50 34.0156C41.1742 34.0156 34.0156 41.1742 34.0156 50C34.0156 58.8258 41.1742 65.9844 50 65.9844C58.8258 65.9844 65.9844 58.8258 65.9844 50C65.9844 41.1742 58.8258 34.0156 50 34.0156ZM58.3102 43.7137L49.2609 56.2605C49.1345 56.4371 48.9677 56.5809 48.7746 56.6801C48.5814 56.7794 48.3674 56.8311 48.1502 56.8311C47.933 56.8311 47.719 56.7794 47.5258 56.6801C47.3327 56.5809 47.1659 56.4371 47.0395 56.2605L41.6855 48.8355C41.5223 48.6078 41.6855 48.2898 41.9648 48.2898H43.9801C44.4227 48.2898 44.8352 48.5047 45.093 48.8613L48.1523 53.1066L54.907 43.7395C55.1648 43.3785 55.5816 43.168 56.0199 43.168H58.0352C58.3145 43.168 58.4777 43.4859 58.3102 43.7137V43.7137Z"
        fill="#E6F4FF"
      />
      <path
        d="M58.0352 43.168H56.02C55.5817 43.168 55.1649 43.3785 54.9071 43.7395L48.1524 53.1066L45.093 48.8613C44.8352 48.5047 44.4227 48.2898 43.9801 48.2898H41.9649C41.6856 48.2898 41.5223 48.6078 41.6856 48.8355L47.0395 56.2605C47.166 56.4371 47.3327 56.5809 47.5259 56.6801C47.7191 56.7794 47.9331 56.8311 48.1503 56.8311C48.3674 56.8311 48.5815 56.7794 48.7746 56.6801C48.9678 56.5809 49.1345 56.4371 49.261 56.2605L58.3102 43.7137C58.4778 43.4859 58.3145 43.168 58.0352 43.168V43.168Z"
        fill="#1677FF"
      />
    </svg>
  );
}
