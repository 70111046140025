import { Flex, Table, Tag } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { Transaction } from 'types';
import styles from './styles.module.css';
import { formatUSDValue } from 'utilities/number';
import { tagsStore } from 'store/tags';

interface ExpandedRowProps {
  transaction: Transaction;
}

const columns: ColumnProps<Transaction['payments'][0]>[] = [
  {
    title: 'Payment tag',
    dataIndex: 'tag_id',
    key: 'id',
    render: (tag: Transaction['payments'][0]['tag_id']) => {
      const { tags } = tagsStore.getState();
      return <Tag>{tags.find((el) => el.id === tag)?.name}</Tag>;
    },
  },
  {
    title: 'Payment amount',
    key: 'usd_amount',
    dataIndex: 'usd_amount',
    width: 151,
    align: 'right',
    render: (amount: number) => (
      <span dangerouslySetInnerHTML={{ __html: formatUSDValue(amount, '') }} />
    ),
  },
  {
    width: 232,
  },
];

export function ExpandedRow({ transaction }: ExpandedRowProps) {
  return (
    <>
      {!!transaction.comment && (
        <Flex className={styles.transactionDescription}>
          <p>{transaction.comment}</p>
        </Flex>
      )}
      <Flex vertical style={{ marginLeft: 138 }}>
        <Table
          columns={columns}
          dataSource={transaction?.payments.map((payment) => ({
            ...payment,
            key: payment.id,
          }))}
          pagination={false}
        />
      </Flex>
    </>
  );
}
