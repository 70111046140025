import { tronWeb } from 'services/tronWebAdapter';
import { wait } from 'utilities/date';

export const TRON_WAIT_TX_RETRIES = 10;
export const TRON_WAIT_TX_DELAY_MS = 3000;

export async function waitForTransaction(txID: string) {
  let transaction;

  for (let i = 0; i < TRON_WAIT_TX_RETRIES; i++) {
    try {
      console.log(`[TRON]: Getting transaction ${txID} try ${i}`);

      transaction = await tronWeb.trx.getTransaction(txID);
      break;
    } catch (getTxErr) {
      console.log(`[TRON]: Get transaction error on try ${i}:`, getTxErr);
      await wait(TRON_WAIT_TX_DELAY_MS);
    }
  }

  if (!transaction) {
    throw new Error('Failed to get transaction');
  }

  if (transaction.ret[0].contractRet.toLowerCase() !== 'success') {
    throw new Error('Failed to send transaction');
  }

  await _waitCallback(() => tronWeb.trx.getConfirmedTransaction(txID));

  return transaction
}

async function _waitCallback<T>(callback: () => Promise<T>) {
  let confirmed = false;
  while (!confirmed) {
    try {
      const txConfirmed = await callback();
      confirmed = true;
      return txConfirmed;
    } catch (error) {
      await wait(TRON_WAIT_TX_DELAY_MS);
    }
  }
}
