import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Typography } from 'antd';
import { SubscriptionDialog } from 'components/SubscriptionDialog';
import { useState } from 'react';
import { useUserStore } from 'store/user';
import { useWalletsStore } from 'store/wallets';
import { AddWalletDialog } from '../AddWalletDialog';
import { WalletItem } from './WalletItem';
import styles from './styles.module.css';

export function WalletsList() {
  const [isAddWalletDialogOpen, setAddWalletDialogOpen] = useState(false);
  const [isSubscribeDialogOpen, setIsSubscribeDialogOpen] = useState(false);
  const [wallets] = useWalletsStore((s) => [s.wallets]);
  const canAddNewWallet = useUserStore((s) => s.user?.can_add_new_wallet);
  const userPlan = useUserStore((s) => s.user?.current_plan);

  return (
    <div>
      <Flex vertical gap={20}>
        <Flex vertical gap={4}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Wallets
          </Typography.Title>
          <Typography.Text>
            List of wallets tracked for accounting management.
          </Typography.Text>
        </Flex>
        <Flex vertical gap={8}>
          {wallets.length <= 0 && (
            <div className={styles.walletListPlaceholder}>No wallets added</div>
          )}
          {wallets.length > 0 &&
            userPlan &&
            wallets.map((wallet, i) => (
              <WalletItem
                key={wallet.wallet.address}
                wallet={wallet}
                isDisabled={!wallet.active}
              />
            ))}
          {canAddNewWallet && (
            <Button
              block
              size="large"
              icon={<PlusOutlined />}
              onClick={() => {
                setAddWalletDialogOpen(true);
              }}
            >
              Add wallet
            </Button>
          )}

          {!canAddNewWallet && (
            <Button
              block
              type="primary"
              size="large"
              onClick={() => {
                setIsSubscribeDialogOpen(true);
              }}
            >
              Manage Subscription
            </Button>
          )}

          <AddWalletDialog
            isOpen={isAddWalletDialogOpen}
            onOpenChange={(val) => setAddWalletDialogOpen(val)}
          />
          <SubscriptionDialog
            isOpen={isSubscribeDialogOpen}
            onOpenChange={setIsSubscribeDialogOpen}
          />
        </Flex>
      </Flex>
    </div>
  );
}
