import { SVGAttributes } from "react";

export function SpinnerIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_2_7346)">
        <path
          d="M50 72C47.0309 72 44.1477 71.4199 41.4363 70.2727C38.8152 69.1641 36.4648 67.5742 34.4453 65.5547C32.4258 63.5352 30.8359 61.1848 29.7273 58.5637C28.5801 55.8523 28 52.9691 28 50C28 49.1449 28.6918 48.4531 29.5469 48.4531C30.402 48.4531 31.0938 49.1449 31.0938 50C31.0938 52.5523 31.5922 55.0273 32.5805 57.3605C33.5344 59.6121 34.8965 61.6359 36.6324 63.3719C38.3684 65.1078 40.3922 66.4742 42.6437 67.4238C44.9727 68.4078 47.4477 68.9062 50 68.9062C52.5523 68.9062 55.0273 68.4078 57.3605 67.4195C59.6121 66.4656 61.6359 65.1035 63.3719 63.3676C65.1078 61.6316 66.4742 59.6078 67.4238 57.3563C68.4078 55.0273 68.9062 52.5523 68.9062 50C68.9062 47.4477 68.4078 44.9727 67.4195 42.6395C66.4689 40.3933 65.093 38.3521 63.3676 36.6281C61.6455 34.9004 59.6038 33.5242 57.3563 32.5762C55.0273 31.5922 52.5523 31.0938 50 31.0938C49.1449 31.0938 48.4531 30.402 48.4531 29.5469C48.4531 28.6918 49.1449 28 50 28C52.9691 28 55.8523 28.5801 58.5637 29.7273C61.1848 30.8359 63.5352 32.4258 65.5547 34.4453C67.5742 36.4648 69.1598 38.8195 70.2684 41.4363C71.4156 44.1477 71.9957 47.0309 71.9957 50C71.9957 52.9691 71.4156 55.8523 70.2684 58.5637C69.1641 61.1848 67.5742 63.5352 65.5547 65.5547C63.5352 67.5742 61.1805 69.1598 58.5637 70.2684C55.8523 71.4199 52.9691 72 50 72V72Z"
          fill="black"
          fillOpacity="0.45"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_7346">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(28 28)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
