import { Button, Card, Flex, Tag, Typography } from 'antd';
import clsx from 'clsx';
import ethereum from 'cryptocurrency-icons/32@2x/color/eth@2x.png';
import tron from 'cryptocurrency-icons/32@2x/color/trx@2x.png';
import { useState } from 'react';
import { Wallet } from 'types/wallet';
import { AddWalletDialog } from '../AddWalletDialog';
import { DeleteWalletDialog } from '../DeleteWalletDialog';
import styles from './styles.module.css';

interface WalletItemProps {
  wallet: Wallet;
  isDisabled?: boolean;
}

export function WalletItem({ wallet, isDisabled }: WalletItemProps) {
  const [isEditWalletDialogOpen, setEditWalletDialogOpen] = useState(false);
  const [isDeleteWalletDialogOpen, setDeleteWalletDialogOpen] = useState(false);

  return (
    <Card
      className={clsx(styles.walletListItem, isDisabled && styles.disabled)}
      classNames={{
        body: styles.walletListItemBody,
      }}
    >
      <Flex align="center">
        {wallet.name && (
          <Tag className={styles.walletListItemName}>{wallet.name}</Tag>
        )}
        {wallet.wallet.network === 'eth' && (
          <Typography.Text className={styles.walletListItemNetwork}>
            <img src={ethereum} alt="Ethereum" />
            Ethereum
          </Typography.Text>
        )}
        {wallet.wallet.network === 'tron' && (
          <Typography.Text className={styles.walletListItemNetwork}>
            <img src={tron} alt="Tron" />
            Tron
          </Typography.Text>
        )}

        <Flex gap={20} className={styles.walletListItemActions}>
          <Button
            type="link"
            size="small"
            className={styles.walletListItemAction}
            onClick={() => {
              !isDisabled && setEditWalletDialogOpen(true);
            }}
            disabled={isDisabled}
          >
            Edit
          </Button>
          <Button
            type="link"
            size="small"
            className={styles.walletListItemAction}
            onClick={() => setDeleteWalletDialogOpen(true)}
            danger
          >
            Delete
          </Button>
        </Flex>
        <AddWalletDialog
          isOpen={isEditWalletDialogOpen}
          onOpenChange={(val) => setEditWalletDialogOpen(val)}
          wallet={wallet}
        />
        <DeleteWalletDialog
          isOpen={isDeleteWalletDialogOpen}
          onOpenChange={(val) => setDeleteWalletDialogOpen(val)}
          wallet={wallet}
        />
      </Flex>
      <Typography.Paragraph className={styles.walletListItemText}>
        {wallet.wallet.address}
      </Typography.Paragraph>
    </Card>
  );
}
