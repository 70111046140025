import { Settings } from 'types/settings';
import { create } from 'zustand';

interface SettingsStore {
  settings: Settings;
  setSettings: (settings: Settings) => void;

  shouldOpenWelcomeDialog: boolean;
  setShouldOpenWelcomeDialog: (shouldOpenWelcomeDialog: boolean) => void;
}

export const settingsStore = create<SettingsStore>((set) => ({
  settings: {
    networks: [],
    chains: [],
  },
  setSettings: (settings) => set({ settings }),

  shouldOpenWelcomeDialog: false,
  setShouldOpenWelcomeDialog: (shouldOpenWelcomeDialog) =>
    set({ shouldOpenWelcomeDialog }),
}));

export const useSettingsStore = settingsStore;
