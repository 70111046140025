import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  InputNumber,
  Modal,
  Row,
  Tag,
  Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { ReactElement, cloneElement, useEffect, useState } from 'react';
import { useTransactionsFiltersStore } from 'screens/Transactions/store';
import { transactionService } from 'services/transactionService';
import { useTagsStore } from 'store/tags';
import { useSWRConfig } from 'swr';
import { PaymentCreateRequest, Transaction } from 'types';
import { toFixed } from 'utilities/number';
import { TagSelect } from './TagSelect';
import styles from './styles.module.css';

interface TransactionDialogProps {
  children?: ReactElement;
  transaction: Transaction;
}

interface TransactionFormSchema {
  comment: string;
  amount?: number;
  tag?: string;
  payments: PaymentCreateRequest[];
}

export function TransactionDialog({
  children,
  transaction,
}: TransactionDialogProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const tags = useTagsStore((s) => s.tags);
  const [shouldHighlight, setShouldHighlight] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [form] = Form.useForm<TransactionFormSchema>();
  const [isOpen, setIsOpen] = useState(false);
  const requestKey = useTransactionsFiltersStore((state) => state.requestKey);

  const isEdit = transaction.payments.length > 0 || !!transaction.comment;

  const { mutate } = useSWRConfig();

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        comment: transaction.comment ?? '',
        amount: undefined,
        tag: undefined,
        payments: transaction.payments,
      });
    }
  }, [isOpen]);

  const handleSave = async () => {
    try {
      setError(null);
      setIsSubmitting(true);
      if (!form.getFieldValue('payments')?.length) {
        form.validateFields(['amount', 'tag']);
        throw new Error('Add at least one payment.');
      }

      if (isEdit) {
        await transactionService.updatePayment(
          transaction,
          form.getFieldValue('comment'),
          form.getFieldValue('payments')
        );
      } else {
        await transactionService.createPayment(
          transaction,
          form.getFieldValue('comment'),
          form.getFieldValue('payments')
        );
      }

      await mutate(requestKey);

      setIsOpen(false);
    } catch (error: any) {
      if (error instanceof AxiosError) {
        setError(error.response?.data.message ?? error.message);
      } else if (error instanceof Error) {
        setError(error.message);
      }
      console.warn(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNewPayment = async () => {
    setError(null);
    try {
      const values = await form.validateFields(['amount', 'tag']);

      if (values.amount && toFixed(values.amount, 2) && values.tag) {
        const tag = tags.find((tag) => tag.name === values.tag);

        form.setFieldValue('payments', [
          ...(form.getFieldValue('payments') || []),
          {
            usd_amount: toFixed(values.amount, 2),
            [!tag || !tag.id ? 'tag_name' : 'tag_id']:
              !tag || !tag.id ? values.tag : tag?.id,
          },
        ]);

        form.resetFields(['amount', 'tag']);
      }
    } catch (error: any) {}
  };

  return (
    <>
      {children &&
        cloneElement(children, {
          onClick: () => {
            children.props?.onClick?.();
            setIsOpen(true);
          },
        })}
      <Modal
        open={isOpen}
        afterOpenChange={(open) => {
          if (!open) {
            form.resetFields();
            setError(null);
            setShouldHighlight(false);
          }
        }}
        title={
          isEdit ? 'Edit transaction breakdown' : 'Add transaction breakdown'
        }
        centered
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        okText="Save"
        classNames={{
          footer: styles.transactionFooter,
        }}
        footer={[
          error && (
            <Typography.Text type="danger" key="error">
              {error}
            </Typography.Text>
          ),
          <Button
            key="cancel"
            onClick={() => setIsOpen(false)}
            style={{
              marginLeft: 'auto',
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handleSave()}
            loading={isSubmitting}
          >
            Save
          </Button>,
        ]}
      >
        <Form<TransactionFormSchema>
          validateTrigger={['onChange', 'onSubmit']}
          form={form}
          labelCol={{ span: 24 }}
          requiredMark={false}
        >
          <Flex vertical gap={32}>
            <Flex gap={20} vertical>
              <Typography.Title
                level={3}
                style={{ marginTop: 16, marginBottom: 0 }}
              >
                Transaction
              </Typography.Title>

              <Form.Item<TransactionFormSchema>
                name="comment"
                className={styles.transactionFormItem}
                hasFeedback={{
                  icons: () => ({
                    success: ' ',
                  }),
                }}
                label="Comment"
              >
                <TextArea rows={2} placeholder="Left a comment (optional)" />
              </Form.Item>
            </Flex>
            <Flex vertical gap={16}>
              <Typography.Title level={3} style={{ margin: 0 }}>
                Payments
              </Typography.Title>
              <Typography.Text strong>Set up payment</Typography.Text>
              <Form.Item<TransactionFormSchema>
                name="amount"
                className={styles.transactionFormItem}
                hasFeedback={{
                  icons: () => ({
                    success: ' ',
                  }),
                }}
                label={
                  <>
                    Amount <span className={styles.danger}>*</span>
                  </>
                }
                required
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                  {
                    validator: (_, value) => {
                      if (toFixed(value, 2) <= 0) {
                        return Promise.reject();
                      }
                      if (toFixed(value, 2) !== value) {
                        return Promise.reject();
                      }
                      const paymentsSum =
                        (
                          form.getFieldValue(
                            'payments'
                          ) as TransactionFormSchema['payments']
                        )?.reduce(
                          (acc, payment) => acc + payment.usd_amount,
                          0
                        ) ?? 0;

                      if (
                        value > toFixed(+transaction.amount - paymentsSum, 2)
                      ) {
                        setShouldHighlight(true);
                        setError('You trying to add incorrect amount.');
                        return Promise.reject();
                      }
                      setShouldHighlight(false);
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  prefix={transaction?.currency?.symbol?.toUpperCase()}
                  onChange={() => setError(null)}
                  step={0.01}
                />
              </Form.Item>
              <Form.Item<TransactionFormSchema>
                name="tag"
                className={styles.transactionFormItem}
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.tag !== currentValues.tag
                }
                hasFeedback={{
                  icons: () => ({
                    success: ' ',
                  }),
                }}
                label={
                  <>
                    Tag <span className={styles.danger}>*</span>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <TagSelect onChange={(e) => form.setFieldValue('tag', e)} />
              </Form.Item>
              <Button
                block
                size="large"
                icon={<PlusOutlined />}
                onClick={handleNewPayment}
              >
                Add payment
              </Button>
            </Flex>
            <Flex vertical gap={16}>
              <Typography.Text strong>Payments list</Typography.Text>
              <Flex
                vertical
                gap={16}
                className={styles.transactionCardViewport}
              >
                <Form.Item<TransactionFormSchema>
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.payments !== currentValues.payments
                  }
                >
                  {({ getFieldValue, setFieldValue }) => {
                    return getFieldValue('payments')?.map(
                      (
                        payment: TransactionFormSchema['payments'][0],
                        i: number
                      ) => (
                        <Card
                          key={i}
                          classNames={{ body: styles.transactionCard }}
                        >
                          <Row gutter={16}>
                            <Col span={12}>
                              <Flex gap={4} vertical>
                                <Typography.Text
                                  className={styles.transactionCardLabel}
                                >
                                  Amount
                                </Typography.Text>
                                <Typography.Text>
                                  {payment.usd_amount}
                                </Typography.Text>
                              </Flex>
                            </Col>
                            <Col span={12}>
                              <Flex gap={4} vertical>
                                <Flex
                                  justify="space-between"
                                  style={{ width: '100%' }}
                                >
                                  <Typography.Text
                                    className={styles.transactionCardLabel}
                                  >
                                    Tag
                                  </Typography.Text>
                                  <Button
                                    type="link"
                                    danger
                                    className={styles.transactionCardLabel}
                                    onClick={() => {
                                      const payments = getFieldValue(
                                        'payments'
                                      ) as TransactionFormSchema['payments'];

                                      const newPayments = payments.filter(
                                        (_, idx) => idx !== i
                                      );

                                      setFieldValue('payments', newPayments);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Flex>
                                <Typography.Text>
                                  <Tag>
                                    {
                                      tags.find(
                                        (tag) =>
                                          tag.id === payment.tag_id ||
                                          tag.name === payment.tag_name
                                      )?.name
                                    }
                                  </Tag>
                                </Typography.Text>
                              </Flex>
                            </Col>
                          </Row>
                        </Card>
                      )
                    );
                  }}
                </Form.Item>
                <Form.Item<TransactionFormSchema>
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    JSON.stringify(prevValues.payments) !==
                    JSON.stringify(currentValues.payments)
                  }
                >
                  {({ getFieldValue }) => {
                    const payments = getFieldValue(
                      'payments'
                    ) as TransactionFormSchema['payments'];

                    const total = toFixed(
                      payments?.reduce(
                        (acc, payment) => acc + +payment.usd_amount,
                        0
                      ),
                      2
                    );

                    return (
                      <Flex vertical style={{ paddingLeft: 12 }}>
                        <Typography.Text
                          strong
                          className={styles.transactionCardLabel}
                        >
                          Total
                        </Typography.Text>
                        <Typography.Text
                          strong
                          className={clsx(shouldHighlight && styles.danger)}
                        >
                          {total ?? 0} / {transaction.amount}
                        </Typography.Text>
                      </Flex>
                    );
                  }}
                </Form.Item>
              </Flex>
            </Flex>
          </Flex>
        </Form>
      </Modal>
    </>
  );
}
