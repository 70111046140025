import { Flex } from 'antd';
import { BalancesChart } from './components/Chart';
import { WalletsGrid } from './components/WalletsGrid';

export function BalancesView() {
  return (
    <Flex gap={60} vertical>
      <BalancesChart />
      <WalletsGrid />
    </Flex>
  );
}
