import { App, Checkbox, Flex, Modal, Typography } from 'antd';
import { AxiosError } from 'axios';
import { Routes } from 'constants/routs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userService } from 'services/userService';

interface DeleteAccountDialogProps {
  isOpen: boolean;
  onOpenChange: (val: boolean) => void;
}

export function DeleteAccountDialog({
  isOpen,
  onOpenChange,
}: DeleteAccountDialogProps) {
  const { notification } = App.useApp();
  const [isUserUnderstand, setIsUserUnderstand] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      await userService.deleteUser();

      handleOpenChange(false);
      navigate(Routes.SignUp);
      notification.open({
        message: 'Account deleted',
        description: 'We are sorry to see you go :(',
        placement: 'bottom',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        notification.error({
          message: 'Delete account',
          description: error.response?.data.message || error.message,
          placement: 'bottom',
        });
      } else if (error instanceof Error) {
        notification.error({
          message: 'Delete account',
          description: error.message,
          placement: 'bottom',
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenChange = (val: boolean) => {
    onOpenChange(val);

    if (val) return;
    setIsUserUnderstand(false);
    setIsSubmitting(false);
  };
  return (
    <Modal
      title="Delete account"
      open={isOpen}
      centered
      afterOpenChange={handleOpenChange}
      onOk={handleDelete}
      onCancel={() => handleOpenChange(false)}
      okText="Delete anyway"
      okButtonProps={{
        danger: true,
        loading: isSubmitting,
        disabled: !isUserUnderstand,
      }}
    >
      <Flex vertical gap={20} style={{ margin: '24px 0' }}>
        <Typography.Text>
          If you delete your account, all transactions and tags for all wallets
          added to FinFlo will be erased forever.
        </Typography.Text>
        <Typography.Text>This data can not be restored.</Typography.Text>
        <Checkbox
          checked={isUserUnderstand}
          onChange={(e) => setIsUserUnderstand(e.target.checked)}
        >
          I understand consequences
        </Checkbox>
      </Flex>
    </Modal>
  );
}
