import { Button, Flex, Typography } from 'antd';
import { SubscriptionDialog } from 'components/SubscriptionDialog';
import { useState } from 'react';
import { useUserStore } from 'store/user';
import { useWalletsStore } from 'store/wallets';
import { UserPlans } from 'types/user';
import styles from './styles.module.css';

export function TrialBanner() {
  const [isSubscribeDialogOpen, setIsSubscribeDialogOpen] = useState(false);
  const user = useUserStore((s) => s.user);
  const wallets = useWalletsStore((s) => s.wallets);

  if (user?.current_plan !== UserPlans.Free) return null;

  return (
    <Flex justify="center" align="center" gap={3} className={styles.container}>
      <Typography.Text className={styles.text}>
        You already added {wallets.length || 1} wallet{wallets.length > 1 && 's'}.
      </Typography.Text>
      <Button type="link" onClick={() => setIsSubscribeDialogOpen(true)}>
        Get more wallets
      </Button>
      <SubscriptionDialog
        isOpen={isSubscribeDialogOpen}
        onOpenChange={setIsSubscribeDialogOpen}
      />
    </Flex>
  );
}
