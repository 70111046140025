import { create } from 'zustand';
import { MultisenderForm } from '../form';
import { Multisend } from 'types/multisender';

export enum MultisenderTabs {
  Builder = 'Builder',
  History = 'History',
  Templates = 'Templates',
}

export enum BuilderViewVariant {
  Default = 'default',
  Template = 'template',
}

export interface MultiSenderStore {
  tab: MultisenderTabs;
  setTab(tab: MultisenderTabs): void;

  initialBuilderValues?: MultisenderForm & { id: Multisend['multisend']['id'] };
  setInitialBuilderValues(
    values?: MultiSenderStore['initialBuilderValues']
  ): void;

  builderViewVariant?: BuilderViewVariant;
  setBuilderViewVariant(variant?: BuilderViewVariant): void;
}

export const multisenderStore = create<MultiSenderStore>((set) => ({
  tab: MultisenderTabs.Builder,
  setTab: (tab) => set({ tab }),

  initialBuilderValues: undefined,
  setInitialBuilderValues: (values) => set({ initialBuilderValues: values }),

  builderViewVariant: BuilderViewVariant.Default,
  setBuilderViewVariant: (variant) => set({ builderViewVariant: variant }),
}));

export const useMultisenderStore = multisenderStore;
