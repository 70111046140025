import {
  CheckOutlined,
  CopyFilled,
  CopyTwoTone,
  CrownTwoTone,
  StarTwoTone,
  ThunderboltTwoTone,
} from '@ant-design/icons';
import { App, Button, Card, Flex, Modal, Radio, Tag, Typography } from 'antd';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { SUPPORT_EMAIL, TELEGRAM } from 'constants/contacts';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useState } from 'react';
import { userService } from 'services/userService';
import { useUserStore } from 'store/user';
import { SubscriptionPeriod, UserPlans } from 'types/user';
import styles from './styles.module.css';

export enum SubscriptionDialogVariant {
  Default = 'default',
  Welcome = 'welcome',
}

interface SubscriptionDialogProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  variant?: SubscriptionDialogVariant;
}

const DEFAULT_SUBSCRIPTION_PERIOD = SubscriptionPeriod.Month;

export function SubscriptionDialog({
  isOpen,
  onOpenChange,
  variant = SubscriptionDialogVariant.Default,
}: SubscriptionDialogProps) {
  const user = useUserStore((s) => s.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<UserPlans>(
    user?.current_plan || UserPlans.Free
  );
  const [selectedPeriod, setSelectedPeriod] = useState<SubscriptionPeriod>(
    DEFAULT_SUBSCRIPTION_PERIOD
  );
  const { notification } = App.useApp();
  const [copiedText, copy] = useCopyToClipboard(3000);
  const [copiedFooterText, copyInFooter] = useCopyToClipboard(3000);

  const handleOpenChange = (isOpen: boolean) => {
    onOpenChange(isOpen);
    if (!isOpen) {
      setSelectedPeriod(DEFAULT_SUBSCRIPTION_PERIOD);
      setSelectedPlan(user?.current_plan || UserPlans.Free);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const res = await userService.createPayLink(selectedPlan, selectedPeriod);

      window.open(res, '_self');
    } catch (error) {
      if (error instanceof AxiosError) {
        notification.error({
          message: 'Subscribe',
          description: error.response?.data.message || error.message,
          placement: 'bottom',
        });
      } else if (error instanceof Error) {
        notification.error({
          message: 'Subscribe',
          description: error.message,
          placement: 'bottom',
        });
      }
      console.warn(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title={
        <div className={styles.welcomeHeader}>
          <Flex vertical gap={16}>
            {variant === SubscriptionDialogVariant.Welcome && (
              <Typography.Title level={3} style={{ margin: 0 }}>
                Welcome to FinFLo!
              </Typography.Title>
            )}
            <Typography.Title level={1} style={{ margin: 0 }}>
              Enhance your business efficiency!
            </Typography.Title>
          </Flex>
        </div>
      }
      open={isOpen}
      centered
      afterOpenChange={handleOpenChange}
      className={styles.dialog}
      onCancel={() => handleOpenChange(false)}
      classNames={{
        footer: styles.dialogFooter,
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => handleOpenChange(false)}
          style={{
            marginRight: 'auto',
          }}
        >
          Cancel
        </Button>,
        selectedPlan === UserPlans.Free && (
          <Button
            key="submitFree"
            type="primary"
            onClick={() => handleOpenChange(false)}
            loading={isSubmitting}
            disabled={!selectedPeriod}
          >
            Continue with Free
          </Button>
        ),
        selectedPlan === UserPlans.Pro && (
          <Button
            key="submitPro"
            type="primary"
            onClick={() => handleSubmit()}
            loading={isSubmitting}
            disabled={!selectedPeriod}
          >
            Upgrade now!
          </Button>
        ),
        selectedPlan === UserPlans.Enterprise && (
          <Flex gap={15}>
            <Button
              key="contactTG"
              type="primary"
              target="_blank"
              href={TELEGRAM}
              loading={isSubmitting}
              disabled={!selectedPeriod}
            >
              Contact via Telegram
            </Button>
            <Flex gap={5}>
              <Button
                key="contactEmail"
                type="primary"
                target="_blank"
                href={`mailto:${SUPPORT_EMAIL}`}
                loading={isSubmitting}
                disabled={!selectedPeriod}
              >
                Send email
              </Button>
              <Button
                key="copyEmail"
                type="primary"
                loading={isSubmitting}
                disabled={!selectedPeriod}
                icon={
                  copiedFooterText === SUPPORT_EMAIL ? (
                    <CheckOutlined />
                  ) : (
                    <CopyFilled />
                  )
                }
                onClick={() => copyInFooter(SUPPORT_EMAIL)}
              />
            </Flex>
          </Flex>
        ),
      ]}
    >
      <Flex vertical gap={16}>
        <Typography.Text className={styles.dialogText}>
          To continue enjoying uninterrupted service, consider upgrading to one
          of our flexible paid plans. Upgrade now to make the most of your
          FinFlo experience!
        </Typography.Text>
        <Card
          onClick={() => setSelectedPlan(UserPlans.Free)}
          className={clsx(
            styles.subscriptionPlanCard,
            selectedPlan === UserPlans.Free && styles.active
          )}
          classNames={{ body: styles.subscriptionPlanCardBody }}
        >
          <Flex vertical gap={10}>
            <Flex gap={20} align="center">
              <Typography.Title
                level={3}
                className={styles.subscriptionPlanCardTitle}
              >
                <StarTwoTone /> Basic
              </Typography.Title>
              <div className={styles.subscriptionPlanTag}>1 wallet</div>
              {user?.current_plan === UserPlans.Free && (
                <Typography.Text className={styles.subscriptionPlanCurrent}>
                  Current
                </Typography.Text>
              )}
            </Flex>
            <Typography.Text className={styles.subscriptionPlanCardDescription}>
              Free, forever
            </Typography.Text>
          </Flex>
        </Card>
        <Flex justify="center">
          <Radio.Group
            value={selectedPeriod}
            onChange={(e) => setSelectedPeriod(e.target.value)}
            optionType="button"
            buttonStyle="solid"
          >
            <Radio.Button
              className={styles.subscriptionPeriodButton}
              value={SubscriptionPeriod.Month}
            >
              1 month
            </Radio.Button>
            <Radio.Button
              className={styles.subscriptionPeriodButton}
              value={SubscriptionPeriod.HalfYear}
            >
              <Flex gap={4} align="center">
                6 month{' '}
                <Tag color="volcano" style={{ margin: 0 }}>
                  -20%
                </Tag>
              </Flex>
            </Radio.Button>
            <Radio.Button
              className={styles.subscriptionPeriodButton}
              value={SubscriptionPeriod.Year}
            >
              <Flex gap={4} align="center">
                1 year{' '}
                <Tag color="volcano" style={{ margin: 0 }}>
                  -40%
                </Tag>
              </Flex>
            </Radio.Button>
          </Radio.Group>
        </Flex>
        <Card
          onClick={() => setSelectedPlan(UserPlans.Pro)}
          className={clsx(
            styles.subscriptionPlanCard,
            selectedPlan === UserPlans.Pro && styles.active
          )}
          classNames={{ body: styles.subscriptionPlanCardBody }}
        >
          <Flex vertical gap={10}>
            <Flex gap={20} align="center">
              <Typography.Title
                level={3}
                className={styles.subscriptionPlanCardTitle}
              >
                <CrownTwoTone />
                Pro
              </Typography.Title>
              <div className={styles.subscriptionPlanTag}>up to 5 wallets</div>
              {user?.current_plan === UserPlans.Pro && (
                <Typography.Text className={styles.subscriptionPlanCurrent}>
                  Current
                </Typography.Text>
              )}
            </Flex>
            <Typography.Text className={styles.subscriptionPlanCardDescription}>
              {selectedPeriod === SubscriptionPeriod.Month && '99 USDT'}
              {selectedPeriod === SubscriptionPeriod.HalfYear && '529 USDT'}
              {selectedPeriod === SubscriptionPeriod.Year && '945 USDT'}
            </Typography.Text>
          </Flex>
        </Card>
        <Card
          onClick={() => setSelectedPlan(UserPlans.Enterprise)}
          className={clsx(
            styles.subscriptionPlanCard,
            selectedPlan === UserPlans.Enterprise && styles.active
          )}
          classNames={{ body: styles.subscriptionPlanCardBody }}
        >
          <Flex vertical gap={10}>
            <Flex gap={20} align="center">
              <Typography.Title
                level={3}
                className={styles.subscriptionPlanCardTitle}
              >
                <ThunderboltTwoTone />
                Enterprise
              </Typography.Title>
              <div className={styles.subscriptionPlanTag}>5+ wallets</div>
              {user?.current_plan === UserPlans.Enterprise && (
                <Typography.Text className={styles.subscriptionPlanCurrent}>
                  Current
                </Typography.Text>
              )}
            </Flex>
            <Typography.Text className={styles.subscriptionPlanCardText}>
              Please contact us via{' '}
              <a
                href={TELEGRAM}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline' }}
              >
                Telegram
              </a>{' '}
              or Email to get the offer: <br />
              <a
                href={`mailto:${SUPPORT_EMAIL}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline' }}
              >
                {SUPPORT_EMAIL}
              </a>
              <Button
                type="link"
                icon={
                  copiedText === SUPPORT_EMAIL ? (
                    <CheckOutlined width={24} height={24} />
                  ) : (
                    <CopyTwoTone width={24} height={24} />
                  )
                }
                onClick={(e) => {
                  e.stopPropagation();
                  copy(SUPPORT_EMAIL);
                }}
              />
            </Typography.Text>
          </Flex>
        </Card>

        <Typography.Text className={styles.dialogText}>
          <Typography.Text type="danger"> To pause or cancel</Typography.Text>{' '}
          your subscription just skip upcoming payment and it won’t be extended.
          In case of any questions contact support:{' '}
          <a
            href={`mailto:${SUPPORT_EMAIL}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            {SUPPORT_EMAIL}
          </a>
        </Typography.Text>
      </Flex>
    </Modal>
  );
}
