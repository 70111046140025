export enum Routes {
  LogIn = '/log-in',
  SignUp = '/sign-up',
  EmailConfirm = '/sign-up/confirm-email',
  Onboarding = '/onboarding',
  ProfitAndLoss = '/',
  Transactions = '/transactions',
  Multisender = '/transactions/multisender',
  Balances = '/balances',
  Profile = '/profile',
  AddressBook = '/address-book',
}
