import { SVGAttributes } from "react";

export function GoogleLogo(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M14.5891 6.7577H8.13728V9.40949H11.823C11.6641 10.2666 11.1819 10.9916 10.4551 11.4773C9.84085 11.8881 9.05692 12.1309 8.13549 12.1309C6.35156 12.1309 4.84263 10.9256 4.30335 9.30591C4.16763 8.8952 4.08906 8.45592 4.08906 8.00413C4.08906 7.55234 4.16763 7.11306 4.30335 6.70234C4.84442 5.08449 6.35335 3.87913 8.13728 3.87913C9.14263 3.87913 10.0444 4.22556 10.7551 4.90413L12.7194 2.93806C11.5319 1.83092 9.98371 1.15234 8.13728 1.15234C5.46049 1.15234 3.14442 2.68806 2.01763 4.92734C1.55335 5.85234 1.28906 6.89877 1.28906 8.00592C1.28906 9.11306 1.55335 10.1577 2.01763 11.0827C3.14442 13.322 5.46049 14.8577 8.13728 14.8577C9.98728 14.8577 11.5373 14.2434 12.6694 13.197C13.9641 12.0041 14.7123 10.247 14.7123 8.15949C14.7123 7.67377 14.6694 7.2077 14.5891 6.7577Z"
        fill="currentColor"
      />
    </svg>
  );
}
