import { Button, Flex, Form, Input, Typography } from 'antd';
import { LetterIcon } from 'assets/icons/LetterIcon';
import { AxiosError } from 'axios';
import { EMAIL_RESEND_TIMEOUT } from 'constants/auth';
import { Routes } from 'constants/routs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userService } from 'services/userService';
import { walletService } from 'services/walletService';
import { useUserStore } from 'store/user';
import { useCountdown } from 'usehooks-ts';
import styles from './EmailConfirmView.module.css';

interface EmailConfirmForm {
  code: string;
}

export function EmailConfirmView() {
  const navigate = useNavigate();
  const email = useUserStore((s) => s.user?.email);
  const [form] = Form.useForm<EmailConfirmForm>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: EMAIL_RESEND_TIMEOUT, // 5 minutes
    intervalMs: 1000,
  });

  useEffect(() => {
    startCountdown();
  }, [startCountdown]);

  const handleSubmit = async (formData: EmailConfirmForm) => {
    try {
      setIsSubmitting(true);

      await userService.verifyEmail(formData.code);

      const hasWallets = (await walletService.getWallets()).length > 0;

      if (hasWallets) {
        navigate('/');
      } else {
        navigate(Routes.Onboarding);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        form.setFields([
          {
            name: 'code',
            errors: [error.response?.data.message || error.message],
          },
        ]);
      } else if (error instanceof Error) {
        form.setFields([
          {
            name: 'code',
            errors: [error.message],
          },
        ]);
      }
      console.warn(error);
    }
    setIsSubmitting(false);
  };

  const handleResend = async () => {
    try {
      await userService.resendVerificationEmail();
    } catch (error) {
      console.warn(error);
    } finally {
      resetCountdown();
      startCountdown();
    }
  };

  const handleLogout = async () => {
    try {
      await userService.logout();
      navigate(Routes.LogIn);
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <Flex
      justify="center"
      align="center"
      className={styles.container}
      style={{
        backgroundColor: '#F6F7F9',
      }}
      vertical
    >
      <>
        <div>
          <Flex
            justify="center"
            align="center"
            vertical
            className={styles.content}
          >
            <LetterIcon />
            <Typography.Title level={1} className={styles.title}>
              Confirm your email address
            </Typography.Title>
            <Typography.Paragraph className={styles.description}>
              We have sent a verification code on{' '}
              <a
                href={`mailto:${email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {email}
              </a>
              .<br />
              Enter code below to continue.
            </Typography.Paragraph>
            <Form<EmailConfirmForm>
              form={form}
              onFinish={handleSubmit}
              rootClassName={styles.emailConfirmForm}
            >
              <Flex vertical gap={12}>
                <Form.Item<EmailConfirmForm>
                  name="code"
                  className={styles.emailConfirmFormItem}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required.',
                    },
                  ]}
                >
                  <Input
                    size="large"
                    disabled={isSubmitting}
                    placeholder="Enter confirmation code"
                  />
                </Form.Item>
                <Button
                  block
                  htmlType="submit"
                  type="primary"
                  size="large"
                  loading={isSubmitting}
                >
                  Continue
                </Button>
                {count > 0 && (
                  <Typography.Text className={styles.emailConfirmFormText}>
                    Resend is available in {Math.floor(count / 60)}:
                    {(count % 60).toString().padStart(2, '0')}
                  </Typography.Text>
                )}
                {count === 0 && (
                  <Button
                    type="link"
                    className={styles.emailConfirmFormResendButton}
                    onClick={handleResend}
                    disabled={isSubmitting}
                  >
                    Resend code
                  </Button>
                )}
                <Button
                  type="link"
                  className={styles.emailConfirmFormResendButton}
                  onClick={handleLogout}
                  disabled={isSubmitting}
                >
                  Log out
                </Button>
              </Flex>
            </Form>
          </Flex>
        </div>
      </>
    </Flex>
  );
}
