import { SVGAttributes } from "react";

export function Template(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      {...props}
    >
      <rect x="26" y="26" width="100" height="100" rx="14" fill="#EDEFF3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 58C50 56.8954 50.8954 56 52 56H100C101.105 56 102 56.8954 102 58C102 59.1046 101.105 60 100 60H52C50.8954 60 50 59.1046 50 58ZM50 70C50 68.8954 50.8954 68 52 68H100C101.105 68 102 68.8954 102 70C102 71.1046 101.105 72 100 72H52C50.8954 72 50 71.1046 50 70ZM50 82C50 80.8954 50.8954 80 52 80H100C101.105 80 102 80.8954 102 82C102 83.1046 101.105 84 100 84H52C50.8954 84 50 83.1046 50 82ZM50 94C50 92.8954 50.8954 92 52 92H100C101.105 92 102 92.8954 102 94C102 95.1046 101.105 96 100 96H52C50.8954 96 50 95.1046 50 94Z"
        fill="#667085"
      />
    </svg>
  );
}
