import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Modal } from 'antd';
import { Codeblock } from 'components/Codeblock';
import { useAccount } from 'libs/hooks/useAccount';
import Papa from 'papaparse';
import { ChangeEvent, useRef, useState } from 'react';
import { MultisenderForm } from 'screens/Multisender/form';
import { addressBookService } from 'services/addressBook';
import useSWR from 'swr';

export function UploadCSV() {
  const [isExampleOpened, setIsExampleOpened] = useState(false);
  const [isCSVLoading, setIsCSVUploading] = useState(false);
  const csvInputRef = useRef<HTMLInputElement>(null);
  const form = Form.useFormInstance<MultisenderForm>();
  const { networkType } = useAccount();

  const { data: counterparties, isLoading: isCounterpartiesLoading } = useSWR(
    '/counterparties',
    () => addressBookService.getCounterparties()
  );

  const { data: wallets, isLoading: isWalletsLoading } = useSWR(
    '/counterparties_wallets',
    () => addressBookService.getWallets()
  );

  const handleUploadCSV = (e: ChangeEvent<HTMLInputElement>) => {
    if ((e.target.files?.length ?? 0) <= 0) return;

    const csvFile = e.target.files?.[0];
    if (!csvFile) return;

    setIsCSVUploading(true);

    Papa.parse<[string, string | number]>(csvFile, {
      skipEmptyLines: true,
      dynamicTyping: true,
      error() {
        setIsCSVUploading(false);
      },
      complete(results) {
        setIsCSVUploading(false);

        if (
          results.data[0][0] === 'address' &&
          results.data[0][1] === 'amount'
        ) {
          results.data.shift();
        }
        const recipients = form.getFieldValue(
          'recipients'
        ) as MultisenderForm['recipients'];

        const newRecords: MultisenderForm['recipients'] = [];

        for (let i = 0; i < results.data.length; i++) {
          const item = results.data[i];

          const wallet = wallets?.find(
            (w) =>
              w.wallet.address.toLowerCase() === item[0].toLowerCase() ||
              (w.name === item[0] && w.wallet.network === networkType)
          );

          const counterparty = counterparties?.find(
            (c) => c.id === wallet?.counterparty_id
          );

          newRecords.push({
            id: crypto.randomUUID(),
            no: recipients.length + i + 1,
            counterparty: counterparty?.id,
            walletAddress: [wallet?.wallet.address || item[0]],
            amount: +item[1],
          });
        }

        form.setFieldsValue({
          recipients: [
            ...recipients.filter(
              (recipient) =>
                recipient?.counterparty ||
                recipient?.walletAddress?.[0] ||
                recipient?.amount
            ),
            ...newRecords,
          ],
        });

        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
        }, 0);

        csvInputRef.current!.value = '';
      },
    });
  };

  return (
    <>
      <Button
        type="dashed"
        loading={isCSVLoading || isCounterpartiesLoading || isWalletsLoading}
        icon={<CloudUploadOutlined />}
        style={{ marginLeft: 8 }}
        htmlType='button'
        onClick={() => csvInputRef.current?.click()}
      >
        Upload CSV
      </Button>
      <input
        type="file"
        name="multisender-csv"
        id="multisender-csv"
        accept="text/csv,.csv"
        ref={csvInputRef}
        onChange={handleUploadCSV}
        style={{ display: 'none' }}
      />
      <Button
        type="link"
        htmlType='button'
        style={{ marginLeft: 8, padding: 0 }}
        onClick={() => setIsExampleOpened(true)}
      >
        Show CSV example
      </Button>
      <Modal
        open={isExampleOpened}
        afterOpenChange={setIsExampleOpened}
        title="Example CSV"
        centered
        okText="Got it"
        onOk={() => setIsExampleOpened(false)}
        onCancel={() => setIsExampleOpened(false)}
        footer={(_, { OkBtn, CancelBtn }) => (
          <Flex align="center" gap={8} style={{ marginTop: 24 }}>
            <Button
              href="/static/test.csv"
              target="_blank"
              download
              style={{ marginRight: 'auto' }}
            >
              Download example
            </Button>
            <CancelBtn />
            <OkBtn />
          </Flex>
        )}
      >
        <Codeblock readonly>
          0xC8c30Fa803833dD1Fd6DBCDd91Ed0b301EFf87cF,13.45
          0x7D52422D3A5fE9bC92D3aE8167097eE09F1b347d,1.049
          0x64c9525A3c3a65Ea88b06f184F074C2499578A7E,1
        </Codeblock>
      </Modal>
    </>
  );
}
