import { getBalance } from '@wagmi/core';
import { wagmiConfig } from 'configs/wagmi';
import { NetworkType } from 'libs/types';
import { useCallback, useEffect, useState } from 'react';
import { tronWeb } from 'services/tronWebAdapter';
import { Address } from 'viem';
import { useNetworkType } from './useNetworkType';

interface BalanceParams {
  /**
   * Enable balance fetching.
   */
  enable?: boolean;
  /**
   * Address to get balance for.
   */
  address?: string;
  /**
   * Chain ID.
   */
  chainId?: number;
  /**
   * Network type (EVM or TRON).
   */
  networkType?: NetworkType;
  /**
   * ERC-20 or TRC-20 token address to get balance for.
   */
  token?: string;
}

export function useBalance({ enable = true, ...params }: BalanceParams) {
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [symbol, setSymbol] = useState<string | undefined>();

  const networkType = useNetworkType(
    (s) => params.networkType ?? s.networkType
  );

  const fetchBalance = useCallback(async () => {
    if (!enable) return;

    setIsLoading(true);
    if (params.address === undefined) return;

    try {
      switch (networkType) {
        case NetworkType.EVM: {
          const { value, symbol, decimals } = await getBalance(wagmiConfig, {
            address: params.address as Address,
            chainId: params.chainId,
            token: params.token as Address | undefined,
            unit: 'ether',
          });

          setBalance(+value.toString() / 10 ** decimals);
          setSymbol(symbol);
          break;
        }
        case NetworkType.Tron: {
          if (params.address === undefined) return;
          let balance;
          let symbol = 'TRX';
          let decimals = 6;

          tronWeb.setAddress(params.address);

          if (params.token) {
            const token = await tronWeb?.contract()?.at(params.token);
            symbol = await token.symbol().call();
            balance = await token.balanceOf(params.address).call();
            decimals = await token.decimals().call();
          } else {
            balance = await tronWeb.trx.getBalance(params.address);
          }

          setBalance(+balance.toString() / 10 ** decimals);
          setSymbol(symbol);
          break;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [params, enable, networkType]);

  useEffect(() => {
    if (params.address && enable) {
      fetchBalance();
    }
  }, [
    params.address,
    params.token,
    params.chainId,
    params.networkType,
    enable,
  ]);

  return {
    isLoading,
    balance,
    symbol,
    refetch: fetchBalance,
  };
}
