import { App, Checkbox, Flex, Modal, Typography } from 'antd';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useOnboardingStore } from 'screens/Onboarding/store';
import { useTransactionsFiltersStore } from 'screens/Transactions/store';
import { walletService } from 'services/walletService';
import { useWalletsStore } from 'store/wallets';
import { useSWRConfig } from 'swr';
import { Wallet } from 'types/wallet';

interface DeleteWalletDialogProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  wallet: Wallet;
}

export function DeleteWalletDialog({
  isOpen,
  onOpenChange,
  wallet,
}: DeleteWalletDialogProps) {
  const { notification } = App.useApp();
  const [isUserUnderstand, setIsUserUnderstand] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [removeWalletFromStore] = useOnboardingStore((s) => [s.removeWallet]);
  const [wallets, setWallets] = useWalletsStore((s) => [
    s.wallets,
    s.setWallets,
  ]);
  const { mutate } = useSWRConfig();
  const transactionRequestKey = useTransactionsFiltersStore(
    (s) => s.requestKey
  );

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      if (!wallet.id) {
        removeWalletFromStore(wallet.wallet.address);
        setWallets(
          wallets.filter((w) => w.wallet.address !== wallet.wallet.address)
        );
      } else {
        await walletService.removeWallet(wallet.id);
      }

      await mutate(transactionRequestKey, undefined, {
        revalidate: false,
      });

      handleOpenChange(false);
      notification.success({
        message: 'Delete wallet',
        description: 'Wallet deleted successfully.',
        placement: 'bottom',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        notification.error({
          message: 'Delete wallet',
          description: error.response?.data.message || error.message,
          placement: 'bottom',
        });
      } else if (error instanceof Error) {
        notification.error({
          message: 'Delete wallet',
          description: error.message,
          placement: 'bottom',
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenChange = (val: boolean) => {
    onOpenChange(val);

    if (val) return;
    setIsUserUnderstand(false);
    setIsSubmitting(false);
  };

  return (
    <Modal
      open={isOpen}
      afterOpenChange={handleOpenChange}
      centered
      onOk={handleDelete}
      onCancel={() => handleOpenChange(false)}
      title="Delete wallet"
      okText="Delete anyway"
      okButtonProps={{
        danger: true,
        disabled: !isUserUnderstand,
        loading: isSubmitting,
      }}
    >
      <Flex vertical gap={20} style={{ margin: '24px 0' }}>
        <Typography.Text>
          If you delete this wallet, all transactions and tags related to this
          wallet will be erased from FinFlo forever.
        </Typography.Text>
        <Typography.Text>This data can not be restored.</Typography.Text>
        <Checkbox
          checked={isUserUnderstand}
          onChange={(e) => setIsUserUnderstand(e.target.checked)}
        >
          I understand consequences
        </Checkbox>
      </Flex>
    </Modal>
  );
}
