import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import 'assets/fonts/SFProText/index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

Bugsnag.start({
  apiKey: '7163198b28b88a447b14fcc9fb6f89a3',
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({
  apiKey: '7163198b28b88a447b14fcc9fb6f89a3',
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);
