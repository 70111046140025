import { Flex, Modal, Typography } from 'antd';
import { useState } from 'react';
import {
  BuilderViewVariant,
  MultisenderTabs,
  useMultisenderStore,
} from 'screens/Multisender/store';

interface EditDialogProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export function EditTemplateDialog({ isOpen, onOpenChange }: EditDialogProps) {
  const [setBuilderViewVariant, setInitialBuilderValues, setTab] =
    useMultisenderStore((s) => [
      s.setBuilderViewVariant,
      s.setInitialBuilderValues,
      s.setTab,
    ]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOpenChange = (isOpen: boolean) => {
    onOpenChange(isOpen);

    if (!isOpen) {
      setIsSubmitting(false);
    }
  };

  const handleCancel = async () => {
    setInitialBuilderValues(undefined);
    setBuilderViewVariant(BuilderViewVariant.Default);
    setTab(MultisenderTabs.Templates);
  };

  return (
    <Modal
      open={isOpen}
      afterOpenChange={handleOpenChange}
      centered
      onOk={() => handleOpenChange(false)}
      onCancel={() => handleCancel()}
      title="Cancel edit"
      okText="Continue Editing"
      cancelText="Discard Changes"
      okButtonProps={{
        loading: isSubmitting,
      }}
    >
      <Flex vertical gap={20} style={{ margin: '24px 0' }}>
        <Typography.Text>
          Are you sure you want to cancel editing?
          <br />
          All unsaved changes will be lost.
        </Typography.Text>
      </Flex>
    </Modal>
  );
}
