import { Wallet } from 'types/wallet';
import { create } from 'zustand';

export interface OnboardingStore {
  wallets: Wallet[];
  scanningStatus: null | 'inProgress' | 'success' | 'failed';
  error: string | null;
  addWallet: (newWallet: Wallet) => void;
  setWallets: (wallets: Wallet[]) => void;
  removeWallet: (address: string) => void;
  removeWalletById: (id: string) => void;
  setError: (error: string) => void;
  setScanningStatus: (
    scanningStatus: OnboardingStore['scanningStatus']
  ) => void;
}

export const onboardingStore = create<OnboardingStore>((set, get) => ({
  wallets: [],
  error: null,
  scanningStatus: null,
  addWallet: async (newWallet) => {
    const isAlreadyExists = get().wallets.some(
      (wallet) => wallet.wallet.address === newWallet.wallet.address
    );

    if (isAlreadyExists) throw new Error('Wallet already exists');

    set((state) => ({ wallets: [...state.wallets, newWallet], error: null }));
  },
  removeWallet: (address) =>
    set((state) => ({
      wallets: state.wallets.filter(
        (wallet) => wallet.wallet.address !== address
      ),
    })),
  removeWalletById: (id) =>
    set((state) => ({
      wallets: state.wallets.filter((wallet) => wallet.id !== id),
    })),
  setError: (error) => set({ error }),
  setScanningStatus: (scanningStatus) => set({ scanningStatus }),
  setWallets: (wallets) =>
    set({
      wallets: wallets.map((wallet) => ({
        ...wallet,
        name: wallet.name,
        wallet: { ...wallet.wallet, address: wallet.wallet.address },
      })),
    }),
}));

export const useOnboardingStore = onboardingStore;
