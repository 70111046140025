import { Currency } from "types";

export interface MultisenderForm {
  name: string;
  description: string;
  network: string;
  token: Currency['id'];
  recipients: {
    id: string;
    no?: number;
    counterparty?: string;
    walletAddress?: string[];
    amount?: number;
  }[];
}

export const DEFAULT_NETWORK: string = 'tron';

export const initialRecipients: MultisenderForm['recipients'] = Array(3)
  .fill(0)
  .map((el, i) => ({
    id: crypto.randomUUID(),
    no: i + 1,
    counterparty: undefined,
    walletId: undefined,
    amount: undefined,
  }));
