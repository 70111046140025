import { Button, Card, Flex, Form, Input, Typography } from 'antd';
import { SubscriptionDialog, SubscriptionDialogVariant } from 'components/SubscriptionDialog';
import { useState } from 'react';
import { useUserStore } from 'store/user';
import { isAddressValid } from 'utilities/wallets';
import styles from './styles.module.css';

export interface AddWalletFormType {
  name: string;
  address: string;
}

interface AddWalletFormProps {
  isSubmitting: boolean;
}

export function AddWalletForm({ isSubmitting }: AddWalletFormProps) {
  const form = Form.useFormInstance<AddWalletFormType>();
  const address = Form.useWatch('address', form);
  const [isSubscribeDialogOpen, setIsSubscribeDialogOpen] = useState(false);
  const user = useUserStore((s) => s.user);

  return (
    <Flex gap={20} vertical className={styles.onboardingViewFormContent}>
      <div>
        <Typography>
          <Typography.Title
            className={styles.onboardingViewFormTitle}
            level={1}
          >
            Last step – <br />
            Add wallets
          </Typography.Title>
          <Typography.Text className={styles.onboardingViewFormDescription}>
            Add at least one wallet used in your accounting operations. FinFlo
            will analyse transactions on your wallet for last 180 days.
          </Typography.Text>
        </Typography>
      </div>

      <>
        <Flex gap={20} vertical>
          <Card
            classNames={{
              body: styles.onboardingViewFormCard,
            }}
            bordered
          >
            <Flex vertical gap={20}>
              <Form.Item<AddWalletFormType>
                style={{ marginBottom: 0 }}
                labelCol={{ span: 24 }}
                name="address"
                hasFeedback={{
                  icons: () => ({
                    success: ' ',
                  }),
                }}
                label={
                  <>
                    Wallet address
                    <span className={styles.onboardingViewFormLabelRequired}>
                      *
                    </span>
                  </>
                }
                className={styles.onboardingViewFormItem}
                rules={[
                  {
                    validateTrigger: 'onSubmit',
                    validator(_, value) {
                      if (!value || value?.length <= 0)
                        return Promise.reject('This field is required.');
                      const res = isAddressValid(value);
                      if (!res) {
                        return Promise.reject(
                          'We support TRON and EVM (operates with tokens on Ethereum, Base, Polygon, Avalanche, Arbitrum, and other compatible networks)'
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input size="large" placeholder="Enter your wallet address" />
              </Form.Item>

              <Form.Item<AddWalletFormType>
                style={{ marginBottom: 0 }}
                labelCol={{ span: 24 }}
                label="Name"
                name="name"
                className={styles.onboardingViewFormItem}
              >
                <Input size="large" placeholder="eg Salary (optional)" />
              </Form.Item>
              {(user?.can_add_new_wallet || !address) && (
                <Button
                  size="large"
                  type="primary"
                  style={{ boxShadow: 'none' }}
                  htmlType="submit"
                  block
                  loading={isSubmitting}
                  disabled={!address}
                >
                  Add wallet
                </Button>
              )}
              {!user?.can_add_new_wallet && address && (
                <Button
                  size="large"
                  type="primary"
                  block
                  loading={isSubmitting}
                  disabled={!address}
                  onClick={() => setIsSubscribeDialogOpen(true)}
                >
                  Upgrade to Add more Wallets
                </Button>
              )}
              <SubscriptionDialog
                isOpen={isSubscribeDialogOpen}
                onOpenChange={setIsSubscribeDialogOpen}
              />
            </Flex>
          </Card>
        </Flex>
      </>
    </Flex>
  );
}
