import { Button, Card, Flex, Tag, Typography } from 'antd';
import ethereum from 'cryptocurrency-icons/32@2x/color/eth@2x.png';
import tron from 'cryptocurrency-icons/32@2x/color/trx@2x.png';
import { useState } from 'react';
import { OnboardingStore, useOnboardingStore } from 'screens/Onboarding/store';
import { walletService } from 'services/walletService';
import styles from './styles.module.css';

interface WalletListItemProps {
  wallet: OnboardingStore['wallets'][0];
}

export function WalletListItem({ wallet }: WalletListItemProps) {
  const removeWalletFromStore = useOnboardingStore(
    (state) => state.removeWallet
  );
  const [isLoading, setIsLoading] = useState(false);

  const removeWallet = async () => {
    if (!wallet.id) {
      removeWalletFromStore(wallet.wallet.address);
      return;
    }

    setIsLoading(true);
    try {
      await walletService.removeWallet(wallet.id);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card
      className={styles.walletListItem}
      classNames={{ body: styles.walletListItemBody }}
    >
      <Flex align="center">
        {wallet.name && (
          <Tag className={styles.walletListItemName}>
            {wallet.name}
          </Tag>
        )}
        {wallet.wallet.network === 'eth' && (
          <Typography.Text className={styles.walletListItemNetwork}>
            <img src={ethereum} alt="Ethereum" />
            Ethereum
          </Typography.Text>
        )}
        {wallet.wallet.network === 'tron' && (
          <Typography.Text className={styles.walletListItemNetwork}>
            <img src={tron} alt="Tron" />
            Tron
          </Typography.Text>
        )}

        <Button
          type="link"
          size="small"
          className={styles.walletListItemRemove}
          onClick={removeWallet}
          loading={isLoading}
          danger
        >
          Remove this wallet
        </Button>
      </Flex>
      <Typography.Paragraph className={styles.walletListItemText}>
        {wallet.wallet.address}
      </Typography.Paragraph>
    </Card>
  );
}
