import { REFERRAL_CODE_KEY, REFERRAL_COOKIE_MAX_AGE } from 'constants/auth';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

export function useReferralCodeInterceptor() {
  const [searchParams] = useSearchParams();

  const referralCode = searchParams.get(REFERRAL_CODE_KEY);

  useEffect(() => {
    if (!referralCode) return;

    const cookies = new Cookies();
    const referralCodeCookie = cookies.get(REFERRAL_CODE_KEY);

    if (!referralCodeCookie) {
      cookies.set(REFERRAL_CODE_KEY, referralCode, {
        path: '/',
        maxAge: REFERRAL_COOKIE_MAX_AGE,
      });
    }
  }, [referralCode]);

  return null;
}
