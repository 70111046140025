import { create } from 'zustand';

export interface TransactionsFiltersStore {
  type?: string;
  tagged?: string;
  tagIds?: string[];
  walletIds: string[];
  tokenIds?: number[];
  amountFrom?: number;
  amountTo?: number;
  dateFrom?: string;
  dateTo?: string;

  page: number;
  setPage: (page: number) => void;

  requestKey: string;

  setFilters: (
    filters: Pick<
      TransactionsFiltersStore,
      | 'type'
      | 'tagged'
      | 'tagIds'
      | 'walletIds'
      | 'tokenIds'
      | 'amountFrom'
      | 'amountTo'
      | 'dateFrom'
      | 'dateTo'
    >
  ) => void;
}

export const transactionsFiltersStore = create<TransactionsFiltersStore>(
  (set, get) => ({
    requestKey: '/transfers&page=1',
    walletIds: [],

    page: 1,
    setPage: (page) => {
      if (page < 1) return;

      const key = get().requestKey;

      const newKey = key.replace(/page=\d+/, `page=${page}`);

      set({ page, requestKey: newKey });
    },

    setFilters: (filters) => {
      let key = '/transfers';

      if (filters.walletIds.length) {
        key += `?walletIds=${filters.walletIds.join(',')}`;
      }

      if (filters.type) {
        key += `&type=${filters.type}`;
      }

      if (filters.tagged) {
        key += `&tagged=${filters.tagged}`;
      }

      if (filters.tagIds?.length) {
        key += `&tagIds=${filters.tagIds.join(',')}`;
      }

      if (filters.tokenIds?.length) {
        key += `&currencyIds=${filters.tokenIds.join(',')}`;
      }

      if (filters.amountFrom) {
        key += `&amountFrom=${filters.amountFrom}`;
      }

      if (filters.amountTo) {
        key += `&amountTo=${filters.amountTo}`;
      }

      if (filters.dateFrom) {
        key += `&dateFrom=${filters.dateFrom}`;
      }

      if (filters.dateTo) {
        key += `&dateTo=${filters.dateTo}`;
      }

      set({ ...filters, page: 1, requestKey: `${key}&page=1` });
    },
  })
);

export const useTransactionsFiltersStore = transactionsFiltersStore;
