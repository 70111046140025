import { NetworkType as TronNetworkType } from '@tronweb3/tronwallet-abstract-adapter';
import { WalletProvider } from '@tronweb3/tronwallet-adapter-react-hooks';
import {
  TronLinkAdapter,
  WalletConnectAdapter,
} from '@tronweb3/tronwallet-adapters';
import { WALLET_CONNECT_PROJECT_ID } from 'configs';
import { useNetworkType } from 'libs/hooks/useNetworkType';
import { NetworkType } from 'libs/types';
import { ReactNode, useMemo } from 'react';
import { useAccount } from 'wagmi';
import { EVMSwitcher } from './EVMSwitcher';

export function TronProvider({ children }: { children: ReactNode }) {
  const { isConnected: isEVMConnected } = useAccount();
  const [networkType, setNetworkType] = useNetworkType((s) => [
    s.networkType,
    s.setNetworkType,
  ]);

  const adapters = useMemo(() => {
    const tronLinkAdapter = new TronLinkAdapter();
    const walletConnect = new WalletConnectAdapter({
      network: TronNetworkType.Mainnet,
      options: {
        relayUrl: 'wss://relay.walletconnect.com',
        projectId: WALLET_CONNECT_PROJECT_ID,
        metadata: {
          name: 'FinFlo',
          description: 'FinFlo',
          url: 'https://app.finflo.io/',
          icons: ['https://app.finflo.io/favicon.ico'],
        },
      },
      web3ModalConfig: {
        themeMode: 'dark',
        themeVariables: {
          '--w3m-z-index': 1000,
        } as any,
      },
    });
    return [tronLinkAdapter, walletConnect];
  }, []);

  return (
    <WalletProvider
      adapters={adapters}
      autoConnect={false}
      disableAutoConnectOnLoad
      onConnect={() => {
        if (!networkType) setNetworkType(NetworkType.Tron);
      }}
      onDisconnect={() => {
        if (isEVMConnected) {
          setNetworkType(NetworkType.EVM);
        } else {
          setNetworkType(undefined);
        }
      }}
    >
      <EVMSwitcher>{children}</EVMSwitcher>
    </WalletProvider>
  );
}
