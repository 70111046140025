import { SVGAttributes } from 'react';

export function Copy(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M14.3332 14.3333H16.511C17.3822 14.3333 17.8176 14.3333 18.1503 14.1638C18.443 14.0146 18.6813 13.7767 18.8305 13.484C19 13.1513 19 12.7157 19 11.8445V7.48891C19 6.61772 19 6.18212 18.8305 5.84937C18.6813 5.55667 18.443 5.31868 18.1503 5.16955C17.8176 5 17.3823 5 16.5111 5H12.1556C11.2844 5 10.8485 5 10.5158 5.16955C10.2231 5.31868 9.98529 5.55667 9.83615 5.84937C9.66661 6.18212 9.66661 6.61775 9.66661 7.48895V9.66672M5 16.5112V12.1556C5 11.2844 5 10.8488 5.16954 10.516C5.31868 10.2233 5.55647 9.98535 5.84917 9.83621C6.18191 9.66667 6.61783 9.66667 7.48901 9.66667H11.8445C12.7157 9.66667 13.151 9.66667 13.4837 9.83621C13.7764 9.98535 14.0147 10.2233 14.1638 10.516C14.3334 10.8488 14.3334 11.2844 14.3334 12.1556V16.5111C14.3334 17.3823 14.3334 17.8179 14.1638 18.1507C14.0147 18.4434 13.7764 18.6813 13.4837 18.8304C13.151 19 12.7157 19 11.8445 19H7.48901C6.61783 19 6.18191 19 5.84917 18.8304C5.55647 18.6813 5.31868 18.4434 5.16954 18.1507C5 17.8179 5 17.3824 5 16.5112Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
