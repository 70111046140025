import { App, Button, Form } from 'antd';
import { useAccount } from 'libs/hooks/useAccount';
import { NetworkType } from 'libs/types';
import { useMemo, useState } from 'react';
import { MultisenderForm } from 'screens/Multisender/form';
import {
  BuilderViewVariant,
  MultisenderTabs,
  useMultisenderStore,
} from 'screens/Multisender/store';
import { multisenderService } from 'services/multisender';
import { MultisenderState } from 'types/multisender';
import { EditTemplateDialog } from '../BatchItem/EditTemplateDialog';

export function TemplateTools() {
  const form = Form.useFormInstance<MultisenderForm>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const { notification } = App.useApp();
  const { address, networkType } = useAccount();
  const [setTab, setBuilderViewVariant, initialBuilderValues, variant] =
    useMultisenderStore((s) => [
      s.setTab,
      s.setBuilderViewVariant,
      s.initialBuilderValues,
      s.builderViewVariant,
    ]);

  const networkCode = useMemo(() => {
    switch (variant) {
      case BuilderViewVariant.Default:
        return networkType;
      case BuilderViewVariant.Template:
        return initialBuilderValues?.network;
    }
  }, [networkType, variant, initialBuilderValues?.network]);

  const handleSubmitFailed = () => {
    notification.error({
      message: 'Complete data',
      description: 'Fill all fields and complete transactions list to proceed',
      placement: 'bottom',
    });
  };

  const handleSaveTemplate = async () => {
    const values = form.getFieldsValue();

    const recipients = values.recipients.filter(
      (recipient) =>
        recipient?.counterparty ||
        recipient?.walletAddress?.[0] ||
        recipient?.amount
    );

    try {
      await form.validateFields();
      if (!initialBuilderValues) return;

      if (recipients.length <= 0) {
        throw new Error('Recipients list is empty');
      }
    } catch (error) {
      handleSubmitFailed();
      return;
    }
    setIsSubmitting(true);

    try {
      await multisenderService.editMultisend(
        {
          name: values.name,
          description: values.description ?? '',
          currency_id: values.token,
          chain: networkCode === NetworkType.EVM ? 'arbitrum' : 'tron',
          state: MultisenderState.Template,
          source: address ?? '',
          targets: recipients.map((el) => ({
            address: el.walletAddress?.[0] ?? '',
            amount: el.amount ?? 0,
          })),
        } as any,
        initialBuilderValues?.id
      );

      setTab(MultisenderTabs.Templates);
      setBuilderViewVariant(BuilderViewVariant.Default);

      notification.success({
        message: 'Template updated',
        description: `${values.name} saved`,
        placement: 'bottom',
      });
    } catch (error) {
      notification.error({
        message: 'Save failed',
        description: `${values.name} was not saved`,
        placement: 'bottom',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button
        onClick={() => setIsCanceling(true)}
        disabled={isSubmitting}
        loading={isSubmitting}
        htmlType="button"
        style={{ marginLeft: 'auto' }}
      >
        Cancel
      </Button>
      <Button
        type="primary"
        onClick={() => handleSaveTemplate()}
        loading={isSubmitting}
      >
        Save template
      </Button>
      <EditTemplateDialog isOpen={isCanceling} onOpenChange={setIsCanceling} />
    </>
  );
}
