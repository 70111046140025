import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { NetworkType } from 'libs/types';
import { useAccount as useEVMAccount } from 'wagmi';
import { useNetworkType } from './useNetworkType';

export function useAccount() {
  const {
    connected: isTronConnected,
    address: tronAddress,
    wallet: tronAdapter,
  } = useTronWallet();
  const {
    address: evmAddress,
    isConnected: isEVMConnected,
    connector: evmConnector,
  } = useEVMAccount();

  const networkType = useNetworkType((s) => s.networkType);

  if (networkType === NetworkType.Tron) {
    return {
      isConnected: isTronConnected,
      address: tronAddress ?? undefined,
      connector: tronAdapter,
      networkType,
    };
  }

  return {
    isConnected: isEVMConnected,
    address: evmAddress,
    connector: evmConnector,
    networkType,
  };
}
