import axios, { AxiosInstance } from 'axios';
import { API_URL } from 'configs/api';
import { settingsStore } from 'store/settings';
import { Settings } from 'types/settings';

export class SettingsService {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
    });
  }

  async getSettings() {
    const { data } = await this._api.get<Settings>('/settings');

    settingsStore.setState({ settings: data });

    return data;
  }
}

export const settingsService = new SettingsService();
