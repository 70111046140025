import { Flex, Segmented, Typography } from 'antd';
import { useState } from 'react';
import { Wallets } from './components/Wallets';
import { Counterparties } from './components/Counterparties';

enum Tabs {
  Wallets = 'Wallet',
  Counterparties = 'Counterparties',
}

export function AddressBookView() {
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Wallets);

  return (
    <Flex gap={32} vertical>
      <Flex align="center" gap={30}>
        <Typography.Title level={2} style={{ margin: 0 }}>
          Address Book
        </Typography.Title>
        <Segmented<Tabs>
          options={[Tabs.Wallets, Tabs.Counterparties]}
          value={activeTab}
          onChange={setActiveTab}
        />
      </Flex>
      {activeTab === Tabs.Wallets && <Wallets />}
      {activeTab === Tabs.Counterparties && <Counterparties />}
    </Flex>
  );
}
