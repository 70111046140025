import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import {
  argentWallet,
  coinbaseWallet,
  imTokenWallet,
  ledgerWallet,
  metaMaskWallet,
  omniWallet,
  rainbowWallet,
  trustWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { WALLET_CONNECT_PROJECT_ID } from 'configs';
import { createConfig, http } from 'wagmi';
import { arbitrum, Chain } from 'wagmi/chains';

export const chains: readonly [Chain, ...Chain[]] = [arbitrum];

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Popular',
      wallets: [
        metaMaskWallet,
        rainbowWallet,
        coinbaseWallet,
        walletConnectWallet,
      ],
    },
    {
      groupName: 'More',
      wallets: [
        trustWallet,
        argentWallet,
        omniWallet,
        imTokenWallet,
        ledgerWallet,
      ],
    },
  ],
  {
    appName: 'FinFlo',
    projectId: WALLET_CONNECT_PROJECT_ID,
  }
);

export const wagmiConfig = createConfig({
  chains: chains,
  connectors,
  transports: {
    [arbitrum.id]: http(),
  },
});
