import { Button, Flex, Typography } from 'antd';
import { Routes } from 'constants/routs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userService } from 'services/userService';
import useSWR from 'swr';
import { PasswordChangeDialog } from './PasswordChangeDialog';
import { PasswordSetDialog } from './PasswordSetDialog';
import styles from './styles.module.css';

export function EmailAndPasswordInfo() {
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const [isPasswordSetDialogOpen, setIsPasswordSetDialogOpen] = useState(false);
  const navigate = useNavigate();

  const { data: user, isLoading } = useSWR(
    '/api/user',
    () => userService.getCurrentUser(),
    {
      revalidateOnFocus: false,
    }
  );

  const handleLogout = async () => {
    try {
      await userService.logout();
      navigate(Routes.LogIn);
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <Flex justify="space-between" align="center" gap={12}>
      <Flex vertical gap={4}>
        <Typography.Text className={styles.loginInfoLabel}>
          You logined as
        </Typography.Text>
        <Typography.Text className={styles.loginInfo}>
          {isLoading ? 'Loading...' : user?.user?.email}
        </Typography.Text>
      </Flex>
      <Flex gap={12}>
        {user?.user.is_password_set && (
          <Button onClick={() => setIsPasswordDialogOpen(true)}>
            Change password
          </Button>
        )}
        {!user?.user.is_password_set && (
          <Button onClick={() => setIsPasswordSetDialogOpen(true)}>
            Set password
          </Button>
        )}
        <Button onClick={handleLogout}>Log out</Button>
        <PasswordChangeDialog
          isOpen={isPasswordDialogOpen}
          onOpenChange={setIsPasswordDialogOpen}
        />
        <PasswordSetDialog
          isOpen={isPasswordSetDialogOpen}
          onOpenChange={setIsPasswordSetDialogOpen}
        />
      </Flex>
    </Flex>
  );
}
