import { EditableProTable, ProColumns } from '@ant-design/pro-components';
import { Alert, App, Button, Popconfirm, Tag, Typography } from 'antd';
import { AxiosError } from 'axios';
import { addressBookService } from 'services/addressBook';
import useSWR from 'swr';
import { Counterparty, CounterpartyLabels } from 'types/counterparty';
import { CounterpartyDialog } from '../CounterpartyDialog';

export function Counterparties() {
  const { data, isLoading, mutate } = useSWR(
    '/counterparties?with_turnover',
    () => addressBookService.getCounterparties(true)
  );

  const columns: ProColumns<Counterparty>[] = [
    {
      title: 'Counterparty',
      dataIndex: 'name',
      editable: (text, record, index) => {
        return true;
      },
      sorter: (a, b) => a.name?.localeCompare(b.name || '') || 0,
      render: (text) => {
        return <Tag>{text}</Tag>;
      },
      fieldProps: {
        size: 'small',
        placeholder: 'Select Label',
      },
      width: '268px',
      formItemProps: (form, { rowIndex }) => {
        return {
          rules: [{ required: true, message: 'This field is required' }],
        };
      },
    },
    {
      title: 'Wallets qty',
      dataIndex: ['wallets_count', 'total'],
      editable: () => {
        return false;
      },
      width: '268px',
    },
    {
      title: 'Label',
      dataIndex: 'label',
      editable: (text, record, index) => {
        return true;
      },
      sorter: (a, b) => a.label?.localeCompare(b.label || '') || 0,
      filters: [
        {
          text: CounterpartyLabels.Entity,
          value: CounterpartyLabels.Entity,
        },
        {
          text: CounterpartyLabels.Individual,
          value: CounterpartyLabels.Individual,
        },
      ],
      onFilter: (value, record) => record.label === value,
      valueType: 'select',
      valueEnum: CounterpartyLabels,
      fieldProps: {
        size: 'small',
        placeholder: 'Select Label',
        showSearch: true,
      },
      width: '268px',
      formItemProps: (form, { rowIndex }) => {
        return {
          rules: [{ required: true, message: 'This field is required' }],
        };
      },
    },
    {
      title: 'Turnover',
      dataIndex: 'turnover',
      editable: false,
      sorter: (a, b) => (a.turnover ?? 0) - (b.turnover ?? 0),
      align: 'right',
      width: '228px',
      render: (text, record) => {
        if ((record.turnover ?? 0) > 0) {
          return (
            <Typography.Text type="success">+{record.turnover}</Typography.Text>
          );
        }

        if (!record.turnover || +record.turnover === 0) {
          return <Typography.Text>{text}</Typography.Text>;
        }

        return (
          <Typography.Text type="danger">{record.turnover}</Typography.Text>
        );
      },
    },
    {
      title: 'Actions',
      valueType: 'option',
      width: '160px',
      render: (text, record, _, action) => [
        <CounterpartyDialog counterparty={record} />,

        <Popconfirm
          title="Are you sure?"
          onConfirm={async () => {
            await handleDelete(record.id);
            action?.reload();
          }}
          okText="OK"
          cancelText="Cancel"
        >
          <Button
            type="link"
            key="delete"
            onClick={() => {}}
            style={{ padding: 0, height: 'auto' }}
          >
            Delete
          </Button>
        </Popconfirm>,
      ],
    },
  ];

  const { notification } = App.useApp();

  const handleDelete = async (id: Counterparty['id']) => {
    try {
      await addressBookService.deleteCounterparty(id);
      await mutate();
    } catch (error) {
      if (error instanceof AxiosError) {
        notification.error({
          message: 'Error',
          description: error.response?.data?.message || error.message,
          placement: 'bottom',
        });
      } else if (error instanceof Error) {
        notification.error({
          message: 'Error',
          description: error.message,
          placement: 'bottom',
        });
      }
      throw error;
    }
  };

  const handleSave = async (record: Counterparty) => {
    try {
      const counterparty = await addressBookService.getCounterparty(record.id);

      if (!!counterparty?.id) {
        await addressBookService.updateCounterparty(
          record.id,
          record.name,
          record.note,
          record.label
        );
      } else {
        await addressBookService.createCounterparty(
          record.name,
          record.note,
          record.label
        );
      }
      await mutate();
    } catch (error) {
      if (error instanceof AxiosError) {
        notification.error({
          message: 'Error',
          description: error.response?.data?.message || error.message,
          placement: 'bottom',
        });
      } else if (error instanceof Error) {
        notification.error({
          message: 'Error',
          description: error.message,
          placement: 'bottom',
        });
      }
      throw error;
    }
  };

  return (
    <>
      <Alert
        message="Counterparties created on Wallets section added to the following list automatically."
        type="info"
        showIcon
        closable
      />
      <EditableProTable
        rowKey={(record) => record.id}
        columns={columns}
        loading={isLoading}
        value={data ?? []}
        size="small"
        cardProps={{
          bodyStyle: { padding: 0 },
        }}
        editable={{
          onSave: async (_, record) => await handleSave(record),
          onDelete: async (_, record) => {
            await handleDelete(record.id);
          },
          onlyAddOneLineAlertMessage: 'Only one line can be added at a time',
          onlyOneLineEditorAlertMessage:
            'Only one line can be edited at a time',
        }}
        recordCreatorProps={{
          position: 'top',
          record: {
            id: crypto.randomUUID(),
            name: '',
            note: '',
            label: undefined,
            turnover: 0,
          },
          creatorButtonText: 'Add new Wallet address',
        }}
      />
    </>
  );
}
