import { ACCESS_TOKEN_STORAGE_KEY } from 'constants/auth';
import { Routes } from 'constants/routs';
import { useReferralCodeInterceptor } from 'hooks/useReferralCodeInterceptor';
import { ReactNode, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { userService } from 'services/userService';

interface PrivateRouteProps {
  children?: ReactNode;
}

export function GuestsOnlyRoute({ children }: PrivateRouteProps) {
  const navigate = useNavigate();
  const isAuthorized = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) !== null;

  useReferralCodeInterceptor();

  useEffect(() => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    if (!accessToken) return;

    userService.getCurrentUser(accessToken).catch(() => {
      localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
      navigate(Routes.LogIn);
    });
  }, [navigate]);

  if (isAuthorized) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}
