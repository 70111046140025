import { useGoogleLogin } from '@react-oauth/google';
import { Alert, Button, Flex, Form, Input, Typography } from 'antd';
import { GoogleLogo } from 'assets/icons/GoogleLogo';
import { AxiosError } from 'axios';
import { Routes } from 'constants/routs';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userService } from 'services/userService';
import { useSettingsStore } from 'store/settings';
import { UserAuthResponseCodes } from 'types/user';
import { toCapital } from 'utilities/string';
import styles from './SignUpView.module.css';
import { PasswordRules } from './components/PasswordRules';

interface SignUpViewForm {
  email: string;
  password: string;
}

export function SignUpView() {
  const [form] = Form.useForm<SignUpViewForm>();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGoogleSubmitting, setIsGoogleSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [setShouldOpenWelcomeDialog] = useSettingsStore((s) => [
    s.setShouldOpenWelcomeDialog,
  ]);

  const password = Form.useWatch('password', {
    form,
  });

  const [isTwelveCharacters, hasNumber, hasUpperCase, hasSpecialCharacter] =
    useMemo(() => {
      return [
        password?.length >= 12,
        /\d/.test(password),
        /[A-Z]/.test(password),
        /[~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/]/.test(password),
      ];
    }, [password]);

  const handleSubmit = async (formData: SignUpViewForm) => {
    if (
      !isTwelveCharacters ||
      !hasNumber ||
      !hasUpperCase ||
      !hasSpecialCharacter
    )
      return;

    setIsSubmitting(true);
    setError(null);
    try {
      await userService.createUser(formData.email, formData.password);

      navigate(Routes.EmailConfirm);
      setShouldOpenWelcomeDialog(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        const parsedErrors = Object.entries(
          (error.response?.data.errors as
            | Record<string, string[]>
            | undefined) ?? {}
        );
        if (parsedErrors.length > 0) {
          setError(toCapital(parsedErrors[0][0]) + ' ' + parsedErrors[0][1][0]);
        } else {
          setError(error.message);
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (tokenResponse) => {
      try {
        const res = await userService.googleOAuth(tokenResponse.code);

        if (res.data.code === UserAuthResponseCodes.EmailVerificationRequired) {
          navigate(Routes.EmailConfirm);
          return;
        }

        if (res.status === 201) {
          navigate(Routes.Onboarding);
        } else {
          navigate(Routes.ProfitAndLoss);
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          setError(error.response?.data.message || error.message);
        }
      } finally {
        setIsGoogleSubmitting(false);
      }
    },
    onError: (error) => {
      setError(error.error_description ?? null);
      setIsGoogleSubmitting(false);
    },
    onNonOAuthError: () => {
      setIsGoogleSubmitting(false);
    },
  });

  return (
    <Flex vertical>
      <Typography.Paragraph className={styles.formDescription}>
        Come up with login and password to continue.
      </Typography.Paragraph>
      <Form<SignUpViewForm>
        form={form}
        validateTrigger={['onSubmit', 'onChange']}
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <Flex gap={24} vertical>
          <Flex gap={20} vertical>
            <Form.Item<SignUpViewForm>
              style={{ marginBottom: 0 }}
              labelCol={{ span: 24 }}
              label="Email"
              name="email"
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: 'This field is required.',
                  validateTrigger: 'onSubmit',
                },
                {
                  type: 'email',
                  message: 'This address is not valid.',
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter your email"
                autoComplete="email"
              />
            </Form.Item>
            <Form.Item<SignUpViewForm>
              style={{ marginBottom: 0 }}
              labelCol={{ span: 24 }}
              label="Password"
              name="password"
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: '',
                  validateTrigger: 'onSubmit',
                },
                {
                  validator: async (_, value) => {
                    if (!value) return;

                    if (
                      !isTwelveCharacters ||
                      !hasNumber ||
                      !hasUpperCase ||
                      !hasSpecialCharacter
                    ) {
                      return Promise.reject();
                    }
                  },
                  validateTrigger: ['onSubmit'],
                },
              ]}
            >
              <Flex vertical>
                <Input.Password
                  size="large"
                  placeholder="Enter password"
                  autoComplete="new-password"
                />
                <PasswordRules
                  isTwelveCharacters={isTwelveCharacters}
                  hasNumber={hasNumber}
                  hasUpperCase={hasUpperCase}
                  hasSpecialCharacter={hasSpecialCharacter}
                />
              </Flex>
            </Form.Item>
          </Flex>

          <Flex vertical gap={12}>
            <Button
              disabled={isGoogleSubmitting}
              loading={isSubmitting}
              size="large"
              type="primary"
              htmlType="submit"
              block
            >
              Sign up
            </Button>
            <Button
              loading={isGoogleSubmitting}
              disabled={isSubmitting}
              size="large"
              block
              onClick={() => {
                setIsGoogleSubmitting(true);
                googleLogin();
              }}
              icon={<GoogleLogo />}
              className="google-button"
            >
              Sign up with Google
            </Button>
          </Flex>
          {error && (
            <Alert
              message={error}
              type="error"
              showIcon
              closable
              style={{ marginTop: '4px' }}
            />
          )}
        </Flex>
      </Form>
    </Flex>
  );
}
