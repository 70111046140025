import Bugsnag from '@bugsnag/js';
import {
  getGasPrice,
  getTransaction,
  readContract,
  waitForTransactionReceipt,
  writeContract,
} from '@wagmi/core';
import { App } from 'antd';
import { wagmiConfig } from 'configs/wagmi';
import {
  EVM_MULTISENDER_ABI,
  EVM_MULTISENDER_ADDRESS,
} from 'constants/multisender/evm';
import { TRON_MULTISENDER_ABI } from 'constants/multisender/tron';
import { useCallback } from 'react';
import { multisenderService } from 'services/multisender';
import { MultisenderState } from 'types/multisender';
import { toFixed } from 'utilities/number';
import { Address, erc20Abi, formatEther, parseUnits, Transaction } from 'viem';
import { arbitrum } from 'viem/chains';
import { useAccount, usePublicClient, useSwitchChain } from 'wagmi';

interface MultisendParams {
  multiSendParams: {
    name: string;
    currency_id: number;
    platform: string;
  };

  /**
   * Token address to send
   */
  token: string;

  /**
   * Token decimals
   */
  decimals: number;

  /**
   *  Recipients
   */
  recipients: {
    id: string;
    no?: number;
    counterparty?: string;
    walletAddress?: string[];
    amount?: number;
  }[];
}

const ETH_TRANSACTION_VALUE = 300000000000001;

export function useEVMMultisend() {
  const { address, chainId } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const publicClient = usePublicClient();
  const { notification } = App.useApp();

  const handleSubmit = useCallback(
    async ({
      multiSendParams,
      token,
      decimals,
      recipients,
    }: MultisendParams) => {
      if (!address || !publicClient) return;

      const totalSum = recipients.reduce(
        (acc, recipient) => acc + +toFixed(recipient.amount ?? 0, decimals),
        0
      );

      const addresses = recipients.map(
        (recipient) => recipient.walletAddress?.[0] ?? ''
      );

      const values = recipients.map((recipient) =>
        parseUnits((recipient.amount ?? 0).toString(), decimals)
      );

      const targets = recipients.map((recipient) => ({
        address: recipient.walletAddress?.[0] ?? '',
        amount: +(recipient.amount ?? 0),
      }));

      let gas_calculated;
      let tx_hash;
      let multisend;

      try {
        if (chainId !== arbitrum.id) {
          await switchChainAsync({ chainId: arbitrum.id });
        }

        const allowance = await readContract(wagmiConfig, {
          abi: erc20Abi,
          address: token as Address,
          functionName: 'allowance',
          args: [address, EVM_MULTISENDER_ADDRESS as Address],
        });

        if (allowance < parseUnits(totalSum.toString(), decimals)) {
          const gas = await publicClient.estimateContractGas({
            abi: erc20Abi,
            address: token as Address,
            functionName: 'approve',
            args: [
              EVM_MULTISENDER_ADDRESS as Address,
              parseUnits(totalSum.toString(), decimals),
            ],
            account: address,
          });

          const allowanceTx = await writeContract(wagmiConfig, {
            abi: erc20Abi,
            address: token as Address,
            functionName: 'approve',
            args: [
              EVM_MULTISENDER_ADDRESS as Address,
              parseUnits(totalSum.toString(), decimals),
            ],
            gas: gas * BigInt(2),
          });

          await waitForTransactionReceipt(wagmiConfig, {
            hash: allowanceTx,
          });
        }

        const gas = await publicClient.estimateContractGas({
          abi: TRON_MULTISENDER_ABI,
          address: EVM_MULTISENDER_ADDRESS as Address,
          functionName: 'sendToMany',
          args: [addresses, values, token],
          account: address,
          value: BigInt(ETH_TRANSACTION_VALUE * addresses.length),
        });

        gas_calculated = +formatEther(gas);

        const tx = await writeContract(wagmiConfig, {
          abi: EVM_MULTISENDER_ABI,
          address: EVM_MULTISENDER_ADDRESS as Address,
          functionName: 'sendToMany',
          args: [addresses, values, token],
          gas,
          value: BigInt(ETH_TRANSACTION_VALUE * addresses.length),
        });

        tx_hash = tx;

        multisend = await multisenderService.createMultisend({
          name: multiSendParams.name,
          description: '',
          currency_id: multiSendParams.currency_id,
          chain: multiSendParams.platform,
          state: MultisenderState.InProgress,
          source: address ?? '',
          targets: targets,
          tx_id: tx,
        });

        notification.success({
          message: 'Multisend in progress',
          description: 'Tokens are being sent',
          placement: 'bottom',
          duration: 15,
        });

        await waitForTransactionReceipt(wagmiConfig, {
          hash: tx,
        });

        if (multisend) {
          await multisenderService.confirmMultisend(multisend.multisend, tx);
        }

        return tx;
      } catch (error) {
        if (multisend) {
          await multisenderService.editMultisend(
            {
              name: multisend.multisend.name,
              description: multisend.multisend.description ?? '',
              currency_id: multisend.multisend.currency.id,
              chain: multisend.multisend.chain,
              source: multisend.multisend.source.address,
              targets: multisend.multisend.targets.map((target) => ({
                address: target.address,
                amount: +target.amount,
              })),
              state: MultisenderState.Failed,
            },
            multisend.multisend.id
          );
        }
        if (tx_hash) {
          let transaction: Transaction | undefined;
          try {
            transaction = await getTransaction(wagmiConfig, {
              hash: tx_hash,
            });
          } catch (error) {}

          const errorEvent = {
            exception: (error as Error)?.message,
            native_token_symbol: 'ETH',
            native_token_calculated: +formatEther(
              BigInt(ETH_TRANSACTION_VALUE * addresses.length)
            ),
            native_token_actual: transaction?.value ?? 0,
            gas_calculated,
            gas_actual: transaction?.gas ?? 0,
            token_address: token,
            token_calculated: totalSum,
            tx_hash: tx_hash,
          };
          if (error instanceof Error) {
            Bugsnag.notify(error, (event) => {
              event.addMetadata('multisend', errorEvent);
            });
          }
        }
        throw error;
      }
    },
    [address, chainId, switchChainAsync]
  );

  const estimateGas = useCallback(
    async ({
      token,
      decimals,
      recipients,
    }: Pick<MultisendParams, 'token' | 'decimals' | 'recipients'>) => {
      try {
        if (!publicClient || !address) return 0;

        const addresses = recipients
          .map((recipient) => recipient.walletAddress?.[0] ?? '')
          .filter((address) => !!address);

        if (addresses.length === 0) {
          return 0;
        }

        const values = recipients.map((recipient) =>
          parseInt(((recipient.amount ?? 0) * 10 ** decimals).toString())
        );

        const gasEstimate = await publicClient.estimateContractGas({
          abi: TRON_MULTISENDER_ABI,
          address: EVM_MULTISENDER_ADDRESS as Address,
          functionName: 'sendToMany',
          args: [addresses, values, token],
          account: address,
          value: BigInt(ETH_TRANSACTION_VALUE * addresses.length),
        });

        const gasPrice = await getGasPrice(wagmiConfig, {
          chainId: arbitrum.id,
        });

        const fee = BigInt(ETH_TRANSACTION_VALUE * addresses.length);

        return +formatEther(gasPrice * gasEstimate + fee);
      } catch (error) {
        return 0;
      }
    },
    [publicClient, address]
  );

  const estimateGasForApprove = useCallback(
    async ({
      token,
      decimals,
      recipients,
    }: Pick<MultisendParams, 'token' | 'decimals' | 'recipients'>) => {
      if (!publicClient || !address || !recipients) return undefined;

      const filteredRecipients = recipients.filter(
        (recipient) =>
          recipient?.counterparty ||
          recipient?.walletAddress?.[0] ||
          recipient?.amount
      );

      const totalSum = filteredRecipients.reduce(
        (acc, recipient) => acc + +toFixed(recipient.amount ?? 0, decimals),
        0
      );

      if (totalSum <= 0) return undefined;

      const allowance = await readContract(wagmiConfig, {
        abi: erc20Abi,
        address: token as Address,
        functionName: 'allowance',
        args: [address, EVM_MULTISENDER_ADDRESS as Address],
      });

      if (allowance >= parseUnits(totalSum.toString(), decimals)) {
        return 0;
      }

      const gasEstimate = await publicClient.estimateContractGas({
        abi: erc20Abi,
        address: token as Address,
        functionName: 'approve',
        args: [
          EVM_MULTISENDER_ADDRESS as Address,
          parseUnits(totalSum.toString(), decimals),
        ],
        account: address,
      });

      const gasPrice = await getGasPrice(wagmiConfig, {
        chainId: arbitrum.id,
      });

      return +(gasPrice * gasEstimate).toString();
    },
    [publicClient, address]
  );

  return {
    sendToMany: handleSubmit,
    estimateGas,
    estimateGasForApprove,
  };
}
