import { PlusOutlined } from '@ant-design/icons';
import { EditableProTable, ProColumns } from '@ant-design/pro-components';
import {
  App,
  Button,
  Divider,
  Input,
  Popconfirm,
  Space,
  Tag,
  Typography,
} from 'antd';
import { AxiosError } from 'axios';
import WAValidator from 'multicoin-address-validator';
import { useState } from 'react';
import { addressBookService } from 'services/addressBook';
import { useSettingsStore } from 'store/settings';
import { useWalletsStore } from 'store/wallets';
import useSWR from 'swr';
import { CounterpartyLabels, CounterpartyWallet } from 'types/counterparty';
import { getNetworkType } from 'utilities/wallets';
import styles from './styles.module.css';

export function Wallets() {
  const { data, isLoading, mutate } = useSWR(
    '/counterparties_wallets?with_turnover',
    () => addressBookService.getWallets(true)
  );

  const [newCounterparty, setNewCounterparty] = useState<string>('');
  const { data: counterparties, mutate: refetchCounterparties } = useSWR(
    '/counterparties',
    () => addressBookService.getCounterparties()
  );
  const settings = useSettingsStore((s) => s.settings);
  const { notification } = App.useApp();
  const wallet = useWalletsStore((s) => s.wallets);

  const handleAddCounterparty = async () => {
    try {
      await addressBookService.createCounterparty(
        newCounterparty,
        '',
        CounterpartyLabels.Individual
      );
      await refetchCounterparties();
      setNewCounterparty('');
    } catch (error) {
      if (error instanceof AxiosError) {
        notification.error({
          message: 'Error',
          description: error.response?.data?.message || error.message,
          placement: 'bottom',
        });
      } else if (error instanceof Error) {
        notification.error({
          message: 'Error',
          description: error.message,
          placement: 'bottom',
        });
      }
    }
  };

  const columns: ProColumns<CounterpartyWallet>[] = [
    {
      title: 'Wallet address',
      dataIndex: ['wallet', 'address'],
      sorter: (a, b) => {
        return a.wallet.address?.localeCompare(b.wallet.address || '') || 0;
      },
      editable: (text, record, index) => {
        return index === 0 && !record.wallet?.address?.length;
      },
      width: '359px',
      fieldProps: (form) => ({
        size: 'small',
        placeholder: 'Enter wallet address',
      }),
      formItemProps: (form, record) => {
        return {
          rules: [
            { required: true, message: 'This field is required' },
            {
              validateTrigger: 'onSubmit',
              validator(rule, value) {
                const formItem = form.getFieldValue(record.entity.id);
                const network = formItem?.wallet?.network || formItem?.network;

                const res = WAValidator.validate(value, network);
                if (value.length === 0) return Promise.resolve();
                if (!res) {
                  return Promise.reject('This address is not valid.');
                } else {
                  return Promise.resolve();
                }
              },
            },
            {
              validateTrigger: 'onSubmit',
              validator(rule, value) {
                if (data?.some((w) => w.wallet.address === value)) {
                  return Promise.reject('This address already exists.');
                }

                if (wallet.some((w) => w.wallet.address === value)) {
                  return Promise.reject('This address already exists.');
                }

                return Promise.resolve();
              },
            },
          ],
        };
      },
    },
    {
      title: 'Counterparty',
      dataIndex: 'counterparty_id',
      sorter: (a, b) => {
        return a.counterparty_id?.localeCompare(b.counterparty_id || '') || 0;
      },
      editable: () => {
        return true;
      },
      filters: counterparties?.map((counterparty, i) => ({
        text: counterparty.name,
        value: counterparty.id,
        key: i,
      })),
      onFilter: (value, record) => record.counterparty_id === value,
      width: '150px',
      valueType: 'select',
      fieldProps: {
        size: 'small',
        placeholder: 'Enter name',
        showSearch: true,
        rootClassName: styles.counterpartiesDropdownMenu,
        options: counterparties?.map((counterparty, i) => ({
          label: counterparty.name,
          value: counterparty.id,
          key: counterparty.id,
        })),
        onDropdownVisibleChange: (open: boolean) => {
          if (!open) {
            setNewCounterparty('');
          }
        },
        dropdownRender: (menu: any) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '0 8px 4px' }}>
              <Input
                placeholder="New counterparty"
                value={newCounterparty}
                onChange={(e) => setNewCounterparty(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Button
                type="text"
                icon={<PlusOutlined />}
                onClick={handleAddCounterparty}
              >
                Add
              </Button>
            </Space>
          </>
        ),
      },
      render: (text, record) => {
        if (!record.counterparty_id) return text;
        return <Tag>{text}</Tag>;
      },
    },
    {
      title: 'Wallet name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => {
        return a.name?.localeCompare(b.name || '') || 0;
      },
      editable: () => {
        return true;
      },
      width: '150px',
      fieldProps: {
        size: 'small',
        placeholder: 'Enter name',
      },
      render: (text, record) => {
        if (!record.name) return text;
        return <Tag color="volcano">{text}</Tag>;
      },
    },
    {
      title: 'Wallet address type',
      dataIndex: ['wallet', 'network'],
      sorter: (a, b) =>
        a.wallet.network?.localeCompare(b.wallet.network || '') || 0,
      filters: settings.networks.map((network, i) => ({
        text: getNetworkType(network.code),
        value: network.code,
        key: i,
      })),
      onFilter: (value, record) => record.wallet.network === value,
      editable: (text, record, index) => {
        return index === 0 && !record.wallet?.address?.length;
      },
      formItemProps: (form, { rowIndex }) => {
        return {
          rules:
            rowIndex === 0
              ? [{ required: true, message: 'This field is required' }]
              : [],
        };
      },
      valueType: 'select',
      fieldProps: {
        size: 'small',
        placeholder: 'Select type',
        options: settings.networks.map((network, i) => ({
          label: getNetworkType(network.code),
          value: network.code,
          key: i,
        })),
      },
      width: '225px',
    },
    {
      title: 'Turnover',
      dataIndex: 'turnover',
      editable: false,
      width: '110px',
      sorter: (a, b) => +(a.turnover ?? 0) - +(b.turnover ?? 0),
      render: (text, record) => {
        if (+(record.turnover ?? 0) > 0) {
          return (
            <Typography.Text type="success">+{record.turnover}</Typography.Text>
          );
        }

        if (!+(record.turnover ?? 0)) {
          return <Typography.Text>{text}</Typography.Text>;
        }

        return (
          <Typography.Text type="danger">{record.turnover}</Typography.Text>
        );
      },
    },
    {
      title: 'Actions',
      valueType: 'option',
      width: '160px',
      render: (text, record, _, action) => [
        <Button
          type="link"
          key="editable"
          onClick={() => {
            action?.startEditable?.(record.id);
          }}
          style={{ padding: 0, height: 'auto' }}
        >
          Edit
        </Button>,

        <Popconfirm
          title="Are you sure?"
          onConfirm={async () => {
            await handleDelete(record);
            action?.reload();
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="link"
            key="delete"
            onClick={() => {}}
            style={{ padding: 0, height: 'auto' }}
          >
            Delete
          </Button>
        </Popconfirm>,
      ],
    },
  ];

  const handleSave = async (record: CounterpartyWallet) => {
    try {
      const wallet = await addressBookService.getWallet(record.id);

      if (!!wallet?.id) {
        await addressBookService.updateWallet(
          record.id,
          record.name,
          record.counterparty_id,
          record.wallet.address,
          record.wallet.network
        );
      } else {
        await addressBookService.createWallet(
          record.name,
          record.wallet.network,
          record.wallet.address,
          record.counterparty_id
        );
      }
      await mutate();
    } catch (error) {
      if (error instanceof AxiosError) {
        notification.error({
          message: 'Error',
          description: error.response?.data?.message || error.message,
          placement: 'bottom',
        });
        throw new Error(error.response?.data?.message || error.message);
      } else if (error instanceof Error) {
        notification.error({
          message: 'Error',
          description: error.message,
          placement: 'bottom',
        });
      }
      throw error;
    }
  };

  const handleDelete = async (record: CounterpartyWallet) => {
    try {
      await addressBookService.deleteWallet(record.id);
      await mutate();
    } catch (error) {
      if (error instanceof AxiosError) {
        notification.error({
          message: 'Error',
          description: error.response?.data?.message || error.message,
          placement: 'bottom',
        });
      } else if (error instanceof Error) {
        notification.error({
          message: 'Error',
          description: error.message,
          placement: 'bottom',
        });
      }
      throw error;
    }
  };

  return (
    <>
      <EditableProTable
        rowKey={(record) => record.id}
        columns={columns}
        size="small"
        cardProps={{
          bodyStyle: { padding: 0 },
        }}
        loading={isLoading}
        value={data ?? []}
        editable={{
          onlyAddOneLineAlertMessage: 'Only one line can be added at a time',
          onlyOneLineEditorAlertMessage:
            'Only one line can be edited at a time',
          onSave: async (_, record) => {
            await handleSave(record);
          },
          onDelete: async (_, record) => {
            await handleDelete(record);
          },
        }}
        recordCreatorProps={{
          position: 'top',
          record: {
            id: crypto.randomUUID(),
            name: undefined,
            turnover: '',
            counterparty_id: undefined,
            wallet: {
              address: '',
              network: undefined,
              last_retrieved_at: null,
              usd_balance: null,
            },
          },
          creatorButtonText: 'Add new Wallet address',
        }}
        className="withoutOffset"
      />
    </>
  );
}
