import { Flex } from 'antd';
import { BreakdownTable } from './components/BreakdownTable';
import { ProfitAndLossChart } from './components/Chart';

export function ProfitAndLossView() {
  return (
    <Flex gap={60} vertical>
      <ProfitAndLossChart />
      <BreakdownTable />
    </Flex>
  );
}
