import { User } from 'types/user';
import { create } from 'zustand';

interface UserStore {
  user?: User;
  setUser: (user: User) => void;
}

export const userStore = create<UserStore>((set) => ({
  user: undefined,
  setUser: (user) => set({ user }),
}));

export const useUserStore = userStore;
