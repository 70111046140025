import { SVGAttributes } from 'react';

export function WalletIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      {...props}
    >
      <rect x="26" y="26" width="100" height="100" rx="14" fill="#EDEFF3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57 59.5C57 58.0192 58.1327 57 59.3103 57H85.8621C87.4587 57 88.931 58.3709 88.931 60.2857V62H59.3103C58.1327 62 57 60.9808 57 59.5ZM94.931 60.2857V62H98C99.6569 62 101 63.3431 101 65V91.92C101 96.9297 96.9449 101 91.931 101H60.069C55.0552 101 51 96.9297 51 91.92V60C51 59.9376 51.0019 59.8757 51.0057 59.8142C51.0019 59.7099 51 59.6051 51 59.5C51 54.9057 54.6223 51 59.3103 51H85.8621C90.9692 51 94.931 55.2575 94.931 60.2857ZM57 67.6701V91.92C57 93.6262 58.3791 95 60.069 95H91.931C93.6209 95 95 93.6263 95 91.92V68H59.3103C58.5044 68 57.73 67.8846 57 67.6701ZM86.5 78.5C85.3957 78.5 84.5 79.3957 84.5 80.5C84.5 81.6043 85.3957 82.5 86.5 82.5C87.6043 82.5 88.5 81.6043 88.5 80.5C88.5 79.3957 87.6043 78.5 86.5 78.5ZM81.5 80.5C81.5 77.7389 83.7389 75.5 86.5 75.5C89.2611 75.5 91.5 77.7389 91.5 80.5C91.5 83.2611 89.2611 85.5 86.5 85.5C83.7389 85.5 81.5 83.2611 81.5 80.5Z"
        fill="#667085"
      />
    </svg>
  );
}
